
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import AlertDialog from '../../common/alertDialog';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import CustomDialogs from '../../modals/customModal';
import DailyUsageReportDrilldown from './dailyUsageReportDrilldown';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    justifyContent: {
        justifyContent: 'space-between'
    },
    cardTitleWhite: {
        color: 'White',
        '& .MuiFormLabel-root,.MuiInputBase-root,.MuiIconButton-root':{
            color: 'rgb(251 251 251)',
        },
        '& .MuiInput-underline:before': {
            borderBottom:'1px solid rgba(251 251 251 /42%)'
        }
    }
}));

export default function DailyUsageReport() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(moment().format('YYYY/MM/DD'));
    const [selectedSpaceId, setSelectedSpaceId] = React.useState(0);
    const [modal, setModal] = useState(false);

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
    };

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [dailyReport, setDailyReport] = useState({
        columns: [
            { title: 'SpaceId', field: 'SpaceId', hidden: true },
            { title: 'Location', field: 'Location'},
            { title: 'Space', field: 'Space'},
            { title: 'Routine', field: 'Routine' },
            { title: 'Total Tasks', field: 'Total', filtering: false },
            { title: 'Complete', field: 'Complete', filtering: false },
            {
                title: 'Percent Complete', field: 'PercentComplete', filtering: false , cellStyle: function (val) {
                    var number = val.replace('%', '');
                    var _color = '#E4393D';

                    if (number >= 75 && number <= 95) {
                        _color = '#F88C23';
                    }
                    else if (number > 95) {
                        _color = '#49A34E';
                    }

                    return { color: _color }
                }

            },
        ],
        data: []
    });


    const getDailyUsageReport = () => {
        setLoading(true);
        HttpService.post('/Report/GetDailyUsageReport', { SiteId: defaultSite.id, Date: selectedDate })
            .then(
                res => {
                    setDailyReport({ ...dailyReport, data: res.data });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYY/MM/DD'));
    };

    const handleRowClick = (item) => {
        setSelectedSpaceId(item.SpaceId);
        handleModalOpen();
    };


    useEffect(() => {
        getDailyUsageReport();
        // eslint-disable-next-line
    }, [defaultSite.id, selectedDate]);

    return (
        <div className={classes.root}>
            <CustomDialogs open={modal} close={handleModalClose} title="Routine Item Details" >
                <DailyUsageReportDrilldown spaceId={selectedSpaceId} selectedDate={selectedDate} />
            </CustomDialogs>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            
                            <Grid container
                                direction="row"
                                alignItems="center"
                                className={classes.justifyContent}>
                                <Grid>
                                    <h4 className={classes.cardTitleWhite}>Daily Usage Report</h4>
                                </Grid>
                                <Grid>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                className={classes.cardTitleWhite}
                                                label="Select Date"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                            />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                onRowClick={(event, item) => { handleRowClick(item); }}
                                columns={dailyReport.columns}
                                data={dailyReport.data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
