import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { HttpService } from '../../../apiAuthorization/httpService';
import AlertDialog from '../../common/alertDialog';
import { Context } from "../../contexts/siteContext";
import debounce  from "lodash/debounce";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    hideFilter: {
        display: 'none'
    },
    filterRoot: {
        display: 'flex'
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headers: { fontWeight: 'bold' }
}));

export default function EnhancedTable(props) {

    const { locationsFromTL, onRefreshLocations } = props;
    const classes = useStyles();
    const classesToolbar = useToolbarStyles();
    const [rows, setRows] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selectedText, setSelectedText] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const { setOpen } = useContext(Context);
   
    //init data
    useEffect(() => {
        if (locationsFromTL) {
            setRows(locationsFromTL);
        }
    }, [locationsFromTL]);

    useEffect(() => {
        filterDatas();
    }, [selectedText]);

    const alertClose = () => {
        setAlertOpen(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.GeoLocationBranchId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, locationsFromTL.length - page * rowsPerPage);
    const headCells = [
        { id: 'GeoLocationBranchName', numeric: false, disablePadding: true, label: 'Geo Location Branch Name' },
    ];

    const handleFilterChange = event => {
        setSelectedText(event.target.value);
    };

    const addLocation = () => {
       
        setOpen(true);
        //fill dto
        let selectedDto = [];
        rows.forEach(r => {
            selected.forEach(s => {
                if (r.GeoLocationBranchId == s) {
                    selectedDto.push(r);
                }
            });
        });

        HttpService.post('/Setting/AddLocationsBySite', selectedDto)
            .then(
                res => {
                    setOpen(false);
                    onRefreshLocations();
                },
                error => {
                    setOpen(false);
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
        );
    };

    const filterDatas = () => {

        if (!selectedText || selectedText === '') {
            setRows(locationsFromTL);
            return;
        }

        var filterDate = [];

        locationsFromTL.forEach(x => {

            if (x.GeoLocationBranchName.toLowerCase().indexOf(selectedText.toLowerCase()) > -1) {
                filterDate.push(x);
            }
        });

        setRows(filterDate);

    };


    const enhancedTableHead=()=> {
        const createSortHandler = (property) => (event) => {
            handleRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell className={classes.headers}
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const enhancedTableToolbar = () => {
        return (
            <Toolbar
                className={clsx(classesToolbar.root, {
                    [classesToolbar.highlight]: selected.length > 0,
                })}
            >
                {selected.length > 0 ? (
                    <Typography className={classesToolbar.title} color="inherit" variant="subtitle1" component="div">
                        {selected.length} selected
                    </Typography>
                ) : null}

                <div className={selected.length > 0 ? classesToolbar.hideFilter : classesToolbar.filterRoot}>
                    <Tooltip title="Filter list"  >
                        <TextField
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Filter Items"
                            onChange={handleFilterChange}
                        />
                    </Tooltip>
                </div>
                {selected.length > 0 ? (
                    <Tooltip title="Add">
                        <IconButton aria-label="add" onClick={debounce(addLocation,500)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Toolbar>
        );
    };

    return (
        <div className={classes.root}>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />

            <Paper className={classes.paper}>
                {
                    enhancedTableToolbar()
                }
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        {
                            enhancedTableHead()
                        }
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.GeoLocationBranchId);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.GeoLocationBranchId)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.GeoLocationBranchId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.GeoLocationBranchName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
