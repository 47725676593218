/* This page is used to update the locations */

import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from "../../contexts/siteContext";
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import AlertDialog from '../../common/alertDialog';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TextField, Select, InputLabel, FormLabel, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText } from '@material-ui/core/';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));


export default function Holiday() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(true);

    const selectedHolidayBase = {
        startDate: new Date(),
        startTime: "00:00",
        endDate: new Date(),
        endTime: "23:59",
    };
    const [selectedHoliday, setSelectedHoliday] = useState(selectedHolidayBase);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const clearSelectedHolidayBase = () => {
        let holiday = selectedHoliday;
        holiday.startDate = new Date();
        holiday.startTime = "00:00";
        holiday.endDate = new Date();
        holiday.endTime = "23:59";
        setSelectedHoliday(holiday);
    };

    const converHolidayToModel = (model) => {
        let holiday = selectedHoliday;
        model.StartDateTime = `${moment(holiday.startDate).format('YYYY/MM/DD')} ${holiday.startTime}`;
        model.EndDateTime = `${moment(holiday.endDate).format('YYYY/MM/DD')} ${holiday.endTime}`;
        model.SiteId = defaultSite.siteId || defaultSite.id;
        //console.log(model, defaultSite);
    };

    const fillHoliday = (model) => {
        let holiday = selectedHoliday;
        holiday.startDate = new Date();
        holiday.startTime = "00:00";
        holiday.endDate = new Date();
        holiday.endTime = "00:00";
        setSelectedHoliday(holiday);
    };

    const getStartDate = (props) => {
        if (moment(props.value).isValid()) {
            selectedHoliday.startDate = moment(props.value).format("YYYY/MM/DD");
        }
        // console.log(moment(props.value).isValid(), selectedHoliday.startDate);
        return selectedHoliday.startDate;
    };
    const getStartTime = (props) => {

        if (!props.value) {
            selectedHoliday.startTime = '00:00';
            return selectedHoliday.startTime;
        }

        if (moment(props.value).isValid()) {
            selectedHoliday.startTime = moment(props.value).format("HH:mm");
        }
        return selectedHoliday.startTime;
    };

    const getEndDate = (props) => {

        if (moment(props.value).isValid()) {
            selectedHoliday.endDate = moment(props.value).format("YYYY/MM/DD");
        }
        return selectedHoliday.endDate;
    };
    const getEndTime = (props) => {

        if (!props.value) {
            selectedHoliday.endTime = '23:59';
            return selectedHoliday.endTime;
        }

        if (moment(props.value).isValid()) {
            selectedHoliday.endTime = moment(props.value).format("HH:mm");
        }
        return selectedHoliday.endTime;
    };

    const [holidays, setHolidays] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Holiday Name', field: 'HolidayName',
                validate: rowData => {
                    if (!rowData.HolidayName) {
                        return { isValid: false, helperText: 'Holiday Name is required' };
                    } else {
                        return { isValid: true, helperText: '' };
                    }
                }
            },
            {
                title: 'Start Date', field: 'StartDateTime', type: 'datetime',
                editComponent: props => (
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.inputControl}
                                    label="Start Date"
                                    value={getStartDate(props)}
                                    onChange={e => { selectedHoliday.startDate = e; props.onChange(e); }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <TextField
                                id="start-time"
                                label="Start Time"
                                type="time"
                                name="StartTime"
                                format="hh:mm a"
                                className={classes.inputControl}
                                value={getStartTime(props)}
                                onChange={e => { selectedHoliday.startTime = e.target.value; props.onChange(e.target.value); }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                ),
                validate: rowData => {
                    if (moment(selectedHoliday.startDate) > moment(selectedHoliday.endDate)) {
                        return { isValid: false };
                    } else {
                        return { isValid: true };
                    }
                }
            },
            {
                title: 'End Date', field: 'EndDateTime', type: 'datetime',
                editComponent: props => (
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.inputControl}
                                    label="End Date"
                                    value={getEndDate(props)}
                                    onChange={e => { selectedHoliday.endDate = e; props.onChange(e); }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <TextField
                                id="end-time"
                                label="End Time"
                                type="time"
                                name="EndTime"
                                format="hh:mm a"
                                className={classes.inputControl}
                                value={getEndTime(props)}
                                onChange={e => { selectedHoliday.endTime = e.target.value; props.onChange(e.target.value); }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                )

            }
        ],
        data: []
    });


    const getHolidays = () => {
        setLoading(true);
        HttpService.post('/Setting/GetHolidaysBySite/' + defaultSite.id)
            .then(
                res => {
                    setHolidays({ ...holidays, data: res.holidays, columns: [...holidays.columns] });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };


    const addHoliday = model => {
        converHolidayToModel(model);
        model.Id = 0;
        HttpService.post('/Setting/AddHoliday', model)
            .then(
                res => {
                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    getHolidays();
                },
                error => {
                    setLoading(false);
                    setErrorMessage(error);
                    setSeverity('info');
                    setAlertOpen(true);
                }
            );
    };

    const updateHoliday = model => {
        converHolidayToModel(model);
        setLoading(true);
        //model.Id = Number(model.Id);
        HttpService.post('/Setting/UpdateHoliday', model)
            .then(
                res => {

                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    getHolidays();
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('info');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    const onRowDelete = model => {
        //model.Id = Number(model.Id);
        if (window.confirm("Are you sure you want to delete this item ?")) {
            setLoading(true);
            HttpService.post('/Setting/DeleteHoliday', model)
                .then(
                    res => {
                        setLoading(false);
                        if (res.error && res.message) {
                            setErrorMessage(res.message);
                            setSeverity('error');
                            setAlertOpen(true);
                        }
                        getHolidays();
                    },
                    error => {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                        setLoading(false);
                    }
                );
        }

    };

    useEffect(() => {
        getHolidays();
        // eslint-disable-next-line
    }, [defaultSite.id]);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>Holidays</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    filtering: true,
                                    search: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                icons={{
                                    Add: props => (
                                        <Tooltip title='Add Holiday'>
                                            <AddIcon className='AddIcon' {...props} onClick={clearSelectedHolidayBase} />
                                        </Tooltip>
                                    )
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        disabled: rowData.Disabled,
                                        onClick: () => { onRowDelete(rowData) }
                                    })
                                ]}
                                columns={holidays.columns}
                                data={holidays.data}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                addHoliday(newData);
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateHoliday(newData);
                                                }
                                            }, 600);
                                        }),
                                    onRowUpdateCancelled: (event) => {
                                        clearSelectedHolidayBase();
                                    },
                                    onRowAddCancelled: (event) => {
                                        clearSelectedHolidayBase();
                                    }
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
