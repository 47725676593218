import React from 'react';
import { Alert } from '@material-ui/lab';
import { Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

export default function AlertDialog(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={props.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert severity={props.severity} action={
                    <Button color="inherit" size="small" variant="outlined" onClick={props.close}>OK</Button>}>
                    {props.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
