/* This page is used to update the items */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core';
import { HttpService } from '../../../apiAuthorization/httpService';
import AddIcon from '@material-ui/icons/Add';
import AlertDialog from '../../common/alertDialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function Items() {
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(true);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [itemGroups, setItemGroups] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Item Group Name', field: 'ItemGroupName', width: '90%',
                validate: rowData => {
                    if (rowData.ItemGroupName === undefined) {
                        rowData.ItemGroupName = '';
                        return { isValid: true, helperText: '' };
                    } else if (rowData.ItemGroupName.trim().length === 0) {
                        return { isValid: false, helperText: 'Item Group Name is required' };
                    } else if (rowData.ItemGroupName.trim().length > 75) {
                        return { isValid: false, helperText: 'Item Group Name length is exceeded' };
                    } else {
                        return { isValid: true, helperText: '' };
                    }
                }
            }
        ],
        data: []
    });

    //const [itemGroups, setItemGroups] = useState({
    //    columns: [
    //        { title: 'Id', hidden: true, field: 'Id' },
    //        { title: 'Item Group Name', field: 'ItemGroupName', width: '80%' }
    //    ],
    //    data: []
    //});

    const [items, setItems] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Item Group Name', field: 'ItemGroupId', width: '30%',
                validate: rowData => {
                    if (rowData.ItemGroupId === undefined) {
                        rowData.ItemGroupId = 0;
                        return { isValid: true, helperText: '' };
                    }
                    else {
                        return rowData.ItemGroupId === 0 ? { isValid: false, helperText: 'Item Group Name is required' } : { isValid: true, helperText: '' };
                    }
                }
            },
            {
                title: 'Item Name', field: 'ItemName', width: '30%',
                validate: rowData => {
                    if (rowData.ItemName === undefined) {
                        rowData.ItemName = '';
                        return { isValid: true, helperText: '' };
                    }
                    else {
                        return rowData.ItemName.trim().length === 0 ? { isValid: false, helperText: 'Item Name is required' } : { isValid: true, helperText: '' };
                    }
                }
            },
            {
                title: 'Time To Complete', field: 'TimeToComplete', width: '20%',
                validate: rowData => {
                    if (rowData.TimeToComplete === undefined) {
                        rowData.TimeToComplete = '';
                        return { isValid: true, helperText: '' };
                    }
                    else {

                        if (rowData.TimeToComplete > 120) {
                            return { isValid: false, helperText: 'Please limit Time To Complete within 120 mins.' } 
                        }

                        return (!(/^\d{1,9}$/.test(rowData.TimeToComplete))) ? { isValid: false, helperText: 'Enter a positive integer' } : { isValid: true, helperText: '' };
                    }
                }
            },
            {
                title: 'Frequency', field: 'FrequencyId', width: '20%',
                validate: rowData => {
                    if (rowData.FrequencyId === undefined) {
                        rowData.FrequencyId = 0;
                        return { isValid: true, helperText: '' };
                    }
                    else {
                        return rowData.FrequencyId === 0 ? { isValid: false, helperText: 'Frequency is required' } : { isValid: true, helperText: '' };
                    }
                 }
            }
        ],
        data: []
    });

    const getItemGroups = () => {
        HttpService.post('/Setting/GetItemGroups')
            .then(
                res => {
                    setItemGroups({ ...itemGroups, data: res.data });
                    // For drop down list look up data
                    const groups = {};
                    for (let index = 0; index < res.data.length; index++) {
                        groups[res.data[index].Id] = res.data[index].ItemGroupName;
                    }
                    const data = [...items.columns];
                    data[1].lookup = groups;
                    setItems({ ...items, columns: data });
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const getFrequency = input => {
        HttpService.post('/Setting/GetFrequency')
            .then(
                res => {
                    const freq = res.data;
                    const obj = {};
                    for (let index = 0; index < freq.length; index++) {
                        obj[freq[index].Id] = freq[index].FrequencyName;
                    }
                    const itemColumns = [...items.columns];
                    itemColumns[4].lookup = obj;
                    setItems({ ...items, columns: itemColumns, data: input });
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const getItems = () => {
        HttpService.post('/Setting/GetItems')
            .then(
                res => {
                    setItems({ ...items, data: res.data });
                    getFrequency(res.data);
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const updateItems = model => {
        setLoading(true);
        model.FrequencyId = parseInt(model.FrequencyId, 10);
        model.ItemGroupId = parseInt(model.ItemGroupId, 10);
        model.TimeToComplete = parseInt(model.TimeToComplete, 10);
        HttpService.post('/Setting/UpdateItem', model)
            .then(
                res => {
                    const itemsData = [...items.data];
                    const updatedItemIndex = itemsData.findIndex(x => x.Id === res.data.Id);
                    itemsData[updatedItemIndex] = res.data;
                    setItems({ ...items, data: itemsData });
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                }
            );
    };

    const addItems = model => {
        setLoading(true);
        model.FrequencyId = parseInt(model.FrequencyId, 10);
        model.ItemGroupId = parseInt(model.ItemGroupId, 10);
        model.TimeToComplete = parseInt(model.TimeToComplete, 10);
        HttpService.post('/Setting/AddItem', model)
            .then(
                res => {
                    const itemsData = [...items.data];
                    itemsData.push(res.data);
                    setItems({ ...items, data: itemsData });
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                }
            );
    };

    //item group
 
    const addItemGroup = model => {
        model.Id = 0;
        HttpService.post('/Setting/AddItemGroup', model)
            .then(
                res => {
                    //const groups = [...itemGroups.data];
                    //groups.push(res.data);
                    //setItemGroups({ ...itemGroups, data: groups });
                    getItemGroups();
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                }
            );
    };

    const updateItemGroup = model => {
        setLoading(true);
        HttpService.post('/Setting/UpdateItemGroup', model)
            .then(
                res => {
                    //const groups = [...itemGroups.data];
                    //const updatedItemIndex = groups.findIndex(x => x.Id === res.data.Id);
                    //groups[updatedItemIndex] = res.data;
                    //setItemGroups({ ...itemGroups, data: groups });
                    getItemGroups();
                    getItems();
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };
    //item group

    useEffect(() => {
        getItemGroups();
        getItems();
        //eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>Items</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                columns={items.columns}
                                data={items.data}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                icons={{
                                    Add: props => (
                                        <Tooltip title='Add Item'>
                                            <AddIcon className='AddIcon' {...props} />
                                        </Tooltip>
                                    )
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                addItems(newData);
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateItems(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>Item Groups</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    filtering: true,
                                    search: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                icons={{
                                    Add: props => (
                                        <Tooltip title='Add Item Group'>
                                            <AddIcon className='AddIcon' {...props} />
                                        </Tooltip>
                                    )
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                columns={itemGroups.columns}
                                data={itemGroups.data}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                addItemGroup(newData);
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateItemGroup(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
