import {  BaseService } from "./baseService";
import { OfflineSyncModel, OfflineSyncExtend } from '../../models/offlineSyncModel';

export class OfflineSyncService extends BaseService {

    constructor() {
        super();
        this.tableName = "OfflineSyncs";
        this.OfflineSyncExtend = new OfflineSyncExtend();
    }

    async getOfflineSyncs() {
        let offlines = await this.connection.select({
            from: this.tableName
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineSyncExtend.decryption(new OfflineSyncModel(), item));
        });

        return dOfflines;
    }

    async getBySite(siteId) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                SiteId: siteId
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineSyncExtend.decryption(new OfflineSyncModel(), item));
        });

        return dOfflines[0];
    }

    async getByName(name) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                Name: name
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineSyncExtend.decryption(new OfflineSyncModel(), item));
        });

        return dOfflines[0];
    }

    async getByEntityId(entityId) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                EntityId: entityId
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineSyncExtend.decryption(new OfflineSyncModel(), item));
        });

        return dOfflines[0];
    }

    async getByNameAndEntityId(entityId, name) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                EntityId: entityId,
                Name: name
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineSyncExtend.decryption(new OfflineSyncModel(), item));
        });

        return dOfflines[0];
    }

    add(siteId, entityId,url, name, data) {

        let offlineSyncModel = new OfflineSyncModel(null, siteId, entityId, url, name, data);
        this.OfflineSyncExtend.encryption(offlineSyncModel, offlineSyncModel);

        return this.connection.insert({
            into: this.tableName,
            values: [offlineSyncModel],
            skipDataCheck: true,
            return: false// since id is autoincrement field and we need id, 
            // so we are making return true which will return the whole data inserted.
        });
    }

    async updateSyncOfflineById(id, updateData) {

        let offlineSyncModel = new OfflineSyncModel(null, null, null, null, null, updateData);
        this.OfflineSyncExtend.encryption(offlineSyncModel, offlineSyncModel);

        return this.connection.update({
            in: this.tableName,
            set: {
                OfflineData: offlineSyncModel.OfflineData
            },
            where: {
                Id: id
            }
        });
    }

    removeById(id) {
        return this.connection.remove({
            from: this.tableName,
            where: {
                Id: id
            }
        });
    }

    removeByEntityId(entityId) {
        return this.connection.remove({
            from: this.tableName,
            where: {
                EntityId: entityId
            }
        });
    }

    removeBySite(siteId) {

        return this.connection.remove({
            from: this.tableName,
            where: {
                SiteId: siteId
            }
        });
    }

    removeByName(name) {

        return this.connection.remove({
            from: this.tableName,
            where: {
                Name: name
            }
        });
    }
}