/* This page is used to update the role names */

import React, { useEffect,useState } from 'react';
import { Button, FormLabel, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { HttpService } from '../../../apiAuthorization/httpService';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import AlertDialog from '../../common/alertDialog';

const useStyles = makeStyles(theme => ({
    button: {
        width: '15%',
        float: 'right'
    },
    colorCode: {
        marginRight: '5px', color: 'inherit'
    },
    legend: {
        width: '16px', height: '16px', display: 'inline-block'
    },
    listIcon: {
        float: 'left',
        marginRight: '5px'
    },
    listIconRight: {
        marginLeft: '25px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

export default function Roles() {
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(false);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    useEffect(() => {
        getTeamRoles();
    }, []);

    const getTeamRoles = () => {
        HttpService.post('/Setting/GetTeamRoles')
            .then(
                res => {
                    const roles = res.data;
                    setTeamRoles({ ...teamRoles, data: roles });
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const [teamRoles, setTeamRoles] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Role Name', field: 'RoleName', width: '90%',
                validate: rowData => {
                    if (rowData.RoleName === undefined) {
                        rowData.RoleName = '';
                        return { isValid: true, helperText: '' };
                    }
                    else {
                        return rowData.RoleName.trim().length === 0 ? { isValid: false, helperText: 'Role Name is required' } : { isValid: true, helperText: '' };
                    }
                }
            }
        ],
        data:[]
    });

    const addTeamRole = model => {
        setLoading(true);
        model.Id = 0;
        HttpService.post('/Setting/AddTeamRole', model)
            .then(
                res => {
                    const teamRolesData = [...teamRoles.data];
                    teamRolesData.push(res.data);
                    setTeamRoles({ ...teamRoles, data: teamRolesData });
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    const updateTeamRole = model => {
        setLoading(true);
        HttpService.post('/Setting/UpdateTeamRole', model)
            .then(
                res => {
                    const teamRolesData = [...teamRoles.data];
                    const updatedItemIndex = teamRolesData.findIndex(x => x.Id === res.data.Id);
                    teamRolesData[updatedItemIndex] = res.data;
                    setTeamRoles({ ...teamRoles, data: teamRolesData });
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    const onRowDelete = model => {
        if (window.confirm("Are you sure you want to delete this item ?")) {
            setLoading(true);
            HttpService.post('/Setting/DeleteTeamRole', model)
                .then(
                    res => {
                        setLoading(false);
                        if (res.error && res.message) {
                            setErrorMessage(res.message);
                            setSeverity('error');
                            setAlertOpen(true);
                        }
                        getTeamRoles();
                    },
                    error => {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                        setLoading(false);
                    }
                );
        }

    };

    return (
        <div>
            <div className={classes.root}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color='primary'>
                                <h4 className={classes.cardTitleWhite}>Roles</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={loading}
                                    title=''
                                    options={{
                                        filtering: true,
                                        search: false,
                                        pageSize: 10,
                                        actionsColumnIndex: -1,
                                        addRowPosition: 'first',
                                        headerStyle: {
                                            fontWeight: 'bold',
                                            marginTop: '-10px'
                                        }
                                    }}
                                    localization={{
                                        body: { addTooltip: '' }
                                    }}
                                    icons={{
                                        Add: addProps => (
                                            <Tooltip title='Add Role'>
                                                <AddIcon className='AddIcon' {...addProps} />
                                            </Tooltip>
                                        )
                                    }}
                                    components={{
                                        Toolbar: tbProps => (
                                            <div className='ToolBar'>
                                                <MTableToolbar {...tbProps} />
                                            </div>
                                        )
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: rowData.IsSystemRole?'':'delete',
                                            tooltip: rowData.IsSystemRole ? '' :'Delete',
                                            disabled: rowData.Disabled || rowData.IsSystemRole,
                                            onClick: () => { onRowDelete(rowData) }
                                        })
                                    ]}
                                    columns={teamRoles.columns}
                                    data={teamRoles.data}
                                    editable={{
                                        isEditHidden: rowData => rowData.IsSystemRole == true,
                                        isDeleteHidden: rowData => rowData.IsSystemRole == true,
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addTeamRole(newData);
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        updateTeamRole(newData);
                                                    }
                                                }, 600);
                                            })
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
