/* This file is used to send the header information  */

import { authenticationService } from './authenticationService';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.Token) {
        return { Authorization: `Bearer ${currentUser.Token}`, 'Content-Type': 'application/json; charset=utf-8' };
    }
    else {
        return {};
    }
}
