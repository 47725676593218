import {
    BaseService
} from "./baseService";
import { OfflineModel, OfflineExtend } from '../../models/offlineModel';

export class OfflineService extends BaseService {

    constructor() {
        super();
        this.tableName = "Offlines";
        this.OfflineExtend = new OfflineExtend();
    }

    async getOfflines() {
        let offlines = await this.connection.select({
            from: this.tableName
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineExtend.decryption(new OfflineModel(), item));
            // return console.log(dOfflines); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)
        });

        return dOfflines;
    }

    async getOfflinesBySite(siteId, name) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                SiteId: siteId,
                Name: name
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineExtend.decryption(new OfflineModel(), item));
            // return console.log(dOfflines); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)
        });

        return dOfflines[0];
    }

    async getOfflinesByName(siteId, name) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                SiteId: siteId,
                Name: name
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineExtend.decryption(new OfflineModel(), item));
        });

        return dOfflines[0];
    }

    async getOfflinesById(id) {
        let offlines = await this.connection.select({
            from: this.tableName,
            where: {
                Id:id
            }
        });

        let dOfflines = [];

        offlines.map(item => {
            dOfflines.push(this.OfflineExtend.decryption(new OfflineModel(), item));
        });

        return dOfflines[0];
    }

    removeOfflineBySite(siteId, name) {

        return this.connection.remove({
            from: this.tableName,
            where: {
                SiteId: siteId,
                Name: name
            }
        });
    }

    addOffline(siteId, name, data) {

        let offlineModel = new OfflineModel(null, siteId, name, data);
        this.OfflineExtend.encryption(offlineModel, offlineModel);

        return this.connection.insert({
            into: this.tableName,
            values: [offlineModel],
            skipDataCheck: true,
            return: false // since id is autoincrement field and we need id, 
        });
    }

    async updateOfflineById(id, updateData) {

        let offlineModel = new OfflineModel(null, null, null, updateData);
        this.OfflineExtend.encryption(offlineModel, offlineModel);

        return this.connection.update({
            in: this.tableName,
            set: {
                OfflineData : offlineModel.OfflineData
            },
            where: {
                Id: id
            }
        });
    }

    removeOfflineByName(name) {
        return this.connection.remove({
            from: this.tableName,
            where: {
                Name: name
            }
        });
    }
}