/* This file is used to store offline data for a long time */

import { DATA_TYPE } from 'jsstore';
const OfflineSchema = {
    name: 'Offlines',
    columns: {
        Id: {
            notNull: true,
            dataType: DATA_TYPE.Number
        },
        SiteId: {
            notNull: true,
            dataType: DATA_TYPE.Number
        },
        Name: {
            dataType: DATA_TYPE.String
        },
        OfflineData: {
            dataType: DATA_TYPE.String
        }
    }
};

export default OfflineSchema;