import React, { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "../CustomButtons/Button.js";
import styles from "../../../assets/jss/material-dashboard-react/components/headerStyle.js";
import Typography from '@material-ui/core/Typography';
import { Context } from "../../contexts/siteContext";

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(styles);

const myStyles = makeStyles(theme => ({
    logo: {
        width: '90%'
    },
    logoMD: {
        width: '45%'
    },
    cursor: {
        cursor: 'pointer'
    }
}));

export default function Header(props) {
    const classes = useStyles();
    const myClasses = myStyles();
    const { defaultSite } = useContext(Context);

    //function makeBrand() {
    //  var name;
    //  props.routes.map(prop => {
    //    if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
    //      name = props.rtlActive ? prop.rtlName : prop.name;
    //    }
    //    return null;
    //  });
    //  return name;
    //}
    const { color } = props;
    const appBarClasses = classNames({
        [" " + classes[color]]: color
    });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Grid container spacing={1}>
                    <Grid container item xs={4} spacing={1} alignItems="center">
                        <div className={classes.flex}>
                            {/* Here we create navbar brand, based on route name */}
                            <Typography>
                                {defaultSite.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container item xs={4} spacing={1} justify="center" alignItems="center">
                        <Hidden mdDown >
                            <img alt="logo" className={myClasses.logoMD} src="/static/images/TeamClean-Logo-white.svg" />
                        </Hidden>
                        <Hidden lgUp >
                            <img alt="logo" className={myClasses.logo} src="/static/images/TeamClean-Logo-white.svg" />
                        </Hidden>
                    </Grid>
                    <Grid container item xs={4} spacing={1} justify="flex-end" alignItems="center">
                        <Hidden smDown implementation="css">
                            <AdminNavbarLinks />
                        </Hidden>

                        <Hidden mdUp implementation="css">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={props.handleDrawerToggle}
                            >
                                <Menu />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown >
                            {
                                props.subMenuOpen ? <MoreVertIcon className={myClasses.cursor}  onClick={props.handleDrawerMenuToggle}/> : <Menu className={myClasses.cursor} onClick={props.handleDrawerMenuToggle} /> 
                                
                            }
                        </Hidden>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    handleDrawerMenuToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object),
    subMenuOpen: PropTypes.bool
};
