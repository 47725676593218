import { HttpService } from "../../apiAuthorization/httpService";
import { authenticationService } from '../../apiAuthorization/authenticationService';
import { OfflineSyncService } from "../../offline/storageService/offlineSyncService";

const CheckOnline = () => {

    setInterval(() => {
        const currentUser = authenticationService.currentUserValue;
        var url = window.location.pathname.toLowerCase().indexOf("/home");
        if (url !=-1  && currentUser && currentUser.Token) {
            HttpService.post("/Home/COL", null)
                .then(async (result) => {

                    if (result && !result.offline) {
                        //sync data 
                        var offlineSyncDatas = await GetOfflineSyncDatas();
                        if (offlineSyncDatas && offlineSyncDatas.length > 0) {
                            const offlineSyncService = new OfflineSyncService();
                            syncData(offlineSyncService, offlineSyncDatas);
                        }

                        sessionStorage.setItem("isOffline", 0);
                    }
                    else {
                       sessionStorage.setItem("isOffline",1);
                    }
                },
                (error) => {
                    console.log(error);
                });
        }
    }, 5000);
};

const GetOfflineSyncDatas = () => {

    const offlineSyncService = new OfflineSyncService();
    return offlineSyncService.getOfflineSyncs();
};

const syncData = (offlineSyncService,offlineDatas) => {

    if (offlineDatas.length === 0) return;

    let item = offlineDatas[0];

     HttpService.post(item.Url, item.OfflineData)
        .then((result) => {

            offlineSyncService.removeById(item.Id);
            offlineDatas.splice(0, 1);
            syncData(offlineSyncService,offlineDatas);
        },
        (error) => {
            console.log(error);
        });
};

export {
    CheckOnline
}