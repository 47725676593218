/* Main page of the application */

import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './apiAuthorization/history';
import Admin from './components/layout/admin';
import Home from './components/home/home';
import AreaDetail from './components/area/areaDetail';
import Login from './components/login/login';
import { PrivateRoute } from './apiAuthorization/privateRoute';
import { SiteContext } from './components/contexts/siteContext';
import './css/site.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Router basename={baseUrl} history={history}>
                <Switch>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <SiteContext>
                            <Route path="/Admin" component={Admin} />
                            <PrivateRoute path='/Home' component={Home} />
                            <PrivateRoute path='/AreaDetail/:id/:colorId' component={AreaDetail} />
                        </SiteContext>
                    </Switch>
                </Switch>
            </Router>
        );
    }
}