/* This page is used to update the locations */

import React, { useEffect, useContext, useState } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, TableRow } from '@material-ui/core';
import AlertDialog from '../../common/alertDialog';
import { Context } from "../../contexts/siteContext";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CustomDialogs from '../../modals/customModal';
import AddLocationfrom from './location-add';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));


export default function Locations() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(true);
    const [locationsFromTL, setLocationsFromTL] = React.useState([]);
    const [modal, setModal] = useState(false);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [locations, setLocations] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Location Name', field: 'LocationName', width: '100%', 
                validate: rowData => {
                    if (rowData.LocationName === undefined) {
                        rowData.LocationName = '';
                        return { isValid: true, helperText: '' };
                    } else if (rowData.LocationName.trim().length === 0) {
                        return { isValid: false, helperText: 'Location Name is required' };
                    } else {
                        return { isValid: true, helperText: '' };
                    }
                }
            }
        ],
        data: []
    });

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
    };

    const getLocations = () => {
        setLoading(true);
        handleModalClose();
        HttpService.post('/Setting/GetLocationsBySite/' + defaultSite.id)
            .then(
                res => {
                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    setLocations({ ...locations, data: res.locations });
                    setLocationsFromTL(res.locationsFromTL);
                    
                },
                error => {
                    setLoading(false);
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const updateLocation = model => {
        setLoading(true);
        HttpService.post('/Setting/UpdateLocation', model)
            .then(
                res => {
                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    getLocations();
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    const onRowDelete = model => {
        
        if (window.confirm("Are you sure you want to delete this item ?")) {
            setLoading(true);
            HttpService.post('/Setting/DeleteLocation', model)
                .then(
                    res => {
                        setLoading(false);
                        if (res.error && res.message) {
                            setErrorMessage(res.message);
                            setSeverity('error');
                            setAlertOpen(true);
                        }
                        getLocations();
                       
                    },
                    error => {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                        setLoading(false);
                    }
                );
        }
      
    };

    useEffect(() => {
        getLocations();
    }, [defaultSite.id]);

    return (
        <div className={classes.root}>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />

            <CustomDialogs open={modal} close={handleModalClose} title="Add Locations" >
                <AddLocationfrom locationsFromTL={locationsFromTL} onRefreshLocations={getLocations}/>
            </CustomDialogs>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <Grid container justify="space-between">
                                <Grid item xs={6}>
                                    <h4 className={classes.cardTitleWhite}>Locations</h4>
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title='Add Item' style={{ float: 'right' }} onClick={handleModalOpen}>
                                        <IconButton  >
                                            <AddIcon style={{ color: 'white' }} />
                                        </IconButton  >
                                    </Tooltip>
                                </Grid>
                            </Grid>
                           
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    filtering: true,
                                    search: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                actions={[
                                    rowData=>( {
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        disabled: rowData.Disabled,
                                        onClick: () => { onRowDelete(rowData) }
                                    })
                                ]}
                                columns={locations.columns}
                                data={locations.data}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateLocation(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
