/* Code for Cleaning Routine Admin Tab */
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Button from "../CustomButtons/Button.js";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import { TextField, Select, InputLabel, FormLabel, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText } from '@material-ui/core/';
import RoutineItem from '../cleaningRoutineAdmin/routineItem';
import RoutineTemplate from '../cleaningRoutineAdmin/routineTemplate';
import CustomDialogs from '../../modals/customModal';
import MaterialTable from 'material-table';
import Code from "@material-ui/icons/Code";
import Tabs from "../CustomTabs/CustomTabs";
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import AlertDialog from '../../common/alertDialog';
import { GetTime, GetDate } from '../../../components/contexts/timeFormatter';

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const useStyles = makeStyles(theme => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    inputControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function CleaningRoutineAdmin() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [scheduleLoading, setScheduleLoading] = useState(true);
    const [scheduleRoutineView, setScheduleRoutineView] = useState([]);

    

    const getScheduleRoutines = () => {
        setScheduleLoading(true);
        HttpService.post("/CleaningRoutineAdmin/GetScheduleRoutinesView", { SiteId: defaultSite.id })
            .then(
                data => {
                    //console.log(data);
                    setScheduleRoutineView(data.data);
                    setScheduleLoading(false);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    //--------------------- Data request function  ------------------------------------

    useEffect(() => {
        if (defaultSite) {
            getScheduleRoutines();
        }
    }, [defaultSite]);

    return (
        <div>

            <Tabs
                headerColor="primary"
                tabs={[
                    {
                        tabName: "Schedule Routines",
                        tabContent: (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: '0px' }}>
                                        <CardBody style={{ paddingTop: '0px', marginTop: '0px' }}>
                                            <RoutineTemplate loading={scheduleLoading} tableType="schedule" data={scheduleRoutineView} />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )
                    },
                ]}
            />
        </div>
    );
}
