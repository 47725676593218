/* This file is used to redirect to the page based on the authorization */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from './authenticationService';

export const PrivateAdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (currentUser && currentUser.Token) {
            if (currentUser.Url !== 'Admin') {
                return <Redirect to={{ pathname: '/Home', state: { from: props.location } }} />;
            }

            return <Component {...props} />;
        }

        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }} />
);
