/* This page is used to update the role names */

import React, { useState } from 'react';
import { Button, FormLabel, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { HttpService } from '../../../apiAuthorization/httpService';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import AlertDialog from '../../common/alertDialog';

const useStyles = makeStyles(theme => ({
    button: {
        width: '15%',
        float: 'right'
    },
    colorCode: {
        marginRight: '5px', color: 'inherit'
    },
    legend: {
        width: '16px', height: '16px', display: 'inline-block'
    },
    listIcon: {
        float: 'left',
        marginRight: '5px'
    },
    listIconRight: {
        marginLeft: '25px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

export default function RoleDialog(props) {
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(false);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [teamRoles, setTeamRoles] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Role Name', field: 'RoleName', width: '90%',
                validate: rowData => {
                    if (rowData.RoleName === undefined) {
                        rowData.RoleName = '';
                        return { isValid: true, helperText: '' };
                    }
                    else {
                        return rowData.RoleName.trim().length === 0 ? { isValid: false, helperText: 'Role Name is required' } : { isValid: true, helperText: '' };
                    }
                }
            }
        ],
        data: props.teamRoles.data
    });

    const addTeamRole = model => {
        setLoading(true);
        model.Id = 0;
        HttpService.post('/Setting/AddTeamRole', model)
            .then(
                res => {
                    const teamRolesData = [...teamRoles.data];
                    teamRolesData.push(res.data);
                    setTeamRoles({ ...teamRoles, data: teamRolesData });
                    props.handleRoleNameChanged(teamRolesData);
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    const updateTeamRole = model => {
        setLoading(true);
        HttpService.post('/Setting/UpdateTeamRole', model)
            .then(
                res => {
                    const teamRolesData = [...teamRoles.data];
                    const updatedItemIndex = teamRolesData.findIndex(x => x.Id === res.data.Id);
                    teamRolesData[updatedItemIndex] = res.data;
                    setTeamRoles({ ...teamRoles, data: teamRolesData });
                    props.handleRoleNameChanged(teamRolesData);
                    setLoading(false);
                },
                error => {
                    if (error.indexOf('This name already exists') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    return (
        <div>
            <Dialog maxWidth='lg' open={props.openDialog} onClose={props.handleCloseDialog} aria-labelledby='form-dialog-title'>
                <DialogContent className={classes.root} >
                    <DialogContentText style={{ marginBottom: '0px' }}>
                        <FormLabel className="selLocation" style={{ lineHeight: '1.5' }}>Add / Edit Roles </FormLabel>
                    </DialogContentText>
                    <div className={classes.root}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color='primary'>
                                        <h4 className={classes.cardTitleWhite}>Roles</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <MaterialTable
                                            isLoading={loading}
                                            title=''
                                            options={{
                                                filtering: true,
                                                search: false,
                                                pageSize: 5,
                                                actionsColumnIndex: -1,
                                                addRowPosition: 'first',
                                                headerStyle: {
                                                    fontWeight: 'bold',
                                                    marginTop: '-10px'
                                                }
                                            }}
                                            localization={{
                                                body: { addTooltip: '' }
                                            }}
                                            icons={{
                                                Add: addProps => (
                                                    <Tooltip title='Add Role'>
                                                        <AddIcon className='AddIcon' {...addProps} />
                                                    </Tooltip>
                                                )
                                            }}
                                            components={{
                                                Toolbar: tbProps => (
                                                    <div className='ToolBar'>
                                                        <MTableToolbar {...tbProps} />
                                                    </div>
                                                )
                                            }}
                                            columns={teamRoles.columns}
                                            data={teamRoles.data}
                                            editable={{
                                                onRowAdd: newData =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            addTeamRole(newData);
                                                        }, 600);
                                                    }),
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            if (oldData) {
                                                                updateTeamRole(newData);
                                                            }
                                                        }, 600);
                                                    })
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCloseDialog} color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
