/* This page is used to update the system variables */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import AlertDialog from '../../common/alertDialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function SystemVariables() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [systemVariables, setSystemVariables] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            { title: 'Variable Name', field: 'VariableName', editable: 'never', width: '30%' },
            {
                title: 'Variable Value', field: 'VariableValue', width: '20%',
                validate: rowData => {

                    if (rowData.VariableName == "EmailRecipientList") {
                        return (rowData.VariableValue.trim().length === 0) ? { isValid: false, helperText: "Value cannot be empty" } : { isValid: true, helperText: '' };
                    }

                    return (rowData.VariableValue.trim().length === 0 || isNaN(rowData.VariableValue) || rowData.VariableValue < 0) ? { isValid: false, helperText: 'Enter a positive integer' } : { isValid: true, helperText: '' };
                }
            },
            {
                title: 'Variable Description', field: 'VariableDescription', width: '45%',
                validate: rowData => {
                    return rowData.VariableDescription.trim().length === 0 ? { isValid: false, helperText: 'Description is required' } : { isValid: true, helperText: '' };
                }
            }
        ],
        data: []
    });

    const getSystemVariables = () => {
        HttpService.post('/Setting/GetSystemVariables')
            .then(
                res => {
                    setSystemVariables({ ...systemVariables, data: res.data });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const updateSystemVariable = model => {
        setLoading(true);
        model.VariableValue = model.VariableValue + '';
        HttpService.post('/Setting/UpdateSystemVariable', model)
            .then(
                res => {
                    const sysVariables = [...systemVariables.data];
                    const updatedItemIndex = sysVariables.findIndex(x => x.Id === res.data.Id);
                    sysVariables[updatedItemIndex] = res.data;
                    setSystemVariables({ ...systemVariables, data: sysVariables });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    useEffect(() => {
        getSystemVariables();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>System Variables</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                columns={systemVariables.columns}
                                data={systemVariables.data}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateSystemVariable(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
