/* This file is used to store offline data that needs to be synchronized */

import { DATA_TYPE } from 'jsstore';
const OfflineSyncSchema = {
    name: 'OfflineSyncs',
    columns: {
        Id: {
            notNull: true,
            dataType: DATA_TYPE.Number
        },
        SiteId: {
            notNull: true,
            dataType: DATA_TYPE.Number
        },
        EntityId: {
            dataType: DATA_TYPE.Number
        },
        Url: {
            dataType: DATA_TYPE.String
        } ,
        Name: {
            dataType: DATA_TYPE.String
        },
        OfflineData: {
            dataType: DATA_TYPE.String
        }
    }
};

export default OfflineSyncSchema;