/* This page is used to update the user system role and employee team roles */

import React, { useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import AlertDialog from '../../common/alertDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: '0.7rem'
    }
}));

const stringToHslColor = function (str, s, l) {
    let hash = 0;
    for (let index = 0; index < str.length; index++) {
        hash = str.charCodeAt(index) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export default function Users() {
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(true);
    const [inputValue, setInputValue] = React.useState('');
    const [empTeamRoles, setEmpTeamRoles] = useState([]);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [teamRoles, setTeamRoles] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            { title: 'Role Name', field: 'RoleName' }
        ],
        data: []
    });

    const [users, setUsers] = useState({
        columns: [
            {
                title: '',
                field: 'HeadImage',
                render: rowData => (
                    (rowData.HeadImage !== null && rowData.HeadImage !== '') ? <Avatar className={classes.small} src={rowData.HeadImage} /> :
                        <Avatar className={classes.small} style={{ backgroundColor: stringToHslColor(rowData.Abbreviation, 50, 30) }}>{rowData.Abbreviation}</Avatar>
                ),
                filtering: false,
                editable: 'never'
            },
            { title: 'Id', hidden: true, field: 'AppUserId' },
            { title: 'Name', field: 'DisplayName', editable: 'never', width: '20%' },
            { title: 'User Name', field: 'Username', editable: 'never', width: '20%' },
            { title: 'System Role', field: 'TeamCleanRole', width: '20%' },
            { title: 'Employee Team Role', field: 'EmployeeTeamRoles', width: '40%', render: rowData => (rowData.EmployeeRoleNames) }
        ],
        data: []
    });

    const filterEmpRoleDDL = data => {
        data[5].customFilterAndSearch = (term, rowData) => {
            let exist = false;

            if (rowData.EmployeeTeamRoles.length > 0) {
                for (let index = 0; index < rowData.EmployeeTeamRoles.length; index++) {
                    exist = term.indexOf(rowData.EmployeeTeamRoles[index].TeamRoleId.toString()) > -1;
                    if (exist === true) {
                        break;
                    }
                }
            }

            return (
                term.length === 0 ? true : exist
            );
        };
    };

    const fillEmpRoleDDL = (data, empRoles) => {
        data[5].editComponent = props => {
            const roles = props.rowData.EmployeeTeamRoles;
            const selectedRoles = [];

            if (roles.length > 0) {
                empRoles.map(item => {
                    const selectedRole = roles.filter(x => x.TeamRoleId === item.Id);
                    if (selectedRole.length > 0) {
                        selectedRoles.push(item);
                    }
                    return selectedRoles;
                });
            }

            return (
                <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags-demo"
                    options={empRoles}
                    disableCloseOnSelect
                    disableClearable
                    defaultValue={selectedRoles.map(item => { return item; })}
                    getOptionDisabled={option => {
                        const selectedRole = selectedRoles.filter(x => x.Id === option.Id);
                        return (selectedRole.length > 0);
                    }}
                    onChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    getOptionLabel={option => option.RoleName}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.RoleName}
                        </React.Fragment>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.RoleName}
                                {...getTagProps({ index })}
                                disabled={selectedRoles.indexOf(option) !== -1}
                            />
                        ))
                    }
                    style={{ width: 500 }}
                    renderInput={params => (
                        <TextField {...params} label="" placeholder="" />
                    )}
            />);
        };

        setUsers({ ...users, columns: data });
    };

    const getEmployeeTeamRoles = () => {
        HttpService.post('/Setting/GetEmployeeTeamRoles')
            .then(
                res => {
                    setEmpTeamRoles(res.data);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    const getUsers = roles => {
        HttpService.post('/Setting/GetUsers')
            .then(
                res => {
                    for (let index = 0; index < res.data.length; index++) {
                        const user = res.data[index];
                        const employeeRoles = empTeamRoles.filter(x => x.UserId === user.AppUserId);
                        user.EmployeeTeamRoles = [];

                        if (employeeRoles.length > 0) {
                            let employeeRoleNames = '';
                            for (let iIndex = 0; iIndex < employeeRoles.length; iIndex++) {
                                user.EmployeeTeamRoles.push(employeeRoles[iIndex]);
                                const selectedRole = roles.filter(x => x.Id === employeeRoles[iIndex].TeamRoleId);
                                if (selectedRole.length > 0) {
                                    employeeRoleNames += (employeeRoleNames === '') ? selectedRole[0].RoleName : ', ' + selectedRole[0].RoleName;
                                }
                            }
                            user.EmployeeRoleNames = employeeRoleNames;
                        }
                        else {
                            user.EmployeeRoleNames = '';
                        }
                    }

                    const data = [...users.columns];
                    filterEmpRoleDDL(data);

                    setUsers({ ...users, data: res.data });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    const getTeamRoles = () => {
        HttpService.post('/Setting/GetTeamRoles')
            .then(
                res => {
                    setTeamRoles({ ...teamRoles, data: res.data });
                    // For Team Roles dropdownlist look up data
                    const systemRoles = res.data.filter(x => x.IsSystemRole === true);
                    const groups = {};
                    for (let index = 0; index < systemRoles.length; index++) {
                        groups[systemRoles[index].Id] = systemRoles[index].RoleName;
                    }
                    const data = [...users.columns];
                    data[4].lookup = groups;
                    setUsers({ ...users, columns: data });
                    // set emp roles
                    const empRoles = res.data.filter(x => x.IsSystemRole === false);
                    const roleGroups = {};
                    for (let index = 0; index < empRoles.length; index++) {
                        roleGroups[empRoles[index].Id] = empRoles[index].RoleName;
                    }
                    data[5].lookup = roleGroups;
                    // overriding edit component for Dropdownlist for employee roles
                    fillEmpRoleDDL(data, empRoles);
                    setUsers({ ...users, columns: data });
                    getUsers(res.data);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    const updateEmployeeTeamRole = (model, empRoles) => {
        const itemIndex = users.data.findIndex(item => item.AppUserId === model.AppUserId);
        const usersData = users.data;
        let employeeRoleNames = '';

        for (let index = 0; index < empRoles.length; index++) {
            const item = empRoles[index];
            model.TeamCleanRole = parseInt(item.Id, 10);
            const roleIndex = teamRoles.data.findIndex(x => x.Id === item.Id);
            model.RoleName = teamRoles.data[roleIndex].RoleName;
            employeeRoleNames = (employeeRoleNames === '') ? model.RoleName : employeeRoleNames + ', ' + model.RoleName;

            HttpService.post('/Setting/AddEmployeeTeamRole', model)
                .then(
                    res => {
                        usersData[itemIndex].EmployeeTeamRoles.push(res.data);
                        setUsers({ ...users, data: usersData });
                    },
                    error => {
                        setErrorMessage(error);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                );
        }

        usersData[itemIndex].EmployeeRoleNames = employeeRoleNames;
        setUsers({ ...users, data: usersData });
    };

    const updateUser = model => {
        setLoading(true);
        if (model.TeamCleanRole !== null) {
            model.TeamCleanRole = parseInt(model.TeamCleanRole, 10);
            const roleIndex = teamRoles.data.findIndex(item => item.Id === model.TeamCleanRole);
            model.RoleName = teamRoles.data[roleIndex].RoleName;
        }

        HttpService.post('/Setting/UpdateUser', model)
            .then(
                res => {
                    const itemIndex = users.data.findIndex(item => item.AppUserId === res.data.AppUserId);
                    const usersData = users.data;
                    usersData[itemIndex].TeamCleanRole = model.TeamCleanRole;
                    usersData[itemIndex].RoleName = model.RoleName;
                    setUsers({ ...users, data: usersData });
                    if (inputValue.length > 0) {
                        updateEmployeeTeamRole(model, inputValue);
                        setInputValue('');
                    }
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    useEffect(() => {
        if (empTeamRoles.length === 0) {
            getEmployeeTeamRoles();
        }
        if (empTeamRoles.length > 0) {
            getTeamRoles();
        }
        // eslint-disable-next-line
    }, [empTeamRoles]);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>Users</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                isLoading={loading}
                                title=''
                                columns={users.columns}
                                data={users.data}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateUser(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
