import { BaseModel } from './baseModel';

export class OfflineSyncModel {
    Id;
    SiteId;
    EntityId;
    Url;
    Name;
    OfflineData;
   

    constructor(Id, SiteId, EntityId, Url, Name, OfflineData) {
        this.Id = Id === null ? Date.now() : Id;
        this.SiteId = SiteId === null ? null : SiteId;
        this.EntityId = EntityId === null ? -1 : EntityId;
        this.Url = Url;
        this.Name = Name === null ? null : Name;
        this.OfflineData = OfflineData === null ? null : OfflineData;
    }
}

export class OfflineSyncExtend extends BaseModel {

    constructor() {
        super(['OfflineData']);  // need encryption column

    }

    fillToProp = (obj, prop) => {
        prop.Id = obj.Id;
        prop.SiteId = obj.SiteId;
        prop.EntityId = obj.EntityId;
        prop.Url = obj.Url;
        prop.Name = obj.Name;
        prop.OfflineData = obj.OfflineData;
  
    };
}