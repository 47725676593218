import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@mdi/react'
import { mdiQrcodeScan } from '@mdi/js';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '10px',
        width: '100%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    statusBox: {
        position: "absolute",
    },
    avatar: {
        backgroundColor: red[500],
        marginLeft: '5px',
        marginTop: '-13px',
        width: 'auto',
        height: 'auto',
        padding: '5px',
        paddingLeft: '15px',
        paddingRight: '15px',
        borderRadius: '50px',
        fontSize: '12px',
        float:'left',
    },
    cardBorder: {
        borderWidth: '3px',
        borderStyle: 'solid'
    },
    cardHeader: {
        paddingBottom: '0px'
    },
    cardContent: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '30px'
    },
    cardAction: {
        paddingTop: '0px'
    },
    yellowB: {
        borderColor: '#f3cf80'
    },
    yellowC: {
        backgroundColor: '#f3cf80'
    },
    blueC: {
        backgroundColor: '#7c88ca'
    },
    orangeC: {
        backgroundColor: '#f3a36b'
    },
    greenC: {
        backgroundColor: '#9ed263'
    },
    cyanC: {
        backgroundColor: '#2ac1b3'
    },
    red: {
        backgroundColor: '#d22839'
    },
    yellowD: {
        backgroundColor: '#e2b00a'
    },
    center: {
        margin: "auto"
    },
    addIcon: {
        width: '36px',
        height: '30px'
    }
}));

const CardFooter = props => {
    const classes = useStyles();
    const { data, onAddToList, showQRModal } = props;

    const addToList = () => {
        onAddToList(data.id);
    };

    if (data.container === "AddToList") {

        return (
            <React.Fragment key={data.id}>
                <Tooltip title="add to list" aria-label="add" >
                    <Fab color="secondary" aria-label="add" className={`${classes.addIcon} green-add `} onClick={addToList}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <Typography variant="caption" className={`${classes.expand} font-weight-6`}>
                    {data.total} Items
                </Typography>
            </React.Fragment>
        )
    } else if (data.container === "Dropdown") {
        return null;
    } else {
        return (
            <>
            <Typography variant="caption" className={`${classes.center} font-weight-6`}>
                {data.total} Items 
            </Typography>
                {data.showQRCode ? <Icon path={mdiQrcodeScan}
                    title="QR Code Scan"
                    size={1}
                    horizontal
                    vertical
                    color="#e2b11c"
                    onClick={showQRModal}
                    style={{ cursor: 'pointer'}}
                /> : null}
          </>
        )
    }
};


export default function AreaCard(props) {
    const classes = useStyles();
    const { data, showQRModal, notShowExpired } = props;

    useEffect(() => {
       // console.log(notShowExpired);
    },[]);

    return (
        <Card className={`${classes.root} ${classes.cardBorder}`}
            style={{ borderColor: data.borderColor }}
        >
            <div className={classes.statusBox}>
                {
                    data.container !== "Dropdown" ?
                        <>
                            <Avatar aria-label="recipe" className={classes.avatar} style={{ 'backgroundColor': `${data.borderColor}` }}>
                                {data.state}
                            </Avatar>
                            {
                                (data.expired && !notShowExpired) ?
                                <Avatar aria-label="recipe" className={`${classes.avatar} ${classes.red}`}>
                                        Expired
                                </Avatar> : null
                            }
                            {
                                data.incomplete ?
                                    <Avatar aria-label="recipe" className={`${classes.avatar} ${classes.red}`}>
                                        Incomplete
                                </Avatar> : null
                            }
                            {
                                data.missed ?
                                    <Avatar aria-label="recipe" className={`${classes.avatar} ${classes.red}`}>
                                        Missed
                                </Avatar> : null
                            }
                        </>

                        : null
                }
            </div>
          
            <CardHeader
                action={data.container === 'HomeDetailPage' ? data.time:null }
                title={data.itemName} 
                subheader={data.container === 'HomeDetailPage' ? `${data.areasName}` : data.areasName }
                className={`${classes.cardHeader} area-card-header`}
            >
             </CardHeader>
            <CardContent className={classes.cardContent}>
                {(data.container === 'HomeDetailPage' || data.container === 'Home' || data.container === 'Dropdown' )? "" :data.areasDetails.map((detail,index) => {
                       return(
                           <Typography key={index} variant="caption" display="block" gutterBottom>
                                {detail}
                            </Typography>
                        )
                    })}
            </CardContent>
            <CardActions className={classes.cardAction}>
                <CardFooter { ...props }/>
            </CardActions>
        </Card>
    );
}
