/* This file is used for login/logout process */

import { BehaviorSubject } from 'rxjs';
import { handleResponse } from './handleResponse';

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        if (!currentUserSubject || !currentUserSubject.value) {
            logout();
            return null;
        }
        return currentUserSubject.value;
    }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch('/account/authenticate', requestOptions)
        .then(handleResponse)
        .then(user => {
            //clear data
            sessionStorage.removeItem('currentUser');
            // store user details and jwt token in session storage to keep user logged in between page refreshes
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {

    var pathname = window.location.pathname || "";
    if (!pathname || pathname === "/") {
        return;
    }
    // remove user from session storage to log user out
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem("sites");
    sessionStorage.removeItem("defaultSite");
    currentUserSubject.next(null);
    window.location.pathname = "/";
    
}
