import { FormatTimeSpan, GetDiffDay, FormatCustom, CheckFuture } from '../components/contexts/timeFormatter';
export class AreaModelList {
    borderColor = 'rgb(109, 111, 111)';
    statusNotStarted = 'Not Started';
    stausInProgress = 'In Progress';
    itemName = "Cash Register/Counter Cleaning";
    headerTexts = [];
    templatesColor = [];
    _colorIndex = 1;

    colorArr = [
        { id:1, hoverColor: "#505252", borderColor: this.borderColor, color: 'blueC'} ,
        { id:2, hoverColor: "#68bf6c", borderColor: '#96d699', color:'blueC'} ,
        { id:3, hoverColor: "#6675cc", borderColor: 'rgb(124, 136, 202)', color: 'blueC' },

        { id:4, hoverColor: "#ea8d4d", borderColor: 'rgb(243, 163, 107)', color: 'blueC' },
        { id:5, hoverColor: "#16ad9f", borderColor: '#2ac1b3', color: 'blueC' },
        { id:6, hoverColor: "#93ca54", borderColor: '#9ed263', color: 'cyanC' },
    ];

    fill(item, defaultSite, container, colorId) {

        const areasDetail = [];
        let _headerText = "Today";

        item.ScheduleRoutineListItems.map((detail, index) => {
            if (index > 2) return null;
            areasDetail.push(`Item:${detail.ItemName}`);
            return null;
        });

        const diffday = GetDiffDay(item.ScheduleRoutines.StartDateTime, defaultSite);
        if (diffday === 0) {
            _headerText = "Today";
        } else if (diffday === 1) {
            _headerText = "Tomorrow";
        }
        else if (diffday === -1) {
            _headerText = "Yesterday";
        }
        else {
            _headerText = FormatCustom(item.ScheduleRoutines.StartDateTime, "dddd, MMMM Do YYYY");
        }

        if (this.headerTexts.indexOf(_headerText) !== -1 || !item.ScheduleRoutines.IsActive) _headerText = null;
            else
              this.headerTexts.push(_headerText);

         //not start color 
        let _color = {};

        if (!colorId) {
            let exist = null;
            this.templatesColor.map(tc => {

                if (tc.name.indexOf(item.ScheduleRoutines.ScheduleRoutineName) !== -1) {
                    exist = tc;
                }
                return null;
            });

            if (exist) {
                this.colorArr.map(c => {
                    if (c.id === exist.colorId) _color = c;
                    return null;
                });
            } else {
                if (this._colorIndex >= this.colorArr.length) this._colorIndex = 0;
                _color = this.colorArr[this._colorIndex];
                this.templatesColor.push({ name: item.ScheduleRoutines.ScheduleRoutineName, colorId: _color.id });
                this._colorIndex++;
            }
        } else {
            this.colorArr.map(c => {
                if (c.id === colorId) _color = c;
                return null;
            });
        }

        return {
            id: item.ScheduleRoutines.Id,
            hoverColor: _color.hoverColor,
            itemName: item.ScheduleRoutines.ScheduleRoutineName,
            areasName: container ==="Dropdown"? null:item.Location?.LocationName+" - "+item.Space?.SpaceName,
            areasDetails: areasDetail,
            total: item.ScheduleRoutineListItems.length,
            time: FormatTimeSpan(item.ScheduleRoutines.StartDateTime),
            readOnly: CheckFuture(item.ScheduleRoutines.StartDateTime, defaultSite, item.CleaningRoutineStartTimeBuffer), 
            color: _color.color,
            colorId: _color.id,
            state: item.State?.StatusName,
            borderColor: _color.borderColor,
            headerText: _headerText,
            subHeaderText: FormatCustom(item.ScheduleRoutines.StartDateTime, "ll"),
            diffdays: diffday,
            active: item.ScheduleRoutines.IsActive,
            expired:item.ScheduleRoutines.Expired,
            incomplete : item.ScheduleRoutines.Incomplete,
            missed: item.ScheduleRoutines.Missed,
            showItemTime:item.ShowItemTime,
            container,
            showQRCode: item.ScheduleRoutines.ShowQRCode,
            spaceId: item.Space?.Id,
            spaceName: item.Space?.SpaceName,
            qrBypassed: item.ScheduleRoutines.QRBypassed
        }
    }

    fillAdhoc(item, container) {

        const areasDetail = [];

        item.RoutinesTemplateListItems.map((detail, index) => {
            if (index > 2) return null;
            areasDetail.push(`Item:${detail.ItemName}`);
            return null;
        });

        //not start color
        let _color = {};
        let exist = null;
        this.templatesColor.map(tc => {

            if (tc.name.indexOf(item.RoutinesTemplate.RoutineTemplateName) !== -1) {
                exist = tc;
            }
            return null;
        });

        if (exist) {
            this.colorArr.map(c => {
                if (c.id === exist.colorId) _color = c;
                return null;
            });
        } else {
            if (this._colorIndex >= this.colorArr.length) this._colorIndex = 0;
            _color = this.colorArr[this._colorIndex];
            this.templatesColor.push({ name: item.RoutinesTemplate.RoutineTemplateName, colorId: _color.id });
            this._colorIndex++;
        }
      
        return {
            id: item.RoutinesTemplate.Id,
            hoverColor: _color.hoverColor,
            itemName: item.RoutinesTemplate.RoutineTemplateName,
            areasName: item.Space.SpaceName,
            areasDetails: areasDetail,
            total: item.RoutinesTemplateListItems.length,
            color: _color.color,
            colorId: _color.id,
            state: this.statusNotStarted,
            borderColor: _color.borderColor,
            container,
        }
    }

    fillDetail(item) {
        const areasDetail = [];

        item.ScheduleRoutineListItems.map(detail => {
            areasDetail.push({
                id: detail.Id,
                time: FormatTimeSpan(detail.StartDateTime),
                name: detail.ItemName,
                checked: detail.CompleteTime ? true : false,
                userId: detail.UserId,
                abbreviation: detail.UserInfo?.Abbreviation,
                description: detail.Description
            });
            return null;
        });

        return areasDetail;
    }

    areas(obj, defaultSite, container) {
        if (obj.length === 0) return [];

        const areas = [];
        this.headerTexts = [];
        obj.map(item => {
            areas.push(this.fill(item, defaultSite, container));
            return null;
        })
        return areas;
    }

    adhoc(obj, container) {
        const adhocs = [];

        obj.map(item => {

            adhocs.push(this.fillAdhoc(item, container));
            return null;
        })
        return adhocs;
    }

    getScheduleRoutineIdsForOffline(obj, defaultSite) {
        if (obj.length === 0) return [];

        const ids = [];

        obj.map(item => {
            if (!CheckFuture(item.ScheduleRoutines.StartDateTime, defaultSite, 90)) {
                ids.push(item.ScheduleRoutines.Id);
            }
        })
        return ids;
    }

    getArea(id) {
        let areaSelected = {};
        this.areasHardcode().map(area => {
            if (area.id === id) {

                areaSelected = area;
            }
            return null; 
       });

        return areaSelected;
    }
}