/* This file is used to fetch or update the data using POST method */

import { handleResponse } from './handleResponse';
import { authHeader } from './authHeader';

export const HttpService = {
    post
};

function post(url, data) {

    return fetch(url, { method: 'POST', headers: authHeader(), body: JSON.stringify(data) }).then(handleResponse).catch(function (error) {

        //console.log(error.toString());

        const offlineMessage = ["offline", "Failed to fetch", "not connect"];
        const errorMessage = error || "";

        if (errorMessage) {
            var isOffline = false;
            offlineMessage.forEach(m => {
                if (errorMessage.toString().indexOf(m) !== -1) {
                    isOffline = true;
                }
            });

            return { offline: isOffline, error: true, message: errorMessage};
        }
        else {
            return null;
        }

    });
}