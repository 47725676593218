import React, { useEffect, useContext } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Update from "@material-ui/icons/Update";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// core components
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import MaterialTable from 'material-table';
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AreaCard from '../../area/areaCard';
import AreaList from '../../area/areaList';
import ListSubheader from '@material-ui/core/ListSubheader';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import CardDetailModal from '../../modals/cardDetail';
import { AreaModelList } from '../../../models/areaModelList';
import { TimeFrom, TimeTo, GetTimeByCurrentTimeZone, FormatTimeSpan } from '../../../components/contexts/timeFormatter';
import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import { authenticationService } from '../../../apiAuthorization/authenticationService';
import Code from "@material-ui/icons/Code";
import Tabs from "../CustomTabs/CustomTabs";

const useStyles = makeStyles(theme => ({
    ...styles,
    root: {
        maxWidth: 345
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        }),
        padding: '0px',
        paddingBottom: '5px'
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    list: {
        maxHeight: '600px',
        overFlow: 'hidden'
    },
    time: {
        color: '#848181',
        marginRight: '10px',
        minWidth: '55px'
    },
    colorMarkContainer: {
        marginTop: '-40px'
    },
    colorDanger: {
        color: '#e53935'
    },
    colorSuccess: {
        color: '#43a047'
    },
    colorWarning: {
        color: '#fb8c00'
    },
    colorMessage: {
        color: '#fb8c00'
    },
    colorMark: {
        float: 'left'
    },
}));

const SwitchIcon = currentLevel => {

    if (currentLevel === 3)
        return <DoneOutlineOutlinedIcon />;
    else if (currentLevel === 2)
        return <InfoOutlinedIcon />;
    else
        return <WarningRoundedIcon />;
};

const SwitchTrend = (trending, color, style) => {

    var key = "";
    if (color == "success")
        key = "colorSuccess";
    else if (color == "warning")
        key = "colorWarning";
    else
        key = "colorDanger";


    if (trending === 0) {
        return <TrendingFlatIcon className={clsx(style[key], style.colorMark)} />
    } else if (trending === -1) {
        return <TrendingDownIcon className={clsx(style[key], style.colorMark)} />
    } else {
        return <TrendingUpIcon className={clsx(style[key], style.colorMark)} />
    }
};

export default function Dashboard() {
    const classes = useStyles();
    const { defaultSite, siteInit, setOpen } = useContext(Context);
    const [expanded, setExpanded] = React.useState([]);
    const [spaces, setSpaces] = React.useState([]);
    const [cardOpen, setCardOpen] = React.useState(false);
    const [openItemDetail, setOpenItemDetail] = React.useState(false);
    const [itemDetailTitle, setItemDetailTitle] = React.useState("");
    const [itemDetail, setItemDetail] = React.useState([]);
    const [itemDetailId, setItemDetailId] = React.useState(0);
    const [users, setUsers] = React.useState([]);

    useEffect(() => {

        if (defaultSite.id && siteInit) {
            getDashboardEmployeesStats();
            getDashboardRoutines();
        }

        // eslint-disable-next-line
    }, [defaultSite, siteInit]);

    useEffect(() => {

        if (spaces) {

            if (window.CleanerUpdateEventSource) {
                window.CleanerUpdateEventSource.close();
            }

            window.CleanerUpdateEventSource = new EventSource('/CleanerUpdate');
            window.CleanerUpdateEventSource.addEventListener('admin-notifications', message => {

                let item = JSON.parse(message.data);
                let newSpaces = [...spaces];
                let ext = false;
            
                newSpaces.forEach(x => {
                    if (x.Space.Id == item.SpaceId) {
                        
                        x.Messages.forEach(s => {
                            if (s.ScheduleRoutineId == item.ScheduleRoutineId)
                            {
                                ext = true;
                                s.Progress = item.Progress;
                                s.Hide = item.Hide;
                                x.LastUpdate = item.CurrentTime;
                            }
                        });

                        if (!ext) {
                            x.LastUpdate = item.CurrentTime;
                            x.Messages.push(item);
                        }
                    }
                });

                //console.log(newSpaces);
                setSpaces(newSpaces);

            });
        }

        const interval = setInterval(function () { getDashboardRoutines() }, 60000);
        return () => clearInterval(interval);

    }, [spaces]);

    let ps_title = null;
    const getDashboardRoutines = () => {

        if (!defaultSite || defaultSite.length == 0) return;
        setOpen(true);
        HttpService.post("/Dashboard/GetDashboardRoutines", { SiteId: defaultSite.id, Offset: Number(defaultSite.offset), CurrentTimeZone: GetTimeByCurrentTimeZone(defaultSite) })
            .then(
                data => {
                    //console.log(data);
                    let newSpaces = [...spaces];
                   
                    spaces.forEach(x => {
                        data.data.forEach(d => {
                            if (x.Space.Id == d.Space.Id) {
                                d.Messages = x.Messages;
                            }
                        });
                    });
                   
                    setSpaces(data.data);
                    setOpen(false);

                    setTimeout(() => {
                        data.data.forEach((space) => {
                            if (space.Active) {
                                ps_title = new PerfectScrollbar(`.ps-scroll-title-${space.Space.Id}`, {
                                    suppressScrollX: true,
                                    suppressScrollY: false,
                                });
                            }
                        });
                    }, 500);

                },
                error => {
                    //console.log(error);
                }
            );
    };

    const getDashboardEmployeesStats = () => {
        HttpService.post("/Dashboard/GetDashboardEmployeesStats", { SiteId: defaultSite.id, Offset: Number(defaultSite.offset) })
            .then(
                data => {
                    const userData = [];
                    data.data.map(u => {
                        userData.push({ Name: u.User.DisplayName, TotalCompletedToday: `${u.TotalCompletedToday}`, TotalCompletedYesterday: `${u.TotalCompletedYesterday}` });
                        return null;
                    });
                    setUsers(userData);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    let ps = null;
    const handleExpandClick = value => () => {
        const currentIndex = expanded.indexOf(value);
        const newExpanded = [...expanded];

        if (currentIndex === -1) {
            newExpanded.push(value);

            setTimeout(() => {

                ps = new PerfectScrollbar(`.ps-scroll-${value}`, {
                    suppressScrollX: true,
                    suppressScrollY: false,
                });

            }, 500);

        } else {
            newExpanded.splice(currentIndex, 1);
            if (ps) {
                ps.destroy();
                ps = null;
            }
        }
        setExpanded(newExpanded);
    };

    const isExpanded = value => expanded.indexOf(value) !== -1;

    const handleShowCardDetail = (scheduleRoutines, name) => {
        setItemDetailTitle(name);
        setItemDetail(areaModelList.areas(scheduleRoutines, defaultSite, "Dropdown"));
        setCardOpen(true);
    };

    const handleHideCardDetail = () => {
        setCardOpen(false);
    };

    const handleShowCardItemDetail = id => {
        setItemDetailId(id);
        setOpenItemDetail(true);
    };

    const handleHideCardItemDetail = () => {
        setOpenItemDetail(false);
    };
    const areaModelList = new AreaModelList();

    const switchColor = currentLevel => {
        if (currentLevel === 3)
            return "success";
        else if (currentLevel === 2)
            return "warning";
        else
            return "danger";
    };

    return (

        <div>
            <Tabs 
                headerColor="primary"
                tabs={[
                    {
                        tabName: "Spaces",
                        tabIcon: Code,
                        tabContent: (
                            <GridContainer>
                                {spaces.map(space =>
                                    space.Active ?
                                        <React.Fragment key={space.Space.Id}>
                                            <GridItem xs={12} sm={6} md={6} lg={4}>
                                                <Card>
                                                    <CardHeader color={switchColor(space.CompletionRate)} stats icon>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Grid item xs={3} sm={3} md={4}>
                                                                <CardIcon color={switchColor(space.CurrentLevel)}>
                                                                    {SwitchIcon(space.CurrentLevel)}
                                                                </CardIcon>
                                                                {SwitchTrend(space.Trending, switchColor(space.CurrentLevel), classes)}
                                                            </Grid>
                                                            <Grid item xs={9} sm={9} md={8}>
                                                                <p className={`${classes.cardCategory} ps-scroll-title-${space.Space.Id}`}  >{space.Location.LocationName}</p>
                                                                <h5 className={classes.cardTitle} onClick={() => { handleShowCardDetail(space.ScheduleRoutines, space.Space.SpaceName) }}>
                                                                    {space.Space.SpaceName}
                                                                </h5>
                                                            </Grid>
                                                        </Grid>
                                                    </CardHeader>
                                                    <CardFooter stats>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Grid container item xs={6} spacing={1}>
                                                                <div className={classes.stats}>
                                                                    <Update />
                                                                    Last cleaned {TimeFrom(space.LastUpdate, defaultSite).replace("in", "")} ago
                                                                <br/>
                                                 Next clean {TimeTo(space.NextUpdate, defaultSite) || 'in N/A'}
                                                                </div>
                                                            </Grid>
                                                            <Grid container item xs={5} spacing={1}>
                                                                {
                                                                    space.Messages.map(m =>
                                                                       !m.Hide? <React.Fragment key={space.Space.Id + '-' + m.ScheduleRoutineId}>
                                                                            <div className={`${classes.stats} ${classes.colorMessage}`}> {FormatTimeSpan(m.StartDateTime)} | {m.ScheduleRoutineName} | {m.Progress}</div>
                                                                        </React.Fragment> : null
                                                                        )
                                                                }
                                                                
                                                            </Grid>
                                                            <Grid container item xs={1} spacing={1} justify="flex-end" >
                                                                {
                                                                    (space.Role === "administrator" || space.Role === "manager") ?
                                                                        <div className={classes.stats}>
                                                                            <IconButton
                                                                                className={clsx(classes.expand, {
                                                                                    [classes.expandOpen]: isExpanded(space.Space.Id),
                                                                                })}
                                                                                onClick={handleExpandClick(space.Space.Id)}
                                                                                aria-expanded={isExpanded(space.Space.Id)}
                                                                                aria-label="show more"
                                                                            >
                                                                                <ExpandMoreIcon />
                                                                            </IconButton>
                                                                        </div> : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </CardFooter>
                                                    <Collapse in={isExpanded(space.Space.Id)} timeout="auto" unmountOnExit>
                                                        <CardContent>
                                                            <List className={`${classes.list} ps-scroll-${space.Space.Id}`} >

                                                                {areaModelList.areas(space.ScheduleRoutines, defaultSite, "Dropdown").map((currentRoutine, index) => {

                                                                    return (
                                                                        <React.Fragment key={index + 1}>
                                                                            {currentRoutine.headerText &&
                                                                                <ListSubheader className={classes.subheader}>{currentRoutine.headerText}
                                                                                    <Typography primary="Vacation" secondary={currentRoutine.subHeaderText} className={classes.time}> {currentRoutine.subHeaderText}</Typography>
                                                                                </ListSubheader>
                                                                            }
                                                                            <ListItem button onClick={() => { handleShowCardItemDetail(currentRoutine.id) }}>
                                                                                <Typography variant="caption" display="block" gutterBottom className={currentRoutine.time}>
                                                                                    {currentRoutine.time}
                                                                                </Typography>
                                                                                <AreaCard data={currentRoutine} notShowExpired={true} />
                                                                            </ListItem>
                                                                        </React.Fragment>
                                                                    )

                                                                })}
                                                            </List>
                                                        </CardContent>
                                                    </Collapse>
                                                </Card>
                                            </GridItem>
                                        </React.Fragment>
                                        : null
                                )}

                            </GridContainer>
                            )
                    },
                    {
                        tabName: "Employees Stats",
                        tabIcon: Code,
                        tabContent: (
                            <GridContainer>

                                {(authenticationService.currentUserValue.RoleName === "administrator" || authenticationService.currentUserValue.RoleName === "manager") ?
                                    <GridItem xs={12}>
                                        <Card>
                                            <CardBody>
                                                <MaterialTable
                                                    options={{
                                                        search: false,
                                                        actionsColumnIndex: -1,
                                                        toolbar: false,
                                                        headerStyle: {
                                                            fontWeight: 'bold',
                                                            marginTop: '-10px'
                                                        }
                                                    }}
                                                    title=""
                                                    columns=
                                                    {
                                                        [
                                                            { title: 'Name', field: 'Name' },
                                                            { title: 'Total Completed Today', field: 'TotalCompletedToday' },
                                                            { title: 'Total Completed Yesterday', field: 'TotalCompletedYesterday' },
                                                        ]
                                                    }
                                                    data={users}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    : null}

                            </GridContainer>
                            )
                    }
                ]} />

            

            

            <CardDetailModal open={cardOpen} close={handleHideCardDetail} title={itemDetailTitle} from={'routine'}>
                <List className={classes.list} >
                    {itemDetail.map(currentRoutine => (

                        <React.Fragment key={currentRoutine.id}>
                            {currentRoutine.headerText &&
                                <ListSubheader className={classes.subheader}>{currentRoutine.headerText}
                                    <Typography primary="Vacation" secondary={currentRoutine.subHeaderText} className={classes.time}> {currentRoutine.subHeaderText}</Typography>
                                </ListSubheader>
                            }
                            <ListItem button onClick={() => { handleShowCardItemDetail(currentRoutine.id) }}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.time}>
                                    {currentRoutine.time}
                                </Typography>
                                <AreaCard data={currentRoutine} notShowExpired={true} />
                            </ListItem>
                        </React.Fragment>

                    ))}
                </List>
            </CardDetailModal>

            <CardDetailModal open={openItemDetail} close={handleHideCardItemDetail} from={'arealist'}>
                <AreaList id={itemDetailId} readonly={true} notShowExpired={true} />
            </CardDetailModal>
        </div>
    );
}
