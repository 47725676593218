import { HttpService } from "../../apiAuthorization/httpService";
import { OfflineService } from "../storageService/offlineService";
import { OfflineSyncService } from "../storageService/offlineSyncService";
import { AreaModelList } from '../../models/areaModelList';
import { GetTimeByCurrentTimeZone, AddMinutes, AddHours } from '../../components/contexts/timeFormatter';
import { authenticationService } from '../../apiAuthorization/authenticationService';


const SetRoutineTemplatesForOffline = (defaultSite) => {
    const offlineService = new OfflineService();
    HttpService.post("/Home/GetRoutineTemplates", { SiteId: defaultSite.id })
        .then(async (result) => {
            if (result && result.offline) {
                return;
            }
            else if (result && result.error) {
                return;
            }

            //clear offline data befor
            offlineService.removeOfflineBySite(defaultSite.id, "GetRoutineTemplatesForOffline");
            //add to offline 
            offlineService.addOffline(defaultSite.id, "GetRoutineTemplatesForOffline", result);

        },
            error => {
                // console.log(error);
            }
        );
}

const SetStatus = (defaultSite) => {
    const offlineService = new OfflineService();
    HttpService.post("/Home/GetStatus")
        .then(async (result) => {
            if (result && result.offline) {
                return;
            }
            else if (result && result.error) {
                return;
            }

            //clear offline data befor
            offlineService.removeOfflineBySite(defaultSite.id, "GetStatus");
            //add to offline 
            offlineService.addOffline(defaultSite.id, "GetStatus", result);

        },
            error => {
                // console.log(error);
            }
        );
}

//load Home Schedule Routines Offline Data to Indexed DB
const SetHomeScheduleRoutinesForOffline = (defaultSite, result, keyName) => {

    const offlineService = new OfflineService();
    //clear offline data befor
    offlineService.removeOfflineBySite(defaultSite.id, keyName);
    //add to offline
    offlineService.addOffline(defaultSite.id, keyName, result);

    const areas = new AreaModelList();
    var ids = areas.getScheduleRoutineIdsForOffline(result.data, defaultSite);

    if (ids.length > 0) {
        SetHomeScheduleRoutineDetails(defaultSite, ids);
    }

    SetStatus(defaultSite);

};

//add Adhoc 
const AddToListForOffline = async (defaultSite, templateId) => {
    const offlineService = new OfflineService();
    let templates = await offlineService.getOfflinesByName(defaultSite.id, "GetRoutineTemplatesForOffline");
    let detail = {};
    templates.OfflineData.data.map(tplt => {
        if (tplt.RoutinesTemplate.Id === templateId) {
            //copy Routines Template object to detail
            detail = tplt;
        }
    });

    let status = await GetStatusOnOffline(offlineService, defaultSite);
    let newId = Number((Date.now()).toString().substring(0, 10));
    let currentTimeZone = GetTimeByCurrentTimeZone(defaultSite);
    const currentUser = authenticationService.currentUserValue;

    detail.State = status.notStarted;
    detail.Status = status.baseStatus;
    detail.ScheduleRoutines.Id = newId;
    detail.ScheduleRoutines.OfflineId = newId;
    detail.ScheduleRoutines.RoutineTemplateId = detail.RoutinesTemplate.Id;
    detail.ScheduleRoutines.ScheduleRoutineName = detail.RoutinesTemplate.RoutineTemplateName;
    detail.ScheduleRoutines.RoutineType = "Adhoc";
    detail.ScheduleRoutines.SpaceId = detail.RoutinesTemplate.SpaceId;
    detail.ScheduleRoutines.StatusId = status.notStarted.Id;
    detail.ScheduleRoutines.StartDateTime = currentTimeZone;
    detail.ScheduleRoutines.EndDateTime = AddHours(currentTimeZone, detail.RoutinesTemplate.RepeatHour);
    detail.ScheduleRoutines.ModifiedUser = currentUser.Username;
    detail.ScheduleRoutines.CreatedUser = currentUser.Username;
    detail.ScheduleRoutines.DateModified = currentTimeZone;
    detail.ScheduleRoutines.DateCreated = currentTimeZone;

    detail.RoutinesTemplateListItems.map((item, index) => {
        item.ScheduleRoutineId = newId;
        item.Id = newId + (index + 1);
        item.OfflineId = item.Id;

        //append more  property
        item.StartDateTime = AddMinutes(currentTimeZone, item.StartMinute);
        item.EndDateTime = AddMinutes(currentTimeZone, item.TimeToComplete);
        item.DateModified = currentTimeZone;
        item.DateCreated = currentTimeZone;
        item.CreatedUser = currentUser.Username;
        item.ModifiedUser = currentUser.Username;
        item.TimeZoneOffset = Number(defaultSite.offset);

        var user = {
            Abbreviation: null,
            AppUseUserName: null,
            AppUserId: 0,
            DisplayName: null,
            FirstName: null,
            HeadImage: null,
            LastName: null,
            Password: null,
            PplPeoEmail: null,
            PplPeoFirstName: null,
            PplPeoHomePhone: null,
            PplPeoLastName: null,
            PplPeoMiddleName: null,
            PplPerson: 0,
            RoleName: null,
            TeamCleanRole: null,
            Token: null,
            Url: null,
            Username: null
        };

        item["UserInfo"] = user;

        detail.ScheduleRoutineListItems.push(item);
    });

    const offlineSyncService = new OfflineSyncService();

    //add new  
    offlineSyncService.add(defaultSite.id, newId, "/Home/AddToListFromOffline", "AddToList", detail);


    // update detail/home list structure ....
    let detailListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline");
    let homeListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutinesByUsername");
    detailListData.OfflineData.data.push(detail);
    homeListData.OfflineData.data.unshift(detail);

    //update indedDB 
    await offlineService.updateOfflineById(detailListData.Id, detailListData.OfflineData);
    //update indedDB 
    await offlineService.updateOfflineById(homeListData.Id, homeListData.OfflineData);

    return newId;
};

const SetHomeScheduleRoutineDetails = async (defaultSite, ids) => {

    const offlineService = new OfflineService();

    HttpService.post("/Home/GetScheduleRoutineItemsDetailForOffline",
        {
            Ids: ids,
            Offset: Number(defaultSite.offset)
        })
        .then(async (result) => {

            //console.log("load Home Schedule Routine Details");

            if (result && result.offline) {
                return;
            }
            else if (result && result.error) {
                return;
            }

            //clear offline data befor
            await offlineService.removeOfflineBySite(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline");
            //add to offline 
            await offlineService.addOffline(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline", result);
        },
            (error) => {
                console.log(error);
            });

};

const UpdateOfflineHomeScheduleRoutineDetailWithOnline = async (defaultSite, id, onlineData) => {
    const offlineService = new OfflineService();
    let detailListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline");

    if (!detailListData) return;

    detailListData.OfflineData.data.map(item => {

        if (item.ScheduleRoutines.Id === onlineData.ScheduleRoutines.Id) {
            //update 
            item.ScheduleRoutineListItems = onlineData.ScheduleRoutineListItems;
        }
    });
    //update indedDB 
    await offlineService.updateOfflineById(detailListData.Id, detailListData.OfflineData);
};

const GetOfflineData = async (defaultSite, keyName) => {
    const offlineService = new OfflineService();
    return offlineService.getOfflinesBySite(defaultSite.id, keyName);
}

const GetScheduleRoutineDetailById = (id, details) => {

    if (details.length === 0) return null;

    let detail = [];

    details.map(item => {
        if (item.ScheduleRoutines.Id === id) {
            detail = item;
        }
    });

    return detail;
};

const SaveItemToComplete = async (scheduleRoutineId, defaultSite, entityId, keyName, param, url, isOnline) => {

    const offlineSyncService = new OfflineSyncService();
    if (entityId.toString().length < 10 && !isOnline) {
        let exist = await offlineSyncService.getByNameAndEntityId(entityId, keyName);
        if (exist) {
            //remove 
            offlineSyncService.removeById(exist.Id);
        }
        else {
            //add new 
            offlineSyncService.add(defaultSite.id, entityId, url, keyName, param);
        }
    }

    // update detail list structure ....
    const offlineService = new OfflineService();
    const currentUser = authenticationService.currentUserValue;

    let detailListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline1");
    let homeListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutinesByUsername");

    if (!homeListData || homeListData.OfflineData.data.length == 0) return;
    if (!detailListData || detailListData.OfflineData.data.length == 0) return;

    let status = homeListData.OfflineData.data[0].Status;

    let inProgress;
    let completed;
    let notStarted;
    status.map(s => {
        if (s.StatusName == "In Progress") {
            inProgress = s;
        } else if (s.StatusName == "Not Started") {
            notStarted = s;
        }
        else {
            completed = s;
        }
    });

    var allCompleted = true;
    var allNotStarted = true;
    var completeTime = GetTimeByCurrentTimeZone(defaultSite);

    detailListData.OfflineData.data.map(async item => {

        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            //get detail list 
            item.ScheduleRoutineListItems.map(detailItem => {
                if (detailItem.Id === entityId) {

                    if (detailItem.UserId > 0 && detailItem.CompleteTime != null) {
                        detailItem.CompleteTime = null;
                        detailItem.IsLate = false;
                        detailItem.StatusId = notStarted.Id;
                        detailItem.UserId = null;

                    }
                    else {
                        detailItem.UserId = currentUser.AppUserId;
                        detailItem.CompleteTime = completeTime;
                        detailItem.StatusId = completed.Id;
                    }
                }

                //check all completed
                if (!detailItem.CompleteTime) {
                    allCompleted = false;
                }

                if (detailItem.CompleteTime) {
                    allNotStarted = false;
                }

                if (detailItem.UserId == currentUser.AppUserId) {
                    detailItem.UserInfo.Abbreviation = currentUser.Abbreviation;
                    detailItem.UserInfo.DisplayName = currentUser.DisplayName;
                    detailItem.UserInfo.FirstName = currentUser.FirstName;
                    detailItem.UserInfo.LastName = currentUser.LastName;
                }
            });

            ////update addToList offline data
            //let exist = await offlineSyncService.getByEntityId(scheduleRoutineId);
            //if (exist) {
            //    //update 
            //    offlineSyncService.updateSyncOfflineById(exist.Id, item);
            //}
        }

    });
    //update indedDB 
    await offlineService.updateOfflineById(detailListData.Id, detailListData.OfflineData);

    // update home list structure ....

    homeListData.OfflineData.data.map(item => {

        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            if (allCompleted) {
                item.ScheduleRoutines.CompleteTime = completeTime;
                item.ScheduleRoutines.StatusId = completed.Id;
                item.State = completed;
            }
            else if (allNotStarted) {
                item.ScheduleRoutines.CompleteTime = null;
                item.ScheduleRoutines.StatusId = notStarted.Id;
                item.State = notStarted;
            }
            else {
                item.ScheduleRoutines.CompleteTime = null;
                item.ScheduleRoutines.StatusId = inProgress.Id;
                item.State = inProgress;
            }
        }

    });
    //update indedDB 
    await offlineService.updateOfflineById(homeListData.Id, homeListData.OfflineData);

};

const SaveNotesForOffline = async (scheduleRoutineId, defaultSite, keyName, param, url) => {

    const offlineSyncService = new OfflineSyncService();
    if (scheduleRoutineId.toString().length < 10) {
        let exist = await offlineSyncService.getByNameAndEntityId(scheduleRoutineId, keyName);
        if (exist) {
            //remove 
            offlineSyncService.removeById(exist.Id);
        }

        //add new 
        offlineSyncService.add(defaultSite.id, scheduleRoutineId, url, keyName, param);

    }

    // update detail list structure ....
    const offlineService = new OfflineService();
    const currentUser = authenticationService.currentUserValue;

    let detailListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline");
    let homeListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutinesByUsername");

    detailListData.OfflineData.data.map(async item => {

        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            item.ScheduleRoutines.Notes = param.Notes;
        }

    });
    //update indedDB 
    await offlineService.updateOfflineById(detailListData.Id, detailListData.OfflineData);

    // update home list structure ....
    homeListData.OfflineData.data.map((item, index) => {
        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            homeListData.OfflineData.data.splice(index, 1);
        }
    });
    //update indedDB 
    await offlineService.updateOfflineById(homeListData.Id, homeListData.OfflineData);
};

const SaveQROptionForOffline = async (scheduleRoutineId, defaultSite, keyName, param, url) => {
    const offlineSyncService = new OfflineSyncService();
    if (scheduleRoutineId.toString().length < 10) {
        let exist = await offlineSyncService.getByNameAndEntityId(scheduleRoutineId, keyName);
        if (exist) {
            return;
        }

        //add new 
        offlineSyncService.add(defaultSite.id, scheduleRoutineId, url, keyName, param);
    }

    //update home structure when optionType is QRBypassed
    if (param.optionType != "QRBypassed") {
        return;
    }

    var QRBypassed = true;

    // update detail list structure ....
    const offlineService = new OfflineService();
    const currentUser = authenticationService.currentUserValue;

    let detailListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutineItemsDetailForOffline");
    let homeListData = await offlineService.getOfflinesByName(defaultSite.id, "GetScheduleRoutinesByUsername");

    detailListData.OfflineData.data.map(async item => {

        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            item.ScheduleRoutines.QRBypassed = QRBypassed;
        }

    });
    //update indedDB 
    await offlineService.updateOfflineById(detailListData.Id, detailListData.OfflineData);

    // update home list structure ....
    homeListData.OfflineData.data.map((item, index) => {
        if (item.ScheduleRoutines.Id === scheduleRoutineId) {
            item.ScheduleRoutines.QRBypassed = QRBypassed;
        }
    });
    //update indedDB 
    await offlineService.updateOfflineById(homeListData.Id, homeListData.OfflineData);
}

const GetStatusOnOffline = async (offlineService, defaultSite) => {

    let homeListData = await offlineService.getOfflinesByName(defaultSite.id, "GetStatus");
    let status = homeListData.OfflineData.data;

    let inProgress;
    let completed;
    let notStarted;
    status.map(s => {
        if (s.StatusName == "In Progress") {
            inProgress = s;
        } else if (s.StatusName == "Not Started") {
            notStarted = s;
        }
        else {
            completed = s;
        }
    });

    return { inProgress, completed, notStarted, baseStatus: status };
};

export {
    SetRoutineTemplatesForOffline,
    SetHomeScheduleRoutinesForOffline,
    GetOfflineData,
    GetScheduleRoutineDetailById,
    SaveItemToComplete,
    AddToListForOffline,
    UpdateOfflineHomeScheduleRoutineDetailWithOnline,
    SaveNotesForOffline,
    SaveQROptionForOffline
};