import React from "react";
import clsx from 'clsx';
import { Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../../components/admin/Navbars/Navbar";
import Footer from "../../components/admin/Footer/Footer";
import Sidebar from "../../components/admin/Sidebar/Sidebar";
import FixedPlugin from "../../components/admin/FixedPlugin/FixedPlugin";
import routes from "../../../src/routes";
import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle";
import { PrivateAdminRoute } from '../../apiAuthorization/privateAdminRoute';
import bgImage from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/reactlogo.png";
import "../../assets/css/material-dashboard-react.css";
import Items from "../admin/settings/items";
import TeamRoleItemGroups from "../admin/settings/teamRoleItemGroups";
import Users from "../admin/settings/users";
import SystemVariables from "../admin/settings/systemVariables";
import Locations from '../admin/settings/locations';
import Spaces from '../admin/settings/spaces';
import Roles from '../admin/settings/roles';
import Holiday from '../admin/settings/holiday';
import QRCodeDemo from '../admin/temp/qrCodeDemo';
import CleaningRoutineSetup from '../admin/cleaningRoutineAdmin/cleaningRoutineSetup';
import CleaningRoutineAdminPage from '../admin/cleaningRoutineAdmin/cleaningRoutineAdmin';
import ApplicationRoutine from '../admin/cleaningRoutineAdmin/applicationRoutine';
import ScheduleRoutine from '../admin/cleaningRoutineAdmin/scheduleRoutine';
import DailyUsageReport from '../admin/report/dailyUsageReport';

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/Admin") {
                 return (
                    <PrivateAdminRoute
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })
        }
        
        <PrivateAdminRoute path="/Admin/CleaningRoutineSetup" component={CleaningRoutineSetup} />
        <PrivateAdminRoute path="/Admin/CleaningRoutineAdmin" component={CleaningRoutineAdminPage} />
        <PrivateAdminRoute path="/Admin/ApplicationRoutine" component={ApplicationRoutine} />
        <PrivateAdminRoute path="/Admin/ScheduleRoutine" component={ScheduleRoutine} />
        <PrivateAdminRoute path="/Admin/Items" component={Items} />
        <PrivateAdminRoute path="/Admin/TeamRoleItemGroups" component={TeamRoleItemGroups} />
        <PrivateAdminRoute path="/Admin/Users" component={Users} />
        <PrivateAdminRoute path="/Admin/SystemVariables" component={SystemVariables} /> 
        <PrivateAdminRoute path="/Admin/Locations" component={Locations} /> 
        <PrivateAdminRoute path="/Admin/Spaces" component={Spaces} /> 
        <PrivateAdminRoute path="/Admin/Roles" component={Roles} /> 
        <PrivateAdminRoute path="/Admin/Holiday" component={Holiday} /> 
        <PrivateAdminRoute path="/Admin/QRCodeDemo" component={QRCodeDemo} />
        <PrivateAdminRoute path="/Admin/DailyUsageReport" component={DailyUsageReport} />
        <Redirect from="/Admin" to="/Admin/Dashboard" />
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin1({ ...rest }) {
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleDrawerMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleImageClick = img => {
        setImage(img);
    };
    const handleColorClick = colors => {
        setColor(colors);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    
    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoText={"TeamCLEAN"}
                logo={logo}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                subMenuOpen={menuOpen}
                color={color}
                {...rest}
            />
            <div 
                className={clsx(classes.mainPanel, {
                    [classes.mainPanelShift]: menuOpen,
                })}
                ref={mainPanel}>
                <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    handleDrawerMenuToggle={handleDrawerMenuToggle}
                    subMenuOpen={menuOpen}
                    {...rest}
                />
                <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                </div>
                <Footer />
                <FixedPlugin
                    handleImageClick={handleImageClick}
                    handleColorClick={handleColorClick}
                    bgColor={color}
                    bgImage={image}
                    handleFixedClick={handleFixedClick}
                    fixedClasses={fixedClasses}
                />
            </div>
        </div>
    );
}