import * as JsStore from 'jsstore';
import OfflineSchema from '../dbTable/offlineSchema';
import OfflineSyncSchema from '../dbTable/offlineSyncSchema';

export const idbCon = new JsStore.Connection(new Worker('../jsstore/jsstore.worker.js'));

export const dbname = 'TeamClean';

const GetDatabase = () => {
    const dataBase = {
        name: dbname,
        tables: [OfflineSchema, OfflineSyncSchema]
    };
    return dataBase;
};

export const InitJsStore = () => {
    try {
        const dataBase = GetDatabase();
        idbCon.initDb(dataBase);
    }
    catch (ex) {
        console.error(ex);
    }
};