import { BaseModel } from './baseModel';

export class OfflineModel {
    Id;
    SiteId;
    Name;
    OfflineData;

    constructor(Id, SiteId, Name, OfflineData) {
        this.Id = Id === null ? Date.now() : Id;
        this.SiteId = SiteId === null ? -999 : SiteId;
        this.Name = Name === null ? null : Name;
        this.OfflineData = OfflineData === null ? null : OfflineData;
    }
}

export class OfflineExtend extends BaseModel {

    constructor() {
        super(['OfflineData']);  // need encryption column

    }

    fillToProp = (obj, prop) => {
        prop.Id = obj.Id;
        prop.SiteId = obj.SiteId;
        prop.Name = obj.Name;
        prop.OfflineData = obj.OfflineData;
    };
}