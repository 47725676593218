import {
  defaultFont,
  dangerColor,
  whiteColor
} from "../../../../assets/jss/material-dashboard-react.js";

import dropdownStyle from "../../../../assets/jss/material-dashboard-react/dropdownStyle.js";

const headerLinksStyle = theme => ({
  ...dropdownStyle(theme),
  //search: {
  //  "& > div": {
  //    marginTop: "0"
  //  },
  //  [theme.breakpoints.down("sm")]: {
  //    margin: "10px 15px !important",
  //    float: "none !important",
  //    paddingTop: "1px",
  //    paddingBottom: "1px",
  //    padding: "0!important",
  //    width: "60%",
  //    marginTop: "40px",
  //    "& input": {
  //      color: whiteColor
  //    }
  //  }
  //},
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0px"
    },
    nameText: {
        zIndex: "4",
        ...defaultFont,
        fontSize: "14px",
        marginLeft: "20px"
    },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "10px 15px 0",
      width: "-webkit-fill-available",
      "& svg": {
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "24px",
        lineHeight: "30px",
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px"
      },
      "& > span": {
        justifyContent: "flex-start",
        width: "100%"
      }
      },
       whiteSpace: 'normal'
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      top: "-50px !important",
      marginRight: "22px",
      float: "right"
    }
  },
  margin: {
    zIndex: "4",
    margin: "0"
  },
  //searchIcon: {
  //  width: "17px",
  //  zIndex: "4"
  //},
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "2px",
      border: "1px solid " + whiteColor,
      right: "4px",
      fontSize: "9px",
      background: dangerColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block"
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    display: "inline-block"
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0"
    },
    display: "inline-block"
    },
  search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        //backgroundColor: fade(theme.palette.common.white, 0.15),
        //'&:hover': {
        //    backgroundColor: fade(theme.palette.common.white, 0.25),
        //},
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        display: 'block'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        // transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            '&:focus': {
                width: 200,
            },
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: '0.7rem'
    },
});

export default headerLinksStyle;
