/* This page is used to login to the application */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { authenticationService } from '../../apiAuthorization/authenticationService';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    inputWidth: {
        width: '100%',
        maxWidth: '300px'
    },
    imgLogo: {
        maxWidth: '300px',
        marginBottom: '-100px',
        marginTop: '-85px'
    },
    error: {
        color: 'red'
    },
    hide: {
        display: 'none'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    styleButtonProgress: {
        background: 'linear-gradient(45deg, #eaf0f3 30%, #c7c8ca 90%) !important'
    }
}
));

const StyledButton = withStyles({
    root: {
        background: 'linear-gradient(45deg, #3399cc 30%, #3399cc 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        maxWidth: '300px',
        width: '100%'
    },
    label: {
        textTransform: 'capitalize'
    }
})(Button);

export default function Login(props) {
    const classes = useStyles();
    useEffect(() => {
        sessionStorage.setItem('currentUser', null);
    }, []);

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false
    });
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const buttonClassname = clsx({
        [classes.styleButtonProgress]: loading
    });

    const onSubmit = event => {
        event.preventDefault();
        if (values.username === '' || values.password === '') {
            return;
        }

        setLoading(true);
        authenticationService.login(values.username, values.password)
            .then(
                user => {
                    setLoading(false);
                    const { Url } = user || {};
                    const { to } = props.location.state || { to: { pathname: '/' + Url } };
                    props.history.push(to);
                    checkUserIsActive();
                },
                loginError => {
                    setLoading(false);
                    setError(true);
                    setErrorMessage(loginError);
                }
            );
    };


    var expiration = null;

    const expirationFunc = () => {
        expiration = setInterval(() => {
            sessionStorage.removeItem("checkExpirationLoaded");
            document.removeEventListener('click', expirationReset);
            authenticationService.logout();
            clearInterval(expiration);
        }, 30 * 60 * 1000);
    };

    const expirationReset = () => {
        clearInterval(expiration);
        expirationFunc();
    };

    const checkUserIsActive = () => {
        var isCheckExpirationLoaded = sessionStorage.getItem("checkExpirationLoaded");
        if (isCheckExpirationLoaded !== '1') {
            sessionStorage.setItem("checkExpirationLoaded", '1');
            expirationFunc();
            document.addEventListener('click', expirationReset);
        }
    };

    let showPasswordButton;
    if (values.showPassword) {
        showPasswordButton = <Visibility />;
    }
    else {
        showPasswordButton = <VisibilityOff />;
    }

    return (
        <div id="login">
            <Container maxWidth="xs" className="login-body">
                <div className="login-header">
                    <img className={classes.imgLogo} alt="logo" src="/static/images/TeamClean-color-rgb.svg" />
                </div>
                <div>
                    <form onSubmit={onSubmit} autoComplete="off">
                        <FormControl className={`${classes.margin} ${classes.inputWidth}`}>
                            <InputLabel htmlFor="input-with-icon-adornment">Username</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                value={values.username}
                                onChange={handleChange('username')}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <br />
                        <FormControl className={`${classes.margin} ${classes.inputWidth}`}>
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={(() => {
                                    if (values.showPassword) { return 'text'; }
                                    else { return 'password'; }
                                })()}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPasswordButton}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText className={`${classes.error} ${error === false ? classes.hide : ''}`}>{errorMessage}</FormHelperText>
                        </FormControl>
                        <div className={` ${classes.wrapper} m-t-10`} style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <StyledButton variant="contained" type="submit" disabled={loading} className={buttonClassname} >Sign in</StyledButton>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
            </Container>
        </div>
    );
}
