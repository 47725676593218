/* Code for Cleaning Routine Admin Tab */
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Button from "../CustomButtons/Button.js";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import { TextField, Select, InputLabel, FormLabel, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText } from '@material-ui/core/';
import RoutineItem from '../cleaningRoutineAdmin/routineItem';
import RoutineTemplate from '../cleaningRoutineAdmin/routineTemplate';
import CustomDialogs from '../../modals/customModal';
import MaterialTable from 'material-table';
import Code from "@material-ui/icons/Code";
import Tabs from "../CustomTabs/CustomTabs";
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import AlertDialog from '../../common/alertDialog';
import { GetTime, GetDate } from '../../../components/contexts/timeFormatter';

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const useStyles = makeStyles(theme => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    inputControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function CleaningRoutineAdmin() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [scheduleLoading, setScheduleLoading] = useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [routineVerification, setRoutineVerification] = useState({
        Location: false,
        Space: false,
        RepeatHour: false,
        RepeatMinutes: false,
        RepeatDay: false,
        RoutineTemplateName: false,
        StartTime: false,
        EndTime: false,
        StartMinute: false
    });

    const routineDtoBase = {
        LocationId: "",
        SpaceId: "",
        RepeatHour: 1,
        RepeatMinutes: 0,
        RepeatDay: [],
        RoutineTemplateName: "",
        StartTime: "",
        EndTime: "",
        DefaultDate: new Date(),
        IsActive: true,
        RoutineItems: []
        //StartDate: new Date()
    };

    const routineTemplateDtoBase = {
        TCRoutinesTemplates: [],
        Offset: Number(defaultSite.offset),
        SiteId: Number(defaultSite.id),
        EffectiveDate: new Date(),
        EffectiveTime: "",
        Action: ""
    };

    const [repeatValue, setRepeatValue] = React.useState('Hour');
    const [routineDto, setRoutineDto] = useState(routineDtoBase)
    const [routineItem, setRoutineItem] = useState({
        columns: [
            { title: 'Item Name', field: 'ItemName' },
            { title: 'Item Description', field: 'Description' },
            {
                title: 'Time To Complete (min)', field: 'TimeToComplete', type: 'numeric', align: 'left'
                , validate: rowData => rowData.TimeToComplete > 999 ? { isValid: false, helperText: `Time To Complete cannot be greater than 999` } : true
            },
            {
                title: 'Start Minute (min)', field: 'StartMinute',
                editComponent: props => (
                    <TextField
                        type="number"
                        value={props.value}
                        onChange={e => startMinuteOnChange(e, props)}
                    />
                )
            },
        ],
    });

    const [saving, setSaving] = useState(false);
    const [routineView, setRoutineView] = useState({});
    const [routineTemplateView, setRoutineTemplateView] = useState([]);
    const [scheduleRoutineView, setScheduleRoutineView] = useState([]);
    const [clearRoutineTemplateSelected, setClearRoutineTemplateSelected] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalRoutineTemplate, setModalRoutineTemplate] = useState(false);
    const [routineTemplateDto, setRoutineTemplateDto] = useState(routineTemplateDtoBase);

    const alertBox = (msg, severityType) => {
        setErrorMessage(msg);
        setSeverity(severityType);
        setAlertOpen(true);
    }

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const startMinuteOnChange = (e, props) => {
        if (e.target.value < 0 || e.target.value > 59) {
            alertBox(`Please enter the minutes between 0 and 59`, 'error');
        } else {
            props.onChange(Number(e.target.value));
        }
    }

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
    };

    const handleDateChange = event => {

        setRoutineDto({
            ...routineDto,
            "DefaultDate": event,
        });
    };

    const handleModalRoutineTemplateOpen = (action) => {
        console.log(action)
        setRoutineTemplateDto({ ...routineTemplateDto, "EffectiveDate": GetDate(), "EffectiveTime": GetTime(), "Action": action });
        console.log(routineTemplateDto)
        setModalRoutineTemplate(true);
    };

    const handleModalRoutineTemplateClose = () => {
        setModalRoutineTemplate(false);
    };

    //const handleFilterDateChange = date => {
    //    setRoutineDto({
    //        ...routineDto,
    //        "StartDate": date,
    //    });

    //    if (date.toString() !== 'Invalid Date') {
    //        getScheduleRoutines(date);
    //    }

    //<GridItem xs={12} sm={6} md={2}>
    //    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //        <KeyboardDatePicker
    //            autoOk
    //            disableToolbar
    //            variant="inline"
    //            inputVariant="outlined"
    //            format="MM/dd/yyyy"
    //            label="Scheduled Date"
    //            value={routineDto.StartDate}
    //            onChange={handleFilterDateChange}
    //            KeyboardButtonProps={{
    //                'aria-label': 'change date',
    //            }}
    //        />
    //    </MuiPickersUtilsProvider>
    //</GridItem>
    //};

    const handleRoutineDtoChange = event => {
        const name = event.target.name;
        if (name === "LocationId") {
            routineDto["SpaceId"] = "";
        } else if (name === "IsActive") {
            setRoutineDto({
                ...routineDto,
                [name]: event.target.checked,
            });
            return;
        }
        setRoutineDto({
            ...routineDto,
            [name]: event.target.value,
        });

        if (name === "RepeatHour" || name === "RepeatMinutes") {
            const data = [...routineItem.columns];
            const limit = name === "RepeatHour" ? (event.target.value * 60) - 1 : (event.target.value) - 1;
            data[3].editComponent = props => {
                return (
                    <TextField
                        type="number"
                        value={props.value}
                        onChange={e => {
                            const input = e.target.value;
                            if ((input < 0 || input > limit) && limit !== -1) {
                                if (props.value > input) {
                                    props.onChange(Number(input));
                                } else {
                                    props.onChange(limit === input - 1 ? Number(input - 1) : Number(limit));
                                    alertBox(`Please enter the minutes between 0 and ${limit}`, 'error');
                                }
                            }
                            else {
                                props.onChange(Number(input));
                            }
                        }}
                    />)
            }
            setRoutineItem({ ...routineItem, columns: data });
        }
    };

    const handleRoutineItemsChange = items => {
        const newItem = [...routineDto.RoutineItems];
        routineView.TCItems.map(item => {
            if (items.indexOf(item.Id) !== -1) {
                const selectedItem = { ...item, StartMinute: 0 };
                selectedItem.Id = `${selectedItem.Id}-${new Date().getTime()}`;
                newItem.push(selectedItem);
            }
            return null;
        });
        setRoutineDto({
            ...routineDto,
            "RoutineItems": newItem,
        });
    };

    const handleRoutineTemplateSelected = items => {
        setClearRoutineTemplateSelected(false);
        const dto = [];
        routineTemplateView.map(item => {
            if (items.indexOf(item.RoutinesTemplate.Id) !== -1) {
                dto.push(item.RoutinesTemplate);
            }
            return null;
        });
        setRoutineTemplateDto({ ...routineTemplateDto, "TCRoutinesTemplates": dto });
    };

    //----------------------   ------------------------ ----------------------------


    //------------------ Data request function  ---------------------------------------

    const getTemplateOptions = () => {
        HttpService.post("/CleaningRoutineAdmin/GetTemplateOptions", { SiteId: defaultSite.id })
            .then(
                data => {
                    //console.log(data);
                    setRoutineView(data.data);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    const getRoutineTemplates = () => {
        HttpService.post("/CleaningRoutineAdmin/GetRoutineTemplates", { SiteId: defaultSite.id })
            .then(
                data => {
                    //console.log(data);
                    setRoutineTemplateView(data.data);
                    setLoading(false);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    const getScheduleRoutines = () => {
        setScheduleLoading(true);
        HttpService.post("/CleaningRoutineAdmin/GetScheduleRoutinesView", { SiteId: defaultSite.id })
            .then(
                data => {
                    //console.log(data);
                    setScheduleRoutineView(data.data);
                    setScheduleLoading(false);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    const verificationDto = () => {

        let verification = true;

        if (routineDto.LocationId <= 0) {
            routineVerification.Location = true;
            verification = false;
        } else {
            routineVerification.Location = false;
        }

        if (routineDto.SpaceId <= 0) {
            routineVerification.Space = true;
            verification = false;
        } else {
            routineVerification.Space = false;
        }

        if (!routineDto.RepeatHour) {
            routineVerification.RepeatHour = true;
            verification = false;
        } else {
            routineVerification.RepeatHour = false;
        }

        if (repeatValue === "Minutes" && routineDto.RepeatMinutes === 0) {
            routineVerification.RepeatMinutes = true;
            verification = false;
        } else {
            routineVerification.RepeatMinutes = false;
        }

        if (routineDto.RepeatDay.length === 0) {
            routineVerification.RepeatDay = true;
            verification = false;
        } else {
            routineVerification.RepeatDay = false;
        }

        if (routineDto.RoutineTemplateName.trim().length === 0) {
            routineVerification.RoutineTemplateName = true;
            verification = false;
        } else {
            routineVerification.RoutineTemplateName = false;
        }

        if (!routineDto.StartTime) {
            routineVerification.StartTime = true;
            verification = false;
        } else {
            routineVerification.StartTime = false;
        }

        if (!routineDto.EndTime) {
            routineVerification.EndTime = true;
            verification = false;
        } else {
            routineVerification.EndTime = false;
        }

        if (routineDto.StartTime && routineDto.EndTime) {
            if (routineDto.StartTime > routineDto.EndTime) {
                routineVerification.StartTime = true;
                routineVerification.EndTime = true;
                verification = false;
            } else {
                routineVerification.StartTime = false;
                routineVerification.EndTime = false;
            }
        }

        if (routineDto.RoutineItems.length === 0) {
            routineVerification.RoutineItems = true;
            verification = false;
            alertBox('Routine items cannot be empty.', 'error');
        } else {
            routineVerification.RoutineItems = false;
        }

        const limit = repeatValue === "Hour" ? (routineDto.RepeatHour * 60) - 1 : routineDto.RepeatMinutes - 1;
        let count = 0
        routineDto.RoutineItems.map(x => {
            count++;
            if (x.StartMinute > limit && limit !== -1) {
                routineVerification.StartMinute = true;
                verification = false;
                alertBox(`Please enter the minutes between 0 and ${limit} on Routine item ${x.ItemName}`, 'error');
                routineDto.RoutineItems[count - 1].StartMinute = limit;
            } else {
                routineVerification.StartMinute = false;
            }
            return null;
        });

        setRoutineVerification({ ...routineVerification });
        return verification;
    };

    const saveRoutineTemplate = () => {

        if (!verificationDto() || saving) { return; }
        setSaving(true);
        HttpService.post("/CleaningRoutineAdmin/SaveRoutineTemplate", routineDto)
            .then(
                data => {
                    //console.log(data);
                    setSaving(false);
                    setRoutineDto(routineDtoBase);
                    getRoutineTemplates();
                    alertBox('Routine Template created successfully!', 'success');
                },
                error => {
                    //  console.log(error);
                }
            );

    };

    const generateScheduleRoutineTemplate = () => {

        if (routineTemplateDto.TCRoutinesTemplates.length <= 0) {
            alertBox('Please select at least one template!', 'error');
            return;
        }

        handleModalRoutineTemplateOpen('generate');

    };

    const deactivateScheduleRoutineTemplate = () => {

        if (routineTemplateDto.TCRoutinesTemplates.length <= 0) {
            alertBox('Please select at least one template!', 'error');
            return;
        }

        handleModalRoutineTemplateOpen('deactivate');

    };

    const generateOrDeactivateScheduleRoutineTemplate = () => {

        if (!routineTemplateDto.EffectiveDate || !routineTemplateDto.EffectiveTime) {
            alertBox('Please select a Effective Date!', 'error');
            return;
        }

        HttpService.post("/CleaningRoutineAdmin/GenerateOrDeactivateScheduleRoutineTemplate", routineTemplateDto)
            .then(
                data => {
                    //console.log(data);
                    alertBox('Schedule Routine ' + routineTemplateDto.Action + ' successfully!', 'success');
                    setRoutineTemplateDto(routineTemplateDtoBase);
                    setClearRoutineTemplateSelected(true);
                    getRoutineTemplates();
                    getScheduleRoutines();
                    handleModalRoutineTemplateClose();
                },
                error => {
                    //  console.log(error);
                    handleModalRoutineTemplateClose();
                }
            );
    };

    const handleRoutineTemplateDtoChange = event => {
        const name = event.target.name;
        setRoutineTemplateDto({
            ...routineTemplateDto,
            [name]: event.target.value,
        });
    };

    const handleEffectiveDateChange = (date) => {
        setRoutineTemplateDto({
            ...routineTemplateDto,
            "EffectiveDate": date,
        });
    };

    const handleModalRoutineTemplateSelected = () => {
        generateOrDeactivateScheduleRoutineTemplate();
    };

    const handleRadioChange = event => {
        setRepeatValue(event.target.value);
        if (event.target.value === "Hour") {
            setRoutineDto({ ...routineDto, "RepeatHour": 1 });
            const data = [...routineItem.columns];
            data[2].editComponent = props => {
                return (
                    <TextField
                        type="number"
                        value={props.value}
                        onChange={e => startMinuteOnChange(e, props)}
                    />)
            }
            setRoutineItem({ ...routineItem, columns: data });
        } else {
            setRoutineDto({ ...routineDto, "RepeatMinutes": 0 });
        }
    }

    const saveShowQRCode = item => {
        setLoading(true);
        item.ShowQRCode = !item.ShowQRCode;
        HttpService.post("/CleaningRoutineAdmin/saveShowQRCode", { Id: item.Id})
            .then(
                data => {
                    //console.log(data);
                    setLoading(false);
                    if (data.error) {
                        alertBox(data.message, 'error');
                        return;
                    }
                    getRoutineTemplates();
                },
                error => {
                    //  console.log(error);
                    setLoading(false);
                }
            );
    };

    //--------------------- Data request function  ------------------------------------

    useEffect(() => {
        if (defaultSite) {
            getTemplateOptions();
            getRoutineTemplates();
            getScheduleRoutines();
            // eslint-disable-next-line
            setRoutineDto(routineDtoBase);
        }
        // eslint-disable-next-line
    }, [defaultSite]);

    return (
        <div>

            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />

            {/*  Show  Application Routine Items   */}

            <CustomDialogs open={modal} close={handleModalClose} title="Add Routine Items" >
                <RoutineItem data={routineView.TCItems} groupData={routineView.TCItemGroups} closeModal={handleModalClose} handleRoutineItemsChange={handleRoutineItemsChange} />
            </CustomDialogs>

            <CustomDialogs autoWidth={true} open={modalRoutineTemplate} close={handleModalRoutineTemplateClose} customButton="Select" onCustomButton={handleModalRoutineTemplateSelected} title="SELECT DATE & TIME" >
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                disablePast
                                variant="inline"
                                format="MM/dd/yyyy"
                                className={classes.inputControl}
                                label="Effective Date"
                                value={routineTemplateDto.EffectiveDate}
                                onChange={handleEffectiveDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            id="effective-time"
                            label="Effective Time"
                            type="time"
                            name="EffectiveTime"
                            format="hh:mm a"
                            value={routineTemplateDto.EffectiveTime}
                            onChange={handleRoutineTemplateDtoChange}
                            className={classes.inputControl}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </CustomDialogs>

            <Tabs
                title="Routines:"
                headerColor="primary"
                tabs={[
                    {
                        tabName: "Cleaning Routine Setup",
                        tabIcon: Code,
                        tabContent: (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: '0px' }}>
                                        <CardBody style={{ paddingTop: '0px' }}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="location-label" error={routineVerification.Location} >Location</InputLabel>
                                                        <Select
                                                            error={routineVerification.Location}
                                                            labelId="location-select-label"
                                                            id="location-select"
                                                            name="LocationId"
                                                            value={routineDto.LocationId}
                                                            onChange={handleRoutineDtoChange}
                                                        >
                                                            {
                                                                routineView.TCLocations ?.map(location =>
                                                                    (<MenuItem key={location.Id} value={location.Id}>{location.LocationName}</MenuItem>))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="spaces-label" error={routineVerification.Space}  >Spaces</InputLabel>
                                                        <Select error={routineVerification.Space}
                                                            labelId="spaces-select-label"
                                                            id="spaces-select"
                                                            name="SpaceId"
                                                            value={routineDto.SpaceId}
                                                            onChange={handleRoutineDtoChange}
                                                        >
                                                            {
                                                                routineView.TCSpaces ?.map(space => {
                                                                    return (
                                                                        routineDto.LocationId === space.LocationId ?
                                                                            <MenuItem key={space.Id} value={space.Id}>{space.SpaceName}</MenuItem> : null
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <TextField className={classes.inputControl}
                                                        id="Routine-Template-Name"
                                                        margin="normal"
                                                        error={routineVerification.RoutineTemplateName}
                                                        value={routineDto.RoutineTemplateName}
                                                        onChange={handleRoutineDtoChange}
                                                        name="RoutineTemplateName"
                                                        label="Routine Template Name" />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={2}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            disablePast
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            className={classes.inputControl}
                                                            label="Default Date"
                                                            value={routineDto.DefaultDate}
                                                            onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={2}>
                                                    <TextField
                                                        id="Start-Time"
                                                        error={routineVerification.StartTime}
                                                        label="Start Time"
                                                        type="time"
                                                        name="StartTime"
                                                        format="hh:mm a"
                                                        value={routineDto.StartTime}
                                                        onChange={handleRoutineDtoChange}
                                                        className={classes.inputControl}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={2}>
                                                    <TextField
                                                        id="End-Time"
                                                        error={routineVerification.EndTime}
                                                        label="End Time"
                                                        type="time"
                                                        name="EndTime"
                                                        value={routineDto.EndTime}
                                                        onChange={handleRoutineDtoChange}
                                                        className={classes.inputControl}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={3}>
                                                    <FormControl style={{ display: 'flex', marginLeft: '10px', marginTop: '15px' }} component="fieldset" className={classes.formControl}>
                                                        <RadioGroup aria-label="Repeat" name="Repeat" value={repeatValue} onChange={handleRadioChange} row>
                                                            <FormLabel component="legend" style={{ width: '25%', marginTop: '12px' }}>Repeat</FormLabel>
                                                            <FormControlLabel value="Hour" control={<Radio />} label="Hour" labelPlacement="end" />
                                                            <FormControlLabel value="Minutes" control={<Radio />} label="Minutes" labelPlacement="end" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </GridItem>
                                                {repeatValue === "Hour" && <GridItem xs={12} sm={6} md={3}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="repeat-hour-label" error={routineVerification.RepeatHour} >Repeat Hour</InputLabel>
                                                        <Select
                                                            error={routineVerification.RepeatHour}
                                                            labelId="repeat-hour-select-label"
                                                            id="repeat-hour-select"
                                                            name="RepeatHour"
                                                            value={routineDto.RepeatHour}
                                                            onChange={handleRoutineDtoChange}
                                                        >
                                                            <MenuItem value={0}>0</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={2}>2</MenuItem>
                                                            <MenuItem value={3}>3</MenuItem>
                                                            <MenuItem value={4}>4</MenuItem>
                                                            <MenuItem value={5}>5</MenuItem>
                                                            <MenuItem value={6}>6</MenuItem>
                                                            <MenuItem value={7}>7</MenuItem>
                                                            <MenuItem value={8}>8</MenuItem>
                                                            <MenuItem value={9}>9</MenuItem>
                                                            <MenuItem value={10}>10</MenuItem>
                                                            <MenuItem value={11}>11</MenuItem>
                                                            <MenuItem value={12}>12</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                }
                                                {repeatValue === "Minutes" && <GridItem xs={12} sm={6} md={3}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="repeat-hour-label" error={routineVerification.RepeatMinutes} >Repeat Minutes</InputLabel>
                                                        <Select
                                                            error={routineVerification.RepeatMinutes}
                                                            labelId="repeat-hour-select-label"
                                                            id="repeat-minutes-select"
                                                            name="RepeatMinutes"
                                                            value={routineDto.RepeatMinutes}
                                                            onChange={handleRoutineDtoChange}
                                                        >
                                                            <MenuItem value={0}>Select...</MenuItem>
                                                            <MenuItem value={5}>5</MenuItem>
                                                            <MenuItem value={10}>10</MenuItem>
                                                            <MenuItem value={15}>15</MenuItem>
                                                            <MenuItem value={20}>20</MenuItem>
                                                            <MenuItem value={25}>25</MenuItem>
                                                            <MenuItem value={30}>30</MenuItem>
                                                            <MenuItem value={35}>35</MenuItem>
                                                            <MenuItem value={40}>40</MenuItem>
                                                            <MenuItem value={45}>45</MenuItem>
                                                            <MenuItem value={50}>50</MenuItem>
                                                            <MenuItem value={55}>55</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                }
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="repeat-day-label" error={routineVerification.RepeatDay} >Repeat Day</InputLabel>
                                                        <Select
                                                            error={routineVerification.RepeatDay}
                                                            labelId="repeat-day-select-label"
                                                            id="repeat-day-select"
                                                            multiple
                                                            name="RepeatDay"
                                                            value={routineDto.RepeatDay}
                                                            onChange={handleRoutineDtoChange}
                                                            renderValue={selected => selected.join(',')}
                                                        >
                                                            {days.map(day => (
                                                                <MenuItem key={day} value={day}>
                                                                    <Checkbox checked={routineDto.RepeatDay.indexOf(day) > -1} />
                                                                    <ListItemText primary={day} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={2} md={2}>
                                                    <FormControl className={classes.formControl}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={routineDto.IsActive}
                                                                    name="IsActive"
                                                                    onChange={handleRoutineDtoChange}
                                                                    color="secondary"
                                                                />
                                                            }
                                                            label="Active"
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} sm={10} md={10}>
                                                    <Button variant="contained" color="primary" round onClick={handleModalOpen} style={{ float: 'right' }}>
                                                        Add Items
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h4 className={classes.cardTitle} style={{ textAlign: 'center', fontWeight: 'bold' }}>Routine Items</h4>
                                                    <MaterialTable
                                                        options={{
                                                            search: false,
                                                            actionsColumnIndex: -1,
                                                            toolbar: false,
                                                            headerStyle: {
                                                                fontWeight: 'bold',
                                                                marginTop: '-10px'
                                                            }
                                                        }}
                                                        title=""
                                                        columns={routineItem.columns}
                                                        data={routineDto.RoutineItems}
                                                        editable={{
                                                            onRowUpdate: (newData, oldData) =>
                                                                new Promise((resolve, reject) => {
                                                                    setTimeout(() => {
                                                                        if (oldData) {
                                                                            const limit = repeatValue === "Hour" ? (routineDto.RepeatHour * 60) - 1 : routineDto.RepeatMinutes - 1;
                                                                            if (newData.StartMinute > limit && limit !== -1) {
                                                                                newData.StartMinute = limit;
                                                                                alertBox(`Please enter the minutes between 0 and ${limit}`, 'error');
                                                                                reject();
                                                                            } else {
                                                                                resolve();
                                                                                setRoutineDto(prevState => {
                                                                                    const RoutineItems = [...prevState.RoutineItems];
                                                                                    RoutineItems[RoutineItems.indexOf(oldData)] = newData;
                                                                                    return { ...prevState, RoutineItems };
                                                                                });
                                                                            }
                                                                        }
                                                                    }, 500)
                                                                }),

                                                            onRowDelete: oldData =>
                                                                new Promise(resolve => {
                                                                    setTimeout(() => {
                                                                        resolve();
                                                                        setRoutineDto(prevState => {
                                                                            const RoutineItems = [...prevState.RoutineItems];
                                                                            RoutineItems.splice(RoutineItems.indexOf(oldData), 1);
                                                                            return { ...prevState, RoutineItems };
                                                                        });
                                                                    }, 500)
                                                                }),
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <Button style={{ float: 'right', marginTop: '20px' }} round color="primary" onClick={saveRoutineTemplate}>Create Routine</Button>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )
                    },
                    {
                        tabName: "APPLICATION ROUTINES",
                        tabIcon: Code,
                        tabContent: (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: '0px' }}>
                                        <CardBody style={{ paddingTop: '0px' }}>
                                            <RoutineTemplate tableType="template" loading={loading} data={routineTemplateView} handleRoutineTemplateSelected={handleRoutineTemplateSelected} clearSelected={clearRoutineTemplateSelected} saveShowQRCode={saveShowQRCode}/>
                                            <Button color="primary" round onClick={generateScheduleRoutineTemplate} style={{ float: 'right' }}>
                                                Generate
                                            </Button>
                                            <Button color="danger" round onClick={deactivateScheduleRoutineTemplate} style={{ float: 'right' }}>
                                                Deactivate
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )
                    },
                    {
                        tabName: "Schedule Routines",
                        tabIcon: Code,
                        tabContent: (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: '0px' }}>
                                        <CardBody style={{ paddingTop: '0px', marginTop: '0px' }}>
                                            <RoutineTemplate loading={scheduleLoading} tableType="schedule" data={scheduleRoutineView} />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )
                    },
                ]}
            />
        </div>
    );
}
