import React, { useState, useEffect } from "react";
import { HttpService } from "../../apiAuthorization/httpService";
import { authenticationService } from '../../apiAuthorization/authenticationService';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 9999,
        color: '#fff'
    }
}));

export const Context = React.createContext(null);

export const SiteContext = props => {
    const classes = useStyles();
    
    let storedSites = JSON.parse(sessionStorage.getItem("sites"));
    let storedDefalutSite = sessionStorage.getItem("defaultSite") !== 'undefined' ?
        JSON.parse(sessionStorage.getItem("defaultSite")) : [];
   
    const [sites, setSites] = useState(storedSites);
    const [defaultSite, setDefaultSite] = useState(storedDefalutSite == null ? [] : storedDefalutSite);
    const [open, setOpen] = useState(false);
    const [siteLoading, setSiteLoading] = useState([]);
    const [siteInit, setSiteInit] = useState(false);
    const [refreshHome, setRefreshHome] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(props.location);

    const contextValue = {
        sites, setSites,
        defaultSite, setDefaultSite,
        siteLoading,
        siteInit,
        setOpen,
        refreshHome, setRefreshHome,
        currentLocation, setCurrentLocation
    };

    const getSiteByUserName = () => {
        let username;
        if (!authenticationService.currentUserValue) {
            return;
        } else {
            username = authenticationService.currentUserValue.Username;
        }

        setOpen(true);
        setSiteLoading(true);
        setSiteInit(false);
        HttpService.post("/Home/GetSitesByUsername", { username })
            .then(result => {

                setOpen(false);
                setSiteLoading(false);
                setSiteInit(true);

                if (!result || result.length === 0) return;

                setSites(result);
                setDefaultSite(result[0]);
                sessionStorage.setItem("sites", JSON.stringify(result));
                sessionStorage.setItem("defaultSite", JSON.stringify(result[0]));
               
            },
                error => {
                    //console.log(error);
                    setOpen(false);
                    setSiteLoading(false);
                    setSiteInit(true);
                }
            )
    }


    useEffect(() => {
            getSiteByUserName();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentLocation(props.location);
        // eslint-disable-next-line
    }, [props.location.pathname]);

    return (
        <>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Context.Provider value={contextValue}>{props.children}</Context.Provider>
        </>
    );
};