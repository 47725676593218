import React from 'react';
import Layout from '../layout/layout';
import AreaList from './areaList';

export default function AreaDetail(props) {

    return (
        <Layout>
            <AreaList id={props.match.params.id} colorId={props.match.params.colorId} history={props.history} />
        </Layout>
    );
}