import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import AreaCard from '../area/areaCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { AreaModelList } from '../../models/areaModelList';
import { HttpService } from '../../apiAuthorization/httpService';
import { Context } from "../contexts/siteContext";
import { GetOfflineData, AddToListForOffline } from "../../offline/dataService/homeOfflineData";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AreaAppendModal(props) {
    const { open, close } = props;
    const { defaultSite } = useContext(Context);
    const [adhocRoutines, setAdhocRoutines] = React.useState([]);
    const maxWidth = 'sm';

    useEffect(() => {
        close();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (open) {            
            getAdhocRoutines();
        }
      // eslint-disable-next-line
    }, [open]);

    const getAdhocRoutines = () => {
        setAdhocRoutines([]);
        HttpService.post("/Home/GetRoutineTemplates", { SiteId: defaultSite.id})
            .then(async (result) => {

                if (result && result.offline) {
                    //get offline data 
                    const offlineResult = await GetOfflineData(defaultSite, "GetRoutineTemplatesForOffline");
                    result = offlineResult.OfflineData;
                    //console.log('offline',result);
                }
                else if (result && result.error) {
                    return;
                }

                const areas = new AreaModelList();
                setAdhocRoutines(areas.adhoc(result.data, "AddToList"));

                },
                error => {
                   // console.log(error);
                }
            );
    };

    const addToList = id => {

        HttpService.post("/Home/AddToList", { Id: id,Offset: Number(defaultSite.offset) })
            .then(async (result) => {

                    if (result && result.offline) {
                        //get offline data 
                        const detailId = await AddToListForOffline(defaultSite, id);
                        props.history.push({ pathname: `/AreaDetail/${detailId}/2` }); 
                        return;
                    }
                    else if (result && result.error) {
                        return;
                    }

                    props.history.push({ pathname: `/AreaDetail/${result.data.Id}/2` }); 
                },
                error => {
                    //console.log(error);
                }
            );
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Add more"}</DialogTitle>
                <DialogContent>
                    <List >
                        {adhocRoutines.map(item => (
                            <React.Fragment key={item.id}>
                                    <ListItem button>
                                    <AreaCard data={item} onAddToList={addToList} />
                                    </ListItem>
                            </React.Fragment>

                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={close}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
