import React, { useContext, useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, InputBase, MenuList, Grow, Paper, ClickAwayListener, Hidden, Divider, Avatar } from "@material-ui/core";
import Poppers from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';

// core components
import Button from "../CustomButtons/Button.js";

import styles from "../../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { authenticationService } from '../../../apiAuthorization/authenticationService';
import { Context } from "../../contexts/siteContext";
import { useEffect } from "react";

function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
    const classes = useStyles();
    const [openNotification, setOpenNotification] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(null);
    const { sites, defaultSite, setDefaultSite } = useContext(Context);
    const [siteList, setSiteList] = useState([]);
    //console.log(siteList);
    const handleClickNotification = event => {
        if (openNotification && openNotification.contains(event.target)) {
            setOpenNotification(null);
        } else {
            setOpenNotification(event.currentTarget);
        }
    };
    const handleCloseNotification = () => {
        setOpenNotification(null);
    };
    const handleClickProfile = event => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = () => {
        setOpenProfile(null);
    };

    const logout = () => {
        setOpenProfile(null);
        authenticationService.logout();
    }

    const searchHandler = (event) => {
        let searcjQery = event.target.value.toLowerCase(),
            list = sites.filter((el) => {
                let searchValue = el.name.toLowerCase();
                if (searcjQery.length > 0) {
                    return searchValue.indexOf(searcjQery) !== -1;
                }
                else {
                    return searchValue;
                }
            })
        setSiteList(list);
    }
    
    useEffect(() => {
        setSiteList(sites);
        // eslint-disable-next-line
    }, [sites])

    return (
        <div>
            <div className={classes.manager}>

                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openNotification ? "notification-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickNotification}
                    className={classes.buttonLink}
                >
                    <LocationCityOutlinedIcon className={classes.icons} />


                    <Hidden mdUp implementation="css">
                        <div onClick={handleCloseNotification} className={classes.linkText}>
                            {defaultSite.name}
                        </div>
                    </Hidden>
                </Button>

                <Poppers
                    open={Boolean(openNotification)}
                    anchorEl={openNotification}
                    transition
                    disablePortal
                    className={
                        classNames({ [classes.popperClose]: !openNotification }) +
                        " " +
                        classes.popperNav
                    }
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="notification-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseNotification}>
                                    <div>
                                        <div className={classes.search} style={{ color: window.innerWidth > 959 ? "inherit" : "white" }}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                placeholder="Search"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={searchHandler}
                                            />
                                            <Divider />
                                        </div>
                                        <MenuList role="menu">
                                            { siteList?.map((site) => (
                                                <MenuItem
                                                    onClick={() => { setDefaultSite(site); handleCloseNotification() }}
                                                    className={classes.dropdownItem}
                                                    key={site.id}
                                                >
                                                    {site.name}
                                                </MenuItem>
                                            ))} 
                                        </MenuList>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
            <div className={classes.manager}>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                >
                    {(authenticationService.currentUserValue.HeadImage !== "" && authenticationService.currentUserValue.HeadImage !== null) ?
                        <Avatar src={authenticationService.currentUserValue.HeadImage} alt='img' className={classes.small} /> :
                        <Avatar className={classes.small} alt='img' style={{ backgroundColor: stringToHslColor(authenticationService.currentUserValue.Abbreviation, 50, 30) }}>
                            {authenticationService.currentUserValue.Abbreviation}
                        </Avatar>
                    }
                    <Hidden mdUp implementation="css">
                        <p className={classes.nameText}>{authenticationService.currentUserValue.DisplayName}</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    className={
                        classNames({ [classes.popperClose]: !openProfile }) +
                        " " +
                        classes.popperNav
                    }
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        <MenuItem style={{display:'flex'}}
                                            onClick={handleCloseProfile}
                                            className={classes.dropdownItem}>
                                            {(authenticationService.currentUserValue.HeadImage !== "" && authenticationService.currentUserValue.HeadImage !== null)?
                                                <Avatar src={authenticationService.currentUserValue.HeadImage} alt='img' className={classes.small} /> :
                                                <Avatar className={classes.small} alt='img' style={{ backgroundColor: stringToHslColor(authenticationService.currentUserValue.Abbreviation, 50, 30) }}>
                                                    {authenticationService.currentUserValue.Abbreviation}
                                                </Avatar>
                                            }
                                            <span style={{marginLeft:'10px'}}>{authenticationService.currentUserValue.DisplayName}</span>
                                        </MenuItem>
                                        {(authenticationService.currentUserValue.RoleName === "administrator" || authenticationService.currentUserValue.RoleName === "manager") ?
                                            <Link to="/Home">
                                                <MenuItem
                                                    onClick={handleCloseProfile}
                                                    className={classes.dropdownItem}
                                                >
                                                    <PhonelinkSetupIcon/>
                                                    <span style={{ marginLeft: '10px' }}>Cleaner</span>
                                            </MenuItem>
                                            </Link> : null

                                        }

                                        <Divider light />
                                        <Link to="/">
                                            <MenuItem
                                                onClick={logout}
                                                className={classes.dropdownItem}
                                            >
                                                <ExitToAppIcon />
                                                <span style={{ marginLeft: '10px' }}>Logout</span>
                                            </MenuItem>
                                        </Link>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
        </div>
    );
}
