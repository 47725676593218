/* This page is used to associate the roles with groups */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar, MTableEditField } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import { Tooltip } from '@material-ui/core';
import RoleDialog from './RoleDialog.js';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AlertDialog from '../../common/alertDialog';
import { TextField, Select, MenuItem, FormControl, FormControlLabel, Checkbox, InputLabel, ListItemText } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    editIcon: {
        marginTop: '-125px', zIndex: '1000',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-120px'
        }
    },
    editIconDiv: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        marginRight: '50px'
    }
}));


export default function TeamRoleItemGroups() {
    const classes = useStyles();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [inputValue, setInputValue] = React.useState('');

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [teamRoles, setTeamRoles] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            { title: 'Role Name', field: 'RoleName' }
        ],
        data: []
    });
    const [groups, setGroups] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            { title: 'Item Group Name', field: 'ItemGroupName' }
        ],
        data: []
    });
    const [teamRoleItemGroups, setTeamRoleItemGroups] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'TeamRoleId' },
            {
                title: 'Role Name', field: 'TeamRoleName', width: '50%', readOnly: true,
                editComponent: props => {
                    return <InputLabel id="item-group-label">{props.value}</InputLabel>
                }

            },
            {
                title: 'Item Group Name', field: 'ItemGroupNameString', width: '50%', render: rowData => (rowData.ItemGroupNameString)
                //validate: rowData => {
                //    if (rowData.ItemGroupIds === undefined) {
                //        rowData.ItemGroupIds = [];
                //        return { isValid: true, helperText: '' };
                //    }
                //    else {
                //        return rowData.ItemGroupIds.length === 0 ? { isValid: false, helperText: 'Item Group Name is required' } : { isValid: true, helperText: '' };
                //    }
                //}
            }
        ],
        data: []
    });

    const filterItemGroupDDL = data => {
        data[2].customFilterAndSearch = (group, rowData) => {
            let exist = false;

            if (rowData.ItemGroups.length > 0) {
                for (let index = 0; index < rowData.ItemGroups.length; index++) {
                    exist = group.indexOf(rowData.ItemGroups[index].ItemGroupName) > -1;
                    if (exist === true) {
                        break;
                    }
                }
            }

            return (
                group.length === 0 ? true : exist
            );
        };
    };

    const fillItemGroupDDL = (data, groups) => {
        data[2].editComponent = props => {
            const selectedGroups = props.rowData.ItemGroups;

            return (
                <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags"
                    options={groups}
                    disableCloseOnSelect
                    disableClearable
                    defaultValue={selectedGroups}
                    getOptionDisabled={option => {
                        const selectedGroup = selectedGroups.filter(x => x.Id === option.Id);
                        return (selectedGroup.length > 0);
                    }}
                    onChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    getOptionLabel={option => option.ItemGroupName}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.ItemGroupName}
                        </React.Fragment>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.ItemGroupName}
                                {...getTagProps({ index })}
                            />
                        ))

                    }
                    style={{ width: 500 }}
                    renderInput={params => (
                        <TextField {...params} label="" placeholder="" />
                    )}
                />);
        };

        setTeamRoleItemGroups({ ...teamRoleItemGroups, columns: data });
    };

    const getTeamRoles = () => {
        HttpService.post('/Setting/GetTeamRoles')
            .then(
                res => {
                    const roles = res.data.filter(item => item.IsSystemRole === false);
                    setTeamRoles({ ...teamRoles, data: roles });
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const getItemGroups = () => {
        HttpService.post('/Setting/GetItemGroups')
            .then(
                res => {
                    setGroups({ ...groups, data: res.data });
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    const getTeamRoleItemGroups = (sikp) => {
        HttpService.post('/Setting/GetTeamRoleItemGroups')
            .then(
                res => {
                    const data = [...teamRoleItemGroups.columns];
                    //const gGroups = {};
                    //for (let index = 0; index < groups.data.length; index++) {
                    //    gGroups[groups.data[index].Id] = groups.data[index].ItemGroupName;
                    //}
                    //data[2].lookup = gGroups;
                    if (!sikp) {
                        fillItemGroupDDL(data, groups.data);
                    }
                  
                    setTeamRoleItemGroups({ ...teamRoleItemGroups, data: res.data });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };



    const updateTeamRoleItemGroup = model => {
        var newModel = JSON.parse(JSON.stringify(model));
        if (inputValue) {
            newModel.ItemGroups = inputValue;
        }
        else {
            return;
        }

    
        setLoading(true);
        newModel.TeamRoleId = parseInt(model.TeamRoleId, 10);
        HttpService.post('/Setting/UpdateTeamRoleItemGroup', newModel)
            .then(
                res => {
                    clearInputValue();
                    getTeamRoleItemGroups(true);
                },
                error => {
                    if (error.indexOf('Item Group is already associated with Team Role.') > -1) {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                    }
                    setLoading(false);
                }
            );
    };

    const handleModalClose = () => {
        setModal(false);
    };

    const handleRoleNameChanged = items => {
        setTeamRoles({ ...teamRoles, data: items });

        const groups = {};
        for (let index = 0; index < items.length; index++) {
            groups[items[index].Id] = items[index].RoleName;
        }
        const data = [...teamRoleItemGroups.columns];
        data[1].lookup = groups;
        setTeamRoleItemGroups({ ...teamRoleItemGroups, columns: data });
    };

    const clearInputValue = () => {
        setInputValue('');
    };

    useEffect(() => {
        getTeamRoles();
        getItemGroups();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (teamRoles.data.length !== 0 && groups.data.length !== 0) {
            getTeamRoleItemGroups();
        }
        // eslint-disable-next-line
    }, [teamRoles, groups]);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Team Roles & Item Groups</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                isLoading={loading}
                                title=''
                                columns={teamRoleItemGroups.columns}
                                data={teamRoleItemGroups.data}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateTeamRoleItemGroup(newData);
                                                }
                                            }, 600);
                                        }),
                                    onRowUpdateCancelled: (event) => {
                                        clearInputValue();
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {
                /*
                teamRoles.data.length > 0 && <RoleDialog openDialog={modal} handleCloseDialog={handleModalClose} teamRoles={teamRoles} handleRoleNameChanged={handleRoleNameChanged} />
                */
            }
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
