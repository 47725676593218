/* This file is used to defne the path of all modules */

// @material-ui/icon'
import Dashboard from '@material-ui/icons/Dashboard';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import SettingsIcon from '@material-ui/icons/Settings';
// core components/views for Admin layout
import DashboardPage from './components/admin/dashboard/dashboard';
import CleaningRoutineSetup from './components/admin/cleaningRoutineAdmin/cleaningRoutineSetup';
import ApplicationRoutine from './components/admin/cleaningRoutineAdmin/applicationRoutine';
import ScheduleRoutine from './components/admin/cleaningRoutineAdmin/scheduleRoutine';
import ItemGroups from './components/admin/settings/itemGroup';
import Items from './components/admin/settings/items';
import TeamRoleItemGroups from './components/admin/settings/teamRoleItemGroups';
import Users from './components/admin/settings/users';
import SystemVariables from './components/admin/settings/systemVariables';
import Locations from './components/admin/settings/locations';
import Spaces from './components/admin/settings/spaces';
import Roles from './components/admin/settings/roles';
import Holiday from './components/admin/settings/holiday';
import QRCodeDemo from './components/admin/temp/qrCodeDemo';
import DailyUsageReport from './components/admin/report/dailyUsageReport';
import AssessmentIcon from '@material-ui/icons/Assessment';

import LinearScaleSharpIcon from '@material-ui/icons/LinearScaleSharp';

const dashboardRoutes = [
{
    path: '/Dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/Admin',
    hasSubMenu: false,
    open:false,
    subMenus: []
},
{
    path: '/Setting',
    name: 'Report',
    icon: AssessmentIcon,
    layout: '/Admin',
    hasSubMenu: true,
    open: false,
    subMenus: [
        {
            path: '/dailyUsageReport',
            name: 'Daily Usage Report',
            icon: SettingsIcon,
            component: DailyUsageReport,
            layout: '/Admin'
        },
    ]
},
{
    path: '/Setting',
    name: 'Cleaning  Routine Admin',
    icon: PhonelinkSetupIcon,
    layout: '/Admin',
    hasSubMenu: true,
    open: false,
    subMenus: [
        {
            path: '/cleaningRoutineSetup',
            name: 'Cleaning Routine Setup',
            icon: SettingsIcon,
            component: CleaningRoutineSetup,
            layout: '/Admin'
        },
        {
            path: '/ApplicationRoutine',
            name: 'Application Routines',
            icon: SettingsIcon,
            component: ApplicationRoutine,
            layout: '/Admin'
        },
        {
            path: '/ScheduleRoutine',
            name: 'Schedule Routines',
            icon: SettingsIcon,
            component: ScheduleRoutine,
            layout: '/Admin'
        }
    ]
},
{
    path: '/Setting',
    name: 'Settings',
    icon: SettingsIcon,
    layout: '/Admin',
    hasSubMenu: true,
    open: false,
    subMenus: [
        {
            path: '/Setting',
            name: 'Users',
            icon: SettingsIcon,
            layout: '/Admin',
            hasSubMenu: true,
            active: false,
            open: false,
            subMenus: [
                {
                    path: '/Users',
                    name: 'Users',
                    icon: SettingsIcon,
                    component: Users,
                    layout: '/Admin',
                    active: false
                },
                {
                    path: '/Roles',
                    name: 'Roles',
                    icon: SettingsIcon,
                    component: Roles,
                    layout: '/Admin',
                    active: false
                },
                {
                    path: '/TeamRoleItemGroups',
                    name: 'Roles & Item Groups',
                    icon: SettingsIcon,
                    component: TeamRoleItemGroups,
                    layout: '/Admin',
                    active: false
                }
            ]
        },
        {
            path: '/Setting',
            name: 'Geo Locations',
            icon: SettingsIcon,
            layout: '/Admin',
            hasSubMenu: true,
            active: false,
            open: false,
            subMenus: [
                {
                    path: '/Locations',
                    name: 'Locations',
                    icon: SettingsIcon,
                    component: Locations,
                    layout: '/Admin',
                    active: false
                },
                {
                    path: '/Spaces',
                    name: 'Spaces',
                    icon: SettingsIcon,
                    component: Spaces,
                    layout: '/Admin',
                    active: false
                },
            ]
        },
        {
            path: '/Items',
            name: 'Items & Item Groups',
            icon: SettingsIcon,
            component: Items,
            hasSubMenu: false,
            layout: '/Admin',
            subMenus: []
        },
        {
            path: '/SystemVariables',
            name: 'System Variables',
            icon: SettingsIcon,
            component: SystemVariables,
            layout: '/Admin',
            subMenus: []
        },
        {
            path: '/Holiday',
            name: 'Holiday Setup',
            icon: SettingsIcon,
            component: Holiday,
            hasSubMenu: false,
            layout: '/Admin',
            subMenus: []
        }
    ]
}];

export default dashboardRoutes;
