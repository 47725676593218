/* This file is used to render the root element of the application */

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { InitJsStore } from "./offline/storageService/idbService";
import { CheckOnline } from './offline/dataService/offlineDatas'

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
InitJsStore();    
CheckOnline();