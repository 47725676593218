import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlaceIcon from '@material-ui/icons/Place';
import AreaAppendModal from '../modals/areaAppend';
import { authenticationService } from '../../apiAuthorization/authenticationService';
import { Context } from "../contexts/siteContext";
import AlertDialog from '../common/alertDialog';
import DashboardIcon from '@material-ui/icons/Dashboard';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0)
    },
    paper: {
        paddingBottom: 100,
        boxShadow: "none",
        backgroundColor: '#f1f1f1'
    },
    list: {
        marginBottom: theme.spacing(2)
    },
    white: {
        color: 'white'
    },
    subheader: {
        backgroundColor: theme.palette.background.paper
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#3c3a3a',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    },
    grow: {
        flexGrow: 1
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        //top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
        boxShadow: '0px 3px 5px -1px rgba(60, 62, 60, 0.7), 0px 6px 10px 0px rgba(70, 68, 68, 0.18), 0px 7px 18px 0px rgba(202, 201, 201, 0.74)'
    },
    root: {
        display: 'flex'
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start'

    },
    drawerFooter: {
        top: 'auto',
        bottom: 0,
        position: "fixed",
        width: drawerWidth - 1
    },
    fullList: {
        width: 'auto'
    }
}));

const HomeIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

const SwitchModal = props => {
    //console.log(props);
    if (props.page.pathname === "/Home") {
        return (
            <AreaAppendModal {...props} />
        )
    } else {
        return (
            <></>
        )
    }
}

export default function Layout(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [bottom, setBottom] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const { sites, defaultSite, setDefaultSite, currentLocation } = useContext(Context);

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const alertBox = (msg, severityType) => {
        setErrorMessage(msg);
        setSeverity(severityType);
        setAlertOpen(true);
    }

    const alertClose = () => {
        setAlertOpen(false);
    };


    const handleDrawerOpen = () => {
        var isOffline = sessionStorage.getItem("isOffline");
        if (isOffline == 1) {
            alertBox('You cannot select site while offline.', 'info');
            return;
        }

        setOpen(true);
    };

    const toggleDrawer = isOpen => event => {

        var isOffline = sessionStorage.getItem("isOffline");
        if (isOffline == 1) {
            alertBox('You cannot select site while offline.', 'info');
            return;
        }

        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setBottom(isOpen);
    };

    const toggleSiteDrawer = isOpen => event => {
     
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(isOpen);
    };

    const handleModalOpen = () => {
        var isOffline = sessionStorage.getItem("isOffline");
        //if (isOffline==1) {
        //    alertBox('You cannot add new items while offline.', 'info');
        //    return;
        //} 
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
    };
    const logout = () => {
        setModal(false);
        authenticationService.logout();
    }
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
        <React.Fragment>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
            <CssBaseline />
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={toggleSiteDrawer(false)}
                onOpen={toggleSiteDrawer(true)}
                classes={{
                    paper: classes.drawerPaper,
                }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <div
                    role="presentation"
                    onClick={toggleSiteDrawer(false)}
                    onKeyDown={toggleSiteDrawer(false)}
                >
                    <List>
                        {sites ? sites.map((site, index) => (
                            <ListItem button key={site.id}
                                onClick={() => { setDefaultSite(site) }}
                            >
                                <ListItemIcon><PlaceIcon /></ListItemIcon>
                                <ListItemText primary={site.name} />
                            </ListItem>
                        )) : ""}
                    </List>
                </div>
            </SwipeableDrawer>

            <div className="logo-grayscale">
                <img alt="logo" src="/static/images/TeamClean-grey-blk.svg" />
            </div>
            <Paper square className={classes.paper}>
                {props.children}
            </Paper>

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Link to="/Home">
                        <IconButton edge="start" className={classes.white}>
                            <HomeIcon />
                        </IconButton>
                    </Link>
                    <Fab color="inherit" aria-label="add" className={`${classes.fabButton} green-add  svg-3`} onClick={handleModalOpen}>
                        <AddIcon />
                    </Fab>
                    <div className={classes.grow} />

                    <React.Fragment key='userBottom'>
                        <IconButton edge="end" className="avatar" onClick={toggleDrawer(true)}>
                            <Avatar>{authenticationService.currentUserValue.Abbreviation}</Avatar>
                        </IconButton>
                        <SwipeableDrawer
                            anchor='bottom'
                            open={bottom}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                        >
                            <div
                                className={clsx(classes.list, { [classes.fullList]: bottom === true })}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    <ListItem button key="Debbie" style={{ paddingLeft: '0px' }}>

                                        <IconButton className="avatar" style={{ marginRight: '20px', marginLeft: '10px', padding: '0' }}>
                                            <Avatar>{authenticationService.currentUserValue.Abbreviation}</Avatar>
                                        </IconButton>
                                        <ListItemText primary={authenticationService.currentUserValue.DisplayName} />
                                    </ListItem>
                                    {
                                        authenticationService.currentUserValue.Url == "Admin"?
                                            <Link to="/Admin">
                                                <ListItem button key="DashboardIcon">
                                                    <ListItemIcon>
                                                        <DashboardIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Dashboard" style={{ color: "rgba(0, 0, 0, 0.87)" }} />
                                                </ListItem>
                                            </Link> :null
                                    }
                                    {currentLocation.pathname === "/Home" ? <ListItem button onClick={handleDrawerOpen} key="SwitchSites">

                                        <ListItemIcon onClick={toggleSiteDrawer(true)}>
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                className={clsx(classes.menuButton, open && classes.hide)}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText primary={defaultSite.name} onClick={toggleSiteDrawer(true)} />
                                    </ListItem> : null}

                                    <Link to="/">
                                        <ListItem button key="Logout" onClick={logout}>
                                            <ListItemIcon>
                                                <ExitToAppIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Logout" style={{ color: "rgba(0, 0, 0, 0.87)" }} />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </Toolbar>
            </AppBar>

            <SwitchModal open={modal} close={handleModalClose} page={currentLocation} history={props.history} />
        </React.Fragment>
    );
}
