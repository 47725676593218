import React, { useEffect, useContext } from 'react';
import QrcodeScannerPlugin  from '../../common/qrCode';

export default function QRCodeDemo(props) {

    return (
            <div>
                <QrcodeScannerPlugin />
            </div>
        )
}