import moment from 'moment';

function isDayLightTime(datetime) {

    if (!datetime.isValid())
        return moment().isDST();

    return moment(datetime.format("MM/DD/YYYY hh:mm A")).isDST();
}
const ConvertToCurrentTimeZone = (datetime, defaultSite, format) => {

    if (defaultSite === null)
    {
        return;
    }
    if (!format)
    {
        format = "MM/DD/YYYY hh:mm A";
    }
    var TimeZoneOffset = parseInt(defaultSite.offset);
    var TimeZoneDST = parseInt(defaultSite.dst);
    if (TimeZoneDST !== 0 && isDayLightTime(moment(moment(datetime).utc()).add(TimeZoneOffset, 'hours'))) {
        TimeZoneOffset = TimeZoneOffset + TimeZoneDST;
    }
    return moment(moment(datetime).utc()).add(TimeZoneOffset, 'hours').format(format);
};

const GetTimeByCurrentTimeZone = (defaultSite, format) => {
    if (defaultSite === null) {
        return;
    }
    if (!format) {
        format = "MM/DD/YYYY hh:mm A";
    }
    var TimeZoneOffset = parseInt(defaultSite.offset);
    var TimeZoneDST = parseInt(defaultSite.dst);
    if (TimeZoneDST !== 0 && isDayLightTime(moment(moment.utc()).add(TimeZoneOffset, 'hours'))) {
        TimeZoneOffset = TimeZoneOffset + TimeZoneDST;
    }
    return moment(moment.utc()).add(TimeZoneOffset, 'hours').format(format);
};

const ConvertTimeToUTC = (datetime, format) => {
    if (typeof datetime === 'undefined') {
        return '';
    }
    if (!moment(datetime).isValid()) {
        return '';
    }
    if (!format) {
        format = 'MM/DD/YYYY HH:mm:ss';
    }
    return moment(datetime).utc().format(format);
};

const ConvertTimeZoneOffset = (datetime, defaultSite) => {
    if (typeof datetime === 'undefined') {
        return '';
    }
    if (!moment(datetime).isValid()) {
        return '';
    }
    var TimeZoneOffset = parseInt(defaultSite.offset);
    var TimeZoneDST = parseInt(defaultSite.dst);
    if (TimeZoneDST !== 0 && isDayLightTime(moment(datetime).add('hours', TimeZoneOffset))) {
        TimeZoneOffset = TimeZoneOffset + TimeZoneDST;
    }
    return moment(datetime).add('hours', TimeZoneOffset).format('MM/DD/YYYY hh:mm A');
};

const FormatTimeSpan = (time) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    return moment(time).format('hh:mm A');
};

const FormatCustom = (time, format) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    return moment(time).format(format);;
};

const GetDiffDay = (time, defaultSite) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    return moment(moment(time).format("YYYY-MM-DD")).diff(ConvertToCurrentTimeZone(moment(), defaultSite, "YYYY-MM-DD"), 'days');
};

const TimeFrom = (time, defaultSite) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    //console.log(ConvertToCurrentTimeZone(moment(), defaultSite, "YYYY-MM-DD  HH:mm:ss"));
    return moment(ConvertToCurrentTimeZone(moment(), defaultSite, "YYYY-MM-DD  HH:mm:ss")).from(ConvertTimeZoneOffset(time, defaultSite));
};

const TimeTo = (time, defaultSite) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    return moment(ConvertToCurrentTimeZone(moment(), defaultSite, "YYYY-MM-DD  HH:mm:ss")).to(moment(time));
};

const CheckFuture = (time, defaultSite, buffer) => {
    if (typeof time === 'undefined') {
        return null;
    }
    if (!moment(time).isValid()) {
        return null;
    }
    buffer = buffer || 0;
    var current = ConvertToCurrentTimeZone(moment(), defaultSite, "YYYY-MM-DD HH:mm:ss");
    var bufferTime = moment(time).add(-buffer, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    //console.log(current, time, time > current);
    return bufferTime > current;
};

const AddMinutes = (time, min) => {
    return moment(time).add(min, 'minutes').format('YYYY-MM-DD HH:mm:ss');
};

const AddHours = (time, min) => {
    return moment(time).add(min, 'hours').format('YYYY-MM-DD HH:mm:ss');
};

const GetDate = () => {
    return moment().format("MM/DD/YYYY");
};

const GetTime = () => {
    return moment().format("HH:mm");
};

export {
    ConvertTimeToUTC,
    ConvertToCurrentTimeZone,
    ConvertTimeZoneOffset,
    FormatTimeSpan,
    GetDiffDay,
    FormatCustom,
    TimeFrom,
    TimeTo,
    CheckFuture,
    GetTimeByCurrentTimeZone,
    AddMinutes,
    AddHours,
    GetTime,
    GetDate
};