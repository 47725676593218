/* This page is used for 2 tables on Cleaning Routine admin page - Routine template and schedule routines */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CircularProgress } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableType, headCells } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <TableRow>
                {tableType === "template" ?
                    <TableCell className={classes.templateHeaders} style={{ paddingLeft: '5px' }}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}

                        />
                    </TableCell> :
                    <TableCell>

                    </TableCell>}

                {headCells.map(headCell => (

                    <TableCell
                        key={headCell.id}
                        className={tableType === "template" ? classes.templateHeaders : classes.scheduleHeaders}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                minHeight:'10px',
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                minHeight: '10px',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <div style={{ height: '10px' , textAlign:'center'}}>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                ) : ""
                }
            </Toolbar>
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750,
        minHeight:400
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    templateHeaders: {
        fontWeight: 'bold',
        padding: '10px 0px 5px 0px'
    },
    scheduleHeaders: {
        fontWeight: 'bold',
        padding: '0px 5px 10px 0px'
    },
    bold: { fontWeight: 'bold' },
    cell: {padding:'5px!important'}
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 36,
        height: 22,
        padding: 0,
        marginTop: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 20,
        height: 20,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function RoutineTemplate(props) {
    const classes = useStyles();

    const { tableType, data, handleRoutineTemplateSelected, clearSelected, loading, onEditTemplateSelected, saveShowQRCode, getRoutineTemplates, totalPageCount } = props;

    const [headCells, setHeadCells] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const dense = false;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //on data load
    useEffect(() => {

        if (tableType === "template") {

            setHeadCells([
                { id: 'ShowQRCode', numeric: false, disablePadding: true, label: 'Show QR Code' },
            { id: 'TemplateName', numeric: false, disablePadding: true, label: 'Template Name' },
            { id: 'SpacesName', numeric: true, disablePadding: false, label: 'Space Name' },
            { id: 'StartTime', numeric: true, disablePadding: false, label: 'Start Time' },
            { id: 'EndTime', numeric: true, disablePadding: false, label: 'End Time' },
            { id: 'EffectiveDateTime', numeric: true, disablePadding: false, label: 'Effective Time' },
            { id: 'ExpirationDateTime', numeric: true, disablePadding: false, label: 'Expire Time' },
            { id: 'RepeatHour', numeric: true, disablePadding: false, label: 'Repeat Hour' },
            { id: 'RepeatDay', numeric: true, disablePadding: false, label: 'Repeat Day' },
            { id: 'IsActive', numeric: true, disablePadding: false, label: 'Active' },
            ]);

            const createData = (ShowQRCode,Id, TemplateName, SpacesName, StartTime, EndTime, EffectiveDateTime, ExpirationDateTime, RepeatHour, RepeatDay, IsActive, Items, Cleaners, Open) => {
                return { ShowQRCode, Id, TemplateName, SpacesName, StartTime, EndTime, EffectiveDateTime, ExpirationDateTime,RepeatHour, RepeatDay, IsActive, Items, Cleaners, Open };
            }

            const dataRows = [];

            data.map(item => {
                //console.log(item, item.RoutinesTemplate.RoutineTemplateName);
                dataRows.push(
                    createData(
                        item.RoutinesTemplate.ShowQRCode,
                        item.RoutinesTemplate.Id,
                        item.RoutinesTemplate.RoutineTemplateName,
                        item.Space.SpaceName,
                        item.RoutinesTemplate.StartTimeString,
                        item.RoutinesTemplate.EndTimeString,
                        item.RoutinesTemplate.EffectiveTimeString,
                        item.RoutinesTemplate.ExpireTimeString,
                        item.RoutinesTemplate.RepeatHour,
                        item.RoutinesTemplate.RepeatDay,
                        item.RoutinesTemplate.IsActive ? 'Active' : 'Inactive',
                        item.RoutinesTemplateListItems,
                        item.Users,
                        item.Open
                    ));
                return null;
            });

            //----------------------
            setRows(dataRows);
            setTotalCount(totalPageCount);
            console.log(totalPageCount);
            //console.log(rows);
        } else if (tableType === "schedule") {
            setHeadCells([{ id: 'TemplateName', numeric: false, disablePadding: true, label: 'Template Name' },
            { id: 'SpacesName', numeric: true, disablePadding: false, label: 'Space Name' },
            { id: 'StartTime', numeric: true, disablePadding: false, label: 'Start Date Time' },
            { id: 'EndTime', numeric: true, disablePadding: false, label: 'End Date Time' },
            { id: 'Status', numeric: true, disablePadding: false, label: 'Status' },
            { id: 'RoutineType', numeric: true, disablePadding: false, label: 'Routine Type' },]);

            const createData = (Id, TemplateName, SpacesName, StartTime, EndTime, Status, RoutineType, Items, Cleaners, State, Open) => {
                return { Id, TemplateName, SpacesName, StartTime, EndTime, Status, RoutineType, Items, Cleaners, State, Open };
            }

            const dataRows = [];
            //----------------------
            data.map(item => {
                //console.log(item, item.RoutinesTemplate.RoutineTemplateName);
                dataRows.push(
                    createData(
                        item.ScheduleRoutines.Id,
                        item.ScheduleRoutines.ScheduleRoutineName,
                        item.Space.SpaceName,
                        item.ScheduleRoutines.StartDateTime,
                        item.ScheduleRoutines.EndDateTime,
                        item.State.StatusName,
                        item.ScheduleRoutines.RoutineType,
                        item.ScheduleRoutineListItems,
                        item.Users,
                        item.Status,
                        item.Open
                    ));
                return null;
            });

            setRows(dataRows);
            setTotalCount(dataRows.length);
        }
        // eslint-disable-next-line
    }, [data]);

    //on handleRoutineTemplateSelected
    useEffect(() => {

        if (tableType === "template") {

            handleRoutineTemplateSelected(selected);
        }
        // eslint-disable-next-line
    }, [selected]);

    //on clear Selected
    useEffect(() => {

        if (clearSelected) {
            setSelected([]);
        }
        // eslint-disable-next-line
    }, [clearSelected]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.Id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        if (tableType === "template") {
            getRoutineTemplates(newPage * rowsPerPage, rowsPerPage);
        }
        
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {

        const PerPage = parseInt(event.target.value, 10);

        if (tableType === "template") {
            getRoutineTemplates(0, PerPage);
        }

        setRowsPerPage(PerPage);
        setPage(0);
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const onOpen = row => {
        //console.log(rows);
        setRows(prevState => {
            const prevData = [...prevState];
            const newRow = row;
            newRow.Open = !row.Open;
            prevData[row] = newRow;
            return [...prevData];
        });

    };


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        
    <>

            <EnhancedTableToolbar numSelected={selected.length} />
            
            <TableContainer style={{minHeight:'500px'}}>
                {loading ?
                    <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '100%', zIndex: 11 }}>
                        <div style={{ display: 'table', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                            <div style={{ display: 'table-cell', width: '100%', height: '100%', verticalAlign: 'middle', textAlign: 'center' }}>
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                    :
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            tableType={tableType}
                            headCells={headCells}
                        />

                        <TableBody>

                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isTemplateSelected = isSelected(row.Id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isTemplateSelected}
                                                tabIndex={-1}
                                                key={row.Id}
                                                selected={isTemplateSelected}
                                            >

                                                {tableType === "template" ?
                                                    <>
                                                        <TableCell className={classes.cell}>
                                                            <Checkbox
                                                                onClick={event => handleClick(event, row.Id)}
                                                                checked={isTemplateSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                            <IconButton aria-label="expand row" size="small" onClick={() => onOpen(row)}>
                                                                {row.Open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>

                                                        </TableCell>
                                                    </> : <TableCell className={classes.cell}>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => onOpen(row)}>
                                                            {row.Open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                                {
                                                    tableType === "template" ?
                                                        <TableCell className={classes.cell} scope="row" padding="none">
                                                            <FormControlLabel
                                                                control={<IOSSwitch checked={row.ShowQRCode} onChange={() => { saveShowQRCode(row)}} name="row.Id" />}
                                                            />
                                                        </TableCell>:null
                                                }
                                               
                                                
                                                <TableCell className={classes.cell} id={labelId} scope="row" padding="none">
                                                    {row.TemplateName}
                                                </TableCell>
                                                <TableCell className={classes.cell} align="right">{row.SpacesName}</TableCell>
                                                <TableCell className={classes.cell} align="right">{row.StartTime}</TableCell>
                                                <TableCell className={classes.cell} align="right">{row.EndTime}</TableCell>

                                                {tableType === "template" ?
                                                    <>
                                                        <TableCell className={classes.cell} align="right">{row.EffectiveDateTime}</TableCell>
                                                        <TableCell className={classes.cell} align="right">{row.ExpirationDateTime}</TableCell>
                                                        <TableCell className={classes.cell} align="right">{row.RepeatHour}</TableCell>
                                                        <TableCell className={classes.cell} align="right">{row.RepeatDay}</TableCell>
                                                        <TableCell className={classes.cell} align="right">{row.IsActive}</TableCell>
                                                    </> :
                                                    <>
                                                        <TableCell className={classes.cell} align="right">{row.Status}</TableCell>
                                                        <TableCell className={classes.cell} align="right">{row.RoutineType}</TableCell>
                                                    </>

                                                }

                                            </TableRow>
                                            <TableRow style={{ verticalAlign: 'baseline' }}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={tableType === "template" ? 5 : 4}>
                                                    <Collapse in={row.Open} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Routine Items
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases" style={{ backgroundColor: 'aliceblue' }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.bold} >Item Name</TableCell>
                                                                        <TableCell className={classes.bold} >Item Description</TableCell>
                                                                        {tableType === "template" ?
                                                                            <>
                                                                                <TableCell className={classes.bold}>Start Minute</TableCell>
                                                                                <TableCell className={classes.bold} align="right">Time To Complete</TableCell>
                                                                            </> :
                                                                            <>
                                                                                <TableCell className={classes.bold}>Start Date Time</TableCell>
                                                                                <TableCell className={classes.bold} align="right">Status</TableCell>
                                                                            </>
                                                                        }

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row?.Items?.map((item,index) => (
                                                                        <TableRow key={item.ItemName+index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {item.ItemName}
                                                                            </TableCell>
                                                                            <TableCell>{item.Description} </TableCell>
                                                                            {tableType === "template" ?
                                                                                <>
                                                                                    <TableCell>{item.StartMinute} </TableCell>
                                                                                    <TableCell align="right">{item.TimeToComplete} </TableCell>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <TableCell>{item.StartDateTime} </TableCell>
                                                                                    <TableCell align="right">{
                                                                                        row?.State?.map(state => (
                                                                                            item.StatusId === state.Id ? state.StatusName : null
                                                                                        ))
                                                                                    } </TableCell>
                                                                                </>
                                                                            }

                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                                    <Collapse in={row.Open} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Cleaners
                                                       </Typography>
                                                            <Table size="small" aria-label="purchases" style={{ backgroundColor: 'antiquewhite' }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.bold}>Cleaner Name</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row?.Cleaners?.map(item => (
                                                                        <TableRow key={item.DisplayName}>
                                                                            <TableCell component="th" scope="row">
                                                                                {item.DisplayName}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>

                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                }
                </TableContainer>
            
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
    </>
        
    );
}
