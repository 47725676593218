/* eslint-disable */
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import styles from "../../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { authenticationService } from '../../../apiAuthorization/authenticationService';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; 
import Button from '@material-ui/core/Button';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(styles);

const useStylesSub = makeStyles(theme => ({
    itemColor: {
        color: '#161515de!important'
    },
    active: {
        backgroundColor: '#00acc1!important',
        color:'white!important'
    },
    mr: {
        left: '120px!important'
    }
}));

export default function Sidebar(props) {
    const classes = useStyles();
    const classesSub = useStylesSub();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { color, image, logoText, routes } = props;
    const [newRoutes, setNewRoutes] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [sumMenuSelected, setSumMenuSelected] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const onSubMenuOpen = (currentTarget,item) => {
        setAnchorEl(currentTarget);
        item.open = true;
    };
    const onSumMenuSelected = (prop,item, subMenuItem) => {

        prop.subMenus.forEach(x => {
            x.active = false;
        });

        item.subMenus.forEach(x => {
            x.active = false;
        });

        subMenuItem.active = true;
        item.active = true;
        setSumMenuSelected(true);
    };
    const onSubMenuClose = (item) => {
        setAnchorEl(null);
        item.open = false;
    };

    const clearSubMenuSelected = () => {
        setSumMenuSelected(false);

    };

    useEffect(() => {
        if (authenticationService.currentUserValue === null) {
            const { to } = { to: { pathname: "/" } };
            props.history.push(to);
        } else if (authenticationService.currentUserValue.RoleName === "administrator") {
            setNewRoutes(routes);
        } else {
            routes.map(item => {
                if (item.name === "Dashboard") {
                    setNewRoutes([item]);
                }
            });
        }
    }, [authenticationService.currentUserValue]);

    const links = (
        <List className={classes.list}>
            {newRoutes.map((prop, key) => {
                const activePro = " ";
                let listItemClasses = " ";
                if (prop.path !== '/Setting') {
                    listItemClasses = classNames({
                        [` ${classes[color]}`]: activeRoute(`${prop.layout}${prop.path}`)
                    });
                }
                const whiteFontClasses = classNames({
                    [` ${classes.whiteFont}`]: activeRoute(`${prop.layout}${prop.path}`)
                });
                const handleClick = item => {
                    if (item.hasSubMenu)
                        item.open = !item.open;
                };

                return (<div key={key}>
                    <NavLink
                        to={prop.path !== '/Setting' ? prop.layout + prop.path : '#'}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}
                        onClick={prop.hasSubMenu === true ?null:clearSubMenuSelected}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses} onClick={e => handleClick(prop)}>
                            {typeof prop.icon === "string" ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                >
                                    {prop.icon}
                                </Icon>
                            ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                            <ListItemText
                                primary={props.rtlActive ? prop.rtlName : prop.name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: props.rtlActive
                                })}
                                disableTypography={true}
                            />

                            {prop.hasSubMenu === true &&
                                (prop.open ? <ExpandLess /> : <ExpandMore />)
                            }
                        </ListItem>
                    </NavLink>

                    <Collapse in={prop.open} timeout="auto" unmountOnExit>
                        {prop.hasSubMenu && prop.subMenus.map((item, keys) => {
                            listItemClasses = classNames({
                                [` ${classes[color]}`]: activeRoute(`${item.layout}${item.path}`)
                            });
                            return (
                                <List className={classes.list} key={keys}>
                                    {item.hasSubMenu === true ?
                                        (
                                            <ListItem button className={`${classes.itemLink}${listItemClasses} ${classes.nested} ${(sumMenuSelected && item.active)?classesSub.active:null}`}>
                                                <MenuOpenIcon className={classes.itemIcon} />
                                                <ListItemText className={classes.itemText} primary={item.name} onClick={(e) => { onSubMenuOpen(e.currentTarget,item) }} />
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(item.open)}
                                                        onClose={() => { onSubMenuClose(item) }}
                                                        className={classesSub.mr}
                                                    >
                                                        {item.subMenus.map((subMenuItem, subKeys) =>

                                                            <NavLink componentclass='span'
                                                                activeClassName="active"
                                                                key={subKeys}
                                                                to={subMenuItem.layout + subMenuItem.path}
                                                                onClick={() => { onSumMenuSelected(prop,item, subMenuItem) }}>
                                                                <MenuItem key={subKeys} onClick={() => { onSubMenuClose(item) }} className={`${classesSub.itemColor} ${(sumMenuSelected && subMenuItem.active) ? classesSub.active : null}`}>
                                                                    {subMenuItem.name}
                                                                </MenuItem>
                                                            </NavLink>

                                                        )}

                                                    </Menu>
                                                         
                                                
                                                    
                                            </ListItem>
                                        )
                                        :
                                        (<NavLink componentclass='span'
                                            className={activePro + classes.item}
                                            activeClassName="active"
                                            onClick={clearSubMenuSelected}
                                            key={keys} to={item.layout + item.path}>
                                                <ListItem button className={`${classes.itemLink}${listItemClasses} ${classes.nested}`}>
                                                        <StarBorder className={classes.itemIcon} />
                                                    <ListItemText className={classes.itemText} primary={item.name} />
                                               </ListItem>
                                        </NavLink>
                                        )
                                 }
                                </List>
                            )
                        })
                        }
                    </Collapse>
                </div>
                );
            })}
        </List>
    );
    const brand = (
        <div className={classes.logo}>
            <a
                href="/Admin/Dashboard"
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                })}
                target="_blank"
            >
                <div className={classes.logoImage}>

                </div>
                {logoText}
            </a>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: true
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <AdminNavbarLinks />
                        {links}
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: `url( ${image} )` }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="persistent"
                    open={props.subMenuOpen}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: false
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: "url(" + image + ")" }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    subMenuOpen: PropTypes.bool
};