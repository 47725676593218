import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    scroll: {
        maxHeight: '600px',
        overFlow: 'hidden'
    }
}));

export default function CardDetailModal(props) {
    const classes = useStyles();
    const { open, close, children,title,from}= props;
    let ps=null;
    React.useEffect(() => {
        if (open) {
            if (ps) {
                ps.destroy();
                ps = null;
            }
            setTimeout(() => {
                ps = new PerfectScrollbar(`.ps-scroll-detail-${from}`, {
                    suppressScrollX: true,
                    suppressScrollY: false,
                    });
            }, 500);
        }
    }, [open]);

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <Fade in={open}>
                    <Container className={classes.container}>
                        <Card style={{ boxShadow:'none'}}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="close" onClick={close}>
                                        <CloseOutlinedIcon />
                                    </IconButton>
                                }
                                title={title}
                                className='area-card-header'
                            />
                        </Card>
                        <Divider />
                        <CardContent className={`${classes.scroll} ps-scroll-detail-${from}`}>
                            {
                                children
                            }
                        </CardContent>
                        <CardActions>
                           
                        </CardActions>
                    </Container>
                </Fade>
            </Modal>
        </div>
    );
}
