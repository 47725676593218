import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Container from '@material-ui/core/Container';
import { deepOrange } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import AreaCard from '../area/areaCard';
import Layout from '../layout/layout';
import { AreaModelList } from '../../models/areaModelList';
import { HttpService } from '../../apiAuthorization/httpService';
import { Context } from "../contexts/siteContext";
import AlertDialog from '../common/alertDialog';
import { SetHomeScheduleRoutinesForOffline, GetOfflineData, SetRoutineTemplatesForOffline} from "../../offline/dataService/homeOfflineData";


const useStyles = makeStyles(theme => ({
    text: {
        padding: theme.spacing(2, 2, 0),
        textAlign: "center"
    },
    time: {
        color: '#848181',
        marginRight: '10px',
        minWidth: '55px'
    },
    paper: {
        paddingBottom: 50
    },
    list: {
        marginBottom: theme.spacing(2)
    },
    subheader: {
        backgroundColor: '#f1f1f1',
        color: 'black',
        fontSize: '2em',
        fontWeight: 600
    },
    appBar: {
        top: 'auto',
        bottom: 0
    },
    grow: {
        flexGrow: 1
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto'
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500]

    },
    listItemAvatar: {
        marginRight: '20px'
    },
    title: {
        textAlign: "center"
    },
    pos: {
        textAlign: "center",
        marginBottom: 12
    },
    card: {
        width: '100%'
    },
    yellowC: {
        backgroundColor: '#f3cf80'
    },
    blueC: {
        backgroundColor: '#7c88ca'
    },
    orangeC: {
        backgroundColor: '#f3a36b'
    },
    greenC: {
        backgroundColor: '#9ed263'
    },
    cyanC: {
        backgroundColor: '#2ac1b3'
    }
}));

export default function Home(props) {
    const classes = useStyles();
    const { defaultSite, siteInit, setOpen, refreshHome, setRefreshHome } = useContext(Context);
    const [scheduleRoutines, setScheduleRoutines] = React.useState([]);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [homeInit, setHomeInit] = React.useState(false);
    const showDays = 1;

    const alertBox = (msg, severityType) => {
        setErrorMessage(msg);
        setSeverity(severityType);
        setAlertOpen(true);
    }

    const alertClose = () => {
        setAlertOpen(false);
    };

   
    useEffect(() => {

        if (window.CleanerUpdateEventSource) {
            window.CleanerUpdateEventSource.close();
        }

        if (defaultSite.id && siteInit) {
            getScheduleRoutines();
        }
        // eslint-disable-next-line
    }, [defaultSite, siteInit]);

    useEffect(() => {

        if (refreshHome) {
            getScheduleRoutines();
        }
        // eslint-disable-next-line
    }, [refreshHome]);

    const getScheduleRoutines = () => {
        setOpen(true);
        HttpService.post("/Home/GetScheduleRoutinesByUsername", { SiteId: defaultSite.id, Offset: Number(defaultSite.offset) })
            .then(async (result) => {

                    if (result && result.offline) {
                        //get offline data
                        const offlineResult = await GetOfflineData(defaultSite, "GetScheduleRoutinesByUsername");
                        result = offlineResult.OfflineData;
                        //console.log('offline',result);
                    }
                    else if (result && result.error) {
                        return;
                    }

                    const areas = new AreaModelList();
                    setScheduleRoutines(areas.areas(result.data, defaultSite, "Home"));
                    setOpen(false);
                    setRefreshHome(false);

                SetHomeScheduleRoutinesForOffline(defaultSite, result, "GetScheduleRoutinesByUsername");
                SetRoutineTemplatesForOffline(defaultSite);
                },
                error => {
                    //console.log(error);
                }
            );
    };

    const goToDetail = area => {
   
        if (area.readOnly) {
            alertBox('It is too early to start this Schedule Routine. Please try again later.', 'info');
            return;
        }

        props.history.push({ pathname: `/AreaDetail/${area.id}/${area.colorId}` });
    };


    return (
        <Layout {...props} >
            <Fade in={true}>
                <Container>
                    <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
                    <List className={classes.list}>
                        {scheduleRoutines.map(area =>
                            (area.diffdays <= showDays && area.active) ?
                                <React.Fragment key={area.id}>
                                    <div onClick={() => { goToDetail(area) }}>
                                        {area.headerText &&
                                            <ListSubheader className={classes.subheader}>{area.headerText}
                                                <Typography primary="Vacation" secondary={area.subHeaderText} className={classes.time}> {area.subHeaderText}</Typography>
                                            </ListSubheader>
                                        }
                                        <ListItem button>
                                            <Typography variant="caption" display="block" gutterBottom className={classes.time}>
                                                {area.time}
                                            </Typography>
                                            <AreaCard data={area} />
                                        </ListItem>
                                    </div>
                                </React.Fragment>
                                : null
                        )}
                    </List>
                </Container>
            </Fade>
        </Layout>
    );
}
