import React, { useEffect, useRef  } from 'react'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function QrcodeScannerPlugin(props) {
    const classes = useStyles();
    const { onScaned, onBypass, showQRCodeBypass } = props;
    const [option, setOption] = React.useState({
        delay: 3000,
        result: ''
    });
    const [myLegacyMode, setMyLegacyMode] = React.useState(false);

    const handleScan = (result) => {
        
        if (result) {
            setOption({ result: result });
            onScaned(result);
        }
        else
        {
            if (myLegacyMode) {
                setOption({ result: "" });
                onScaned(result);
            }
        }
    }

    const handleError=(err)=> {
        console.error(err)
    }

    const SwitchToCamera = () => {
        setMyLegacyMode(false);
    }

    const openImageDialog = () => {
        if (myLegacyMode) {
            qrReader1.current.openImageDialog();
            return;
        }

        setMyLegacyMode(true);
        
    }

    const bypass = () => {
        onBypass();
    }

    useEffect(() => {

        if (myLegacyMode) {
            qrReader1.current.openImageDialog();
        }
    }, [myLegacyMode]);

    const qrReader1 = useRef(null);

    return (
        <div >
            <QrReader
                ref={qrReader1}
                delay={option.delay}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%', minWidth:'260px'}}
                legacyMode={myLegacyMode}
            />
            {
                showQRCodeBypass ?
                    <div className={classes.root}>
                        <Button variant="contained" color="primary" onClick={bypass} >
                            Bypass
                         </Button>
                    </div>
                    : null
            }
            
            {/*
               <div className={classes.root}>
                <Button variant="contained" color="primary" onClick={openImageDialog} >
                    Submit QR Code
                </Button>
                <Button variant="contained" color="secondary" onClick={SwitchToCamera}>
                    Switch to Camera
                </Button>
              </div>
            */}
         
            <p>{option.result}</p>
        </div>
    )
}