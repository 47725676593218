import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';

export class BaseModel {

    encryptionKeys;
    constructor(keys) {
        this.encryptionKeys = keys;
    }

    encryptionBase64 = (baseModel, soureModel) => {

        let keys = Object.keys(baseModel);
        const props = this;
        keys.forEach(k => {
            if (props.encryptionKeys.indexOf(k) > -1) {
                baseModel[k] = Base64.stringify(Utf8.parse(JSON.stringify(soureModel[k])));
            }
            else {
                baseModel[k] = soureModel[k];
            }
            // return console.log(baseModel[k]); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)

        });

        return baseModel;
    };

    decryptionBase64 = (baseModel, soureModel) => {

        let keys = Object.keys(baseModel);
        const props = this;
        keys.forEach(k => {
            if (props.encryptionKeys.indexOf(k) > -1) {

                baseModel[k] = JSON.parse(Utf8.stringify(Base64.parse(soureModel[k])));
                //console.log(1, soureModel[k], baseModel[k] );
            }
            else {
                baseModel[k] = soureModel[k];
            }
            // return console.log(baseModel[k]); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)

        });

        return baseModel;
    };

    encryptionAES = (baseModel, soureModel) => {

        let keys = Object.keys(baseModel);
        const props = this;
        keys.forEach(k => {
            if (props.encryptionKeys.indexOf(k) > -1) {
                baseModel[k] = AES.encrypt(soureModel[k], 'secret key 123').toString();

            }
            else {
                baseModel[k] = soureModel[k];
            }
            // return console.log(baseModel[k]); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)

        });

        return baseModel;
    };

    decryptionAES = (baseModel, soureModel) => {

        let keys = Object.keys(baseModel);
        const props = this;
        keys.forEach(k => {
            if (props.encryptionKeys.indexOf(k) > -1) {

                baseModel[k] = AES.decrypt(soureModel[k], 'secret key 123').toString(Utf8);
            }
            else {
                baseModel[k] = soureModel[k];
            }
            //return console.log(baseModel[k]); // This line added for the solution of warning (Expected to return a value in arrow function  array-callback-return)

        });

        return baseModel;
    };

    encryption = (baseModel, soureModel) => {

        return this.encryptionBase64(baseModel, soureModel);
        //return this.encryptionAES(baseModel, soureModel);
    };

    decryption = (baseModel, soureModel) => {

        return this.decryptionBase64(baseModel, soureModel);
        //return this.decryptionAES(baseModel, soureModel);
    };
}