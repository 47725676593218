/* Code for Cleaning Routine Admin Tab */
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Button from "../CustomButtons/Button.js";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import { TextField, Select, InputLabel, FormLabel, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText } from '@material-ui/core/';
import RoutineItem from '../cleaningRoutineAdmin/routineItem';
import RoutineTemplate from '../cleaningRoutineAdmin/routineTemplate';
import CustomDialogs from '../../modals/customModal';
import MaterialTable from 'material-table';
import Code from "@material-ui/icons/Code";
import Tabs from "../CustomTabs/CustomTabs";
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import AlertDialog from '../../common/alertDialog';
import { GetTime, GetDate } from '../../../components/contexts/timeFormatter';

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const useStyles = makeStyles(theme => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    inputControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function CleaningRoutineAdmin() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [scheduleLoading, setScheduleLoading] = useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [currentRowsPerPage, setCurrentRowsPerPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [routineVerification, setRoutineVerification] = useState({
        Location: false,
        Space: false,
        RepeatHour: false,
        RepeatMinutes: false,
        RepeatDay: false,
        RoutineTemplateName: false,
        StartTime: false,
        EndTime: false,
        StartMinute: false
    });

    const routineDtoBase = {
        LocationId: "",
        SpaceId: "",
        RepeatHour: 1,
        RepeatMinutes: 0,
        RepeatDay: [],
        RoutineTemplateName: "",
        StartTime: "",
        EndTime: "",
        DefaultDate: new Date(),
        IsActive: true,
        RoutineItems: []
        //StartDate: new Date()
    };

    const routineTemplateDtoBase = {
        TCRoutinesTemplates: [],
        Offset: Number(defaultSite.offset),
        SiteId: Number(defaultSite.id),
        EffectiveDate: new Date(),
        EffectiveTime: "",
        Action: ""
    };

    const [repeatValue, setRepeatValue] = React.useState('Hour');
    const [routineDto, setRoutineDto] = useState(routineDtoBase)

    const [saving, setSaving] = useState(false);
    const [routineTemplateView, setRoutineTemplateView] = useState([]);
    const [clearRoutineTemplateSelected, setClearRoutineTemplateSelected] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalRoutineTemplate, setModalRoutineTemplate] = useState(false);
    const [routineTemplateDto, setRoutineTemplateDto] = useState(routineTemplateDtoBase);

    const alertBox = (msg, severityType) => {
        setErrorMessage(msg);
        setSeverity(severityType);
        setAlertOpen(true);
    }

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
    };

    const handleDateChange = event => {

        setRoutineDto({
            ...routineDto,
            "DefaultDate": event,
        });
    };

    const handleModalRoutineTemplateOpen = (action) => {
        console.log(action)
        setRoutineTemplateDto({ ...routineTemplateDto, "EffectiveDate": GetDate(), "EffectiveTime": GetTime(), "Action": action });
        console.log(routineTemplateDto)
        setModalRoutineTemplate(true);
    };

    const handleModalRoutineTemplateClose = () => {
        setModalRoutineTemplate(false);
    };

   

    const handleRoutineTemplateSelected = items => {
        setClearRoutineTemplateSelected(false);
        const dto = [];
        routineTemplateView.map(item => {
            if (items.indexOf(item.RoutinesTemplate.Id) !== -1) {
                dto.push(item.RoutinesTemplate);
            }
            return null;
        });
        setRoutineTemplateDto({ ...routineTemplateDto, "TCRoutinesTemplates": dto });
    };

    //----------------------   ------------------------ ----------------------------


    //------------------ Data request function  ---------------------------------------

    const getRoutineTemplates = (rowsPerPage, pageCount) => {
        if (currentRowsPerPage != 0 && rowsPerPage <= currentRowsPerPage && pageCount <= routineTemplateView.length) return;
        if (currentRowsPerPage != 0 && totalPageCount == routineTemplateView.length) return;
        setLoading(true);
        HttpService.post("/CleaningRoutineAdmin/GetRoutineTemplates", { SiteId: defaultSite.id, RowsPerPage: (rowsPerPage || 0), PageCount: (pageCount || 10) })
            .then(
                data => {
                    //console.log(data);
                    if (!rowsPerPage || rowsPerPage == 0) {
                        setTotalPageCount(data.totalPageCount);
                        setRoutineTemplateView(data.data);
                    }
                    else {
                        setCurrentRowsPerPage(rowsPerPage);
                        let viewData = [];
                        viewData = routineTemplateView.concat(data.data);
                        setRoutineTemplateView(viewData);
                    }
                    
                    setLoading(false);
                },
                error => {
                    //console.log(error);
                }
            );
    };

 
    const generateScheduleRoutineTemplate = () => {

        if (routineTemplateDto.TCRoutinesTemplates.length <= 0) {
            alertBox('Please select at least one template!', 'error');
            return;
        }

        handleModalRoutineTemplateOpen('generate');

    };

    const deactivateScheduleRoutineTemplate = () => {

        if (routineTemplateDto.TCRoutinesTemplates.length <= 0) {
            alertBox('Please select at least one template!', 'error');
            return;
        }

        handleModalRoutineTemplateOpen('deactivate');

    };

    const generateOrDeactivateScheduleRoutineTemplate = () => {

        if (!routineTemplateDto.EffectiveDate || !routineTemplateDto.EffectiveTime) {
            alertBox('Please select a Effective Date!', 'error');
            return;
        }

        HttpService.post("/CleaningRoutineAdmin/GenerateOrDeactivateScheduleRoutineTemplate", routineTemplateDto)
            .then(
                data => {
                    //console.log(data);
                    alertBox('Schedule Routine ' + routineTemplateDto.Action + ' successfully!', 'success');
                    setRoutineTemplateDto(routineTemplateDtoBase);
                    setClearRoutineTemplateSelected(true);
                    getRoutineTemplates();
                    handleModalRoutineTemplateClose();
                },
                error => {
                    //  console.log(error);
                    handleModalRoutineTemplateClose();
                }
            );
    };

    const handleRoutineTemplateDtoChange = event => {
        const name = event.target.name;
        setRoutineTemplateDto({
            ...routineTemplateDto,
            [name]: event.target.value,
        });
    };

    const handleEffectiveDateChange = (date) => {
        setRoutineTemplateDto({
            ...routineTemplateDto,
            "EffectiveDate": date,
        });
    };

    const handleModalRoutineTemplateSelected = () => {
        generateOrDeactivateScheduleRoutineTemplate();
    };

  

    const saveShowQRCode = item => {
        setLoading(true);
        item.ShowQRCode = !item.ShowQRCode;
        HttpService.post("/CleaningRoutineAdmin/saveShowQRCode", { Id: item.Id })
            .then(
                data => {
                    //console.log(data);
                    setLoading(false);
                    if (data.error) {
                        alertBox(data.message, 'error');
                        return;
                    }
                    getRoutineTemplates();
                },
                error => {
                    //  console.log(error);
                    setLoading(false);
                }
            );
    };

    //--------------------- Data request function  ------------------------------------

    useEffect(() => {
        if (defaultSite) {
            getRoutineTemplates();
        }
        // eslint-disable-next-line
    }, [defaultSite]);

    return (
        <div>

            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />

            <CustomDialogs autoWidth={true} open={modalRoutineTemplate} close={handleModalRoutineTemplateClose} customButton="Select" onCustomButton={handleModalRoutineTemplateSelected} title="SELECT DATE & TIME" >
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                disablePast
                                variant="inline"
                                format="MM/dd/yyyy"
                                className={classes.inputControl}
                                label="Effective Date"
                                value={routineTemplateDto.EffectiveDate}
                                onChange={handleEffectiveDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            id="effective-time"
                            label="Effective Time"
                            type="time"
                            name="EffectiveTime"
                            format="hh:mm a"
                            value={routineTemplateDto.EffectiveTime}
                            onChange={handleRoutineTemplateDtoChange}
                            className={classes.inputControl}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </CustomDialogs>

            <Tabs
                headerColor="primary"
                tabs={[
                    {
                        tabName: "APPLICATION ROUTINES",
                        tabContent: (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: '0px' }}>
                                        <CardBody style={{ paddingTop: '0px' }}>
                                            <RoutineTemplate tableType="template" loading={loading} data={routineTemplateView}     handleRoutineTemplateSelected={handleRoutineTemplateSelected}
                                                getRoutineTemplates={getRoutineTemplates} totalPageCount={totalPageCount}
                                           clearSelected={clearRoutineTemplateSelected} saveShowQRCode={saveShowQRCode} />
                                            <Button color="primary" round onClick={generateScheduleRoutineTemplate} style={{ float: 'right' }}>
                                                Generate
                                            </Button>
                                            <Button color="danger" round onClick={deactivateScheduleRoutineTemplate} style={{ float: 'right' }}>
                                                Deactivate
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )
                    }
                ]}
            />
        </div>
    );
}
