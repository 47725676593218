
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import { Context } from "../../contexts/siteContext";
import AlertDialog from '../../common/alertDialog';
import Grid from '@material-ui/core/Grid';
import CustomDialogs from '../../modals/customModal';
import DailyUsageReportDrilldown from './dailyUsageReportDrilldown';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    }
}));

export default function DailyUsageReport(props) {
    const classes = useStyles();
    const { spaceId, selectedDate} = props;
    const { defaultSite } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [dailyReportDrilldown, setDailyReportDrilldown] = useState({
        columns: [
            { title: 'Routine', field: 'ScheduleRoutineName', filtering: false  },
            { title: 'Item Name', field: 'ItemName', filtering: false },
            { title: 'Status', field: 'StatusName', filtering: false },
            { title: 'Start Time', field: 'StartDateTime', filtering: false },
            { title: 'End Time', field: 'EndDateTime', filtering: false },
            { title: 'Complete Time', field: 'CompleteTime', filtering: false },
            { title: 'Note', field: 'Note', filtering: false },
            { title: 'QR Bypassed', field: 'QRBypassed', filtering: false},
        ],
        data: []
    });


    const getDailyUsageReportDrilldown = () => {
        setLoading(true);
        HttpService.post('/Report/GetDailyUsageReportDrilldown', { SpaceId: spaceId, Date: selectedDate})
            .then(
                res => {
                    setDailyReportDrilldown({ ...dailyReportDrilldown, data: res.data });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };

    
    useEffect(() => {
        getDailyUsageReportDrilldown();
        // eslint-disable-next-line
    }, [spaceId]);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    pageSize: 10,
                                    filtering: true,
                                    search: false,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        marginTop: '-10px'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                onRowClick={(event, item) => { console.log(event, item); }}
                                columns={dailyReportDrilldown.columns}
                                data={dailyReportDrilldown.data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
