import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import { AreaModelList } from '../../models/areaModelList';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AreaCard from './areaCard';
import { authenticationService } from '../../apiAuthorization/authenticationService';
import { HttpService } from '../../apiAuthorization/httpService';
import { Context } from "../contexts/siteContext";
import AlertDialog from '../common/alertDialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { GetScheduleRoutineDetailById, GetOfflineData, SaveItemToComplete, UpdateOfflineHomeScheduleRoutineDetailWithOnline, SaveNotesForOffline, SaveQROptionForOffline } from "../../offline/dataService/homeOfflineData";
import CustomDialogs from '../modals/customModal';
import QrcodeScannerPlugin from '../common/qrCode';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    gridHeard: {
        marginTop: '10px',
        marginBottom: '10px',
        position: 'relative',
    },
    fabButton: {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        boxShadow: 'none'
    },
    title: {
        textAlign: "center"
    },
    pos: {
        textAlign: "center",
        marginBottom: 12
    },
    card: {
        width: '100%'
    },
    yellowC: {
        backgroundColor: '#f3cf80'
    },
    blueC: {
        backgroundColor: '#7c88ca'
    },
    orangeC: {
        backgroundColor: '#f3a36b'
    },
    greenC: {
        backgroundColor: '#9ed263'
    },
    cyanC: {
        backgroundColor: '#2ac1b3'
    },
    isComplete50: {
        color: red[50]
    },
    isComplete500: {
        color: red[500]
    },
    listItem: {
        borderRadius: '5px',
        marginBottom: '5px',
        width: '98%',
        marginLeft: '2%'
    },
    listItemFull: {
        borderRadius: '5px',
        marginBottom: '5px'
    },
    listItemText: {
        width: '100%',
        fontSize: '1.4em',
        padding: '10px',
        color: 'white'
    },
    description: {
        fontSize: '12px',
        color: '#3c3a3a'
    },
    delete: {
        position: 'absolute',
        borderBottom: '1px',
        borderStyle: 'solid',
        color: 'white'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function AreaList(props) {
    const { defaultSite, setOpen } = useContext(Context);
    const [area, setArea] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const { readonly, id, colorId, notShowExpired} = props;
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [qrModal, setQrModal] = React.useState(false);
    const [enable, setEnable] = React.useState(false);
    const [initPage, setInitPage] = React.useState(false);

    const alertBox = (msg, severityType) => {
        setErrorMessage(msg);
        setSeverity(severityType);
        setAlertOpen(true);
    }

    const alertClose = () => {
        setAlertOpen(false);
    };

    const getRoutineDetails = () => {

        HttpService.post("/Home/GetScheduleRoutineItemDetail", { Id: Number(id), Offset: Number(defaultSite.offset) })
            .then(async (result) => {

                if (result && result.offline) {
                    //get offline data
                    const offlineResult = await GetOfflineData(defaultSite, "GetScheduleRoutineItemsDetailForOffline");
                    result.data = GetScheduleRoutineDetailById(Number(id), offlineResult.OfflineData.data);
                    //console.log('offline', result);
                }
                else if (result && result.error) {
                    alertBox(result.message, "error");
                    setTimeout(() => { props.history.push({ pathname: "/Home" }); }, 5000);
                    return;
                }

                if (result.data.ScheduleRoutineListItems.length === 0) {
                    alertBox("The current item has been collected by others", "error");
                    setTimeout(() => { props.history.push({ pathname: "/Home" }); }, 5000);
                    return;
                }

                const areas = new AreaModelList();
                var area = areas.fill(result.data, defaultSite, "HomeDetailPage", Number(colorId));
                if (readonly || area.expired) {
                    area.showQRCode = false;
                }
                else if (area.qrBypassed)
                {
                    setEnable(true);
                }
                else
                {
                    if (!initPage)
                    {
                        if (!area.showQRCode)
                            setEnable(true);
                        else 
                            handleQRModalOpen();
                    }
                }
  
                setArea(area);
                
                setItems(areas.fillDetail(result.data));
                setNotes(result.data.ScheduleRoutines.Notes || "");
                setInitPage(true);

                await UpdateOfflineHomeScheduleRoutineDetailWithOnline(defaultSite, Number(id), result.data);

            },
                error => {
                    //console.log(error);
                }
            );
    };

    const setItemToComplete = entity => {

        let param = { Id: entity.id, Offset: Number(defaultSite.offset) };
        setOpen(true);
        HttpService.post("/Home/SetItemToComplete", param)
            .then(async result => {

                if (result && result.offline) {
                    //save offline data
                    await SaveItemToComplete(Number(id), defaultSite, entity.id, "SetItemToComplete", param, "/Home/SetItemToComplete", false);
                    getRoutineDetails();
                }
                else if (result && result.error) {
                    setOpen(false);
                    return;
                }
                else {
                    let refreshItems = JSON.parse(JSON.stringify(items));
                    refreshItems.forEach(x => {
                        if (x.id == entity.id) {
                            x.checked = !x.checked;
                            if (x.checked) {
                                x.abbreviation = authenticationService.currentUserValue.Abbreviation;
                            }
                            else {
                                x.abbreviation = null;
                            }
                        }
                    });
                    setItems(refreshItems);
                    // update offline data
                    await SaveItemToComplete(Number(id), defaultSite, entity.id, "SetItemToComplete", param, "/Home/SetItemToComplete", true);
                }
                setOpen(false);
            },
                error => {
                    // console.log(error);
                }
            );
    };

    const handleNotesChange = event => {
        setNotes(event.target.value);
    };

    const timer = React.useRef();
    const saveNotes = () => {

        if (!notes) return;

        if (!loading) {
            setSuccess(false);
            setLoading(true);
            let param = { Id: Number(id), Notes: notes };
            HttpService.post("/Home/SaveNotes", param)
                .then(async result => {
                    timer.current = window.setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                    }, 500);

                    if (result && result.offline) {
                        //save offline data
                        await SaveNotesForOffline(Number(id), defaultSite, "SaveNotes", param, "/Home/SaveNotes");
                        getRoutineDetails();
                    }
                    else if (result && result.error) {
                        return;
                    }

                },
                    error => {
                        // console.log(error);
                    }
                );
        }

    };

    const saveQRScanned = () => {
        saveQROption('QRScanned');
    }

    const saveQRMatched = () => {
        saveQROption('QRMatched');
    }

    const saveQRBypassed = () => {
        setEnable(true);
        handleQRModalClose();
        saveQROption('QRBypassed');
    }

    const saveQROption = (optionType) => {
        let param = { ScheduleRoutineId: Number(id), optionType: optionType};
        HttpService.post("/Home/SaveQROption", param )
            .then(async result => {
                if (result && result.offline) {
                    //save offline data
                    await SaveQROptionForOffline(Number(id), defaultSite, "optionType", param, "/Home/SaveQROption");
                }
                else if (result && result.error) {
                    return;
                }

            },
                error => {
                    // console.log(error);
                }
            );
    }


    useEffect(() => {

        getRoutineDetails();

        if (readonly) return;

        if (window.CleanerUpdateEventSource) {
            window.CleanerUpdateEventSource.close();
        }

        window.CleanerUpdateEventSource = new EventSource('/CleanerUpdate');
        window.CleanerUpdateEventSource.addEventListener('cleaner-update-notifications', message => {
            if (authenticationService.currentUserValue.AppUserId == Number(message.data)) return;
            getRoutineDetails();
        });

    }, []);

    const classes = useStyles();
    const [hover, setHover] = React.useState([]);

    const toggleHover = value => () => {
        const currentIndex = hover.indexOf(value);
        const newHover = [...hover];

        if (currentIndex === -1) {
            newHover.push(value);
        } else {
            newHover.splice(currentIndex, 1);
        }

        setHover(newHover);
    }

    const handleToggle = value => () => {

        if (readonly || area.expired) return;
        if (!enable) {
            alertBox("Please scan QR Code to complete item.", "error");
            return;
        }
        if (value.userId > 0 && value.userId !== authenticationService.currentUserValue.AppUserId) return;

        setItemToComplete(value);
    };

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleQRModalOpen = () => {
        setQrModal(true);
    };

    const handleQRModalClose = () => {
        setQrModal(false);
    };

    const onScaned = (result) => {
        setQrModal(false);
        saveQRScanned();
        if (result == area.spaceId) {
            setEnable(true);
            saveQRMatched();
        }
        else {
            alertBox("QR Code Result and the current Space info didn't match, please try again.", "error");
        }
    }


    return (

        <>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />

            <CustomDialogs autoWidth={true} open={qrModal} close={handleQRModalClose} title="Scan QR Code" >
                <QrcodeScannerPlugin onScaned={onScaned} onBypass={saveQRBypassed} showQRCodeBypass={authenticationService.currentUserValue.QRCodeBypass}/>
            </CustomDialogs>
            {
                area ?
                    <Container>
                        <Grid container spacing={1} className={classes.gridHeard}>
                            <Grid item xs={12}>
                                <AreaCard data={area} showQRModal={handleQRModalOpen} notShowExpired={notShowExpired}/>
                            </Grid>
                            <Grid item xs={12}>
                                <List className={classes.root} >
                                    {items.map(value => {
                                        const labelId = `checkbox-list-label-${value.id}`;

                                        return (
                                            <React.Fragment key={value.id}>
                                                <Grid container className={classes.gridHeard} direction="row" justify="center" alignItems="center">
                                                    {
                                                        area.showItemTime ?
                                                            <Grid item xs={2} sm={1} >
                                                                <Typography variant="caption" display="block" gutterBottom>
                                                                    {value.time}
                                                                </Typography>
                                                            </Grid>
                                                            : null
                                                    }

                                                    <Grid item xs={!area.showItemTime ? 12 : 10} sm={!area.showItemTime ? 12 : 11}>
                                                        <ListItem role={undefined} dense button onClick={handleToggle(value)} className={!area.showItemTime ? `${classes.listItemFull}` : `${classes.listItem}`} style={{ backgroundColor: area.borderColor /*, filter: hover.indexOf(value.id) !== -1 ? 'brightness(0.8)' : 'brightness(1)' */ }} onMouseEnter={toggleHover(value.id)} onMouseLeave={toggleHover(value.id)}>

                                                            <ListItemIcon style={{ minWidth: '30px' }}>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={value.checked}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    style={{ color: "white" }}
                                                                />
                                                            </ListItemIcon>
                                                            {
                                                                value.checked ?
                                                                    <s style={{ color: "white" }}>
                                                                        <div className={classes.listItemText}>
                                                                            {value.name}
                                                                            <div className={classes.description}>{value.description}</div>
                                                                        </div>
                                                                    </s>
                                                                    :
                                                                    <div className={classes.listItemText}>
                                                                        {value.name}
                                                                        <div className={classes.description}>{value.description}</div>
                                                                    </div>
                                                            }

                                                            <ListItemSecondaryAction>
                                                                {value.checked ?
                                                                    <IconButton className="avatar" style={{ padding: '0' }}>
                                                                        <Avatar>{value.abbreviation} </Avatar>
                                                                    </IconButton> : null}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            </Grid>
                            {
                                area.expired ?
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-full-width"
                                                label="Notes"
                                                placeholder="Add notes"
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="notes"
                                                onChange={handleNotesChange}
                                                variant="outlined"
                                                value={notes}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-between" alignItems="flex-end" className={classes.buttonWrapper}>
                                                <div className={classes.buttonWrapper}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={buttonClassname}
                                                        disabled={loading}
                                                        onClick={saveNotes}
                                                    >
                                                        Save
                                                    </Button>
                                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                    : null
                            }
                        </Grid>
                    </Container>
                    :
                    <Backdrop className={classes.backdrop} open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            }
        </>
    );
}
