/* This file is used to redirect to the page based on the authorization */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from './authenticationService';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (currentUser && currentUser.Token) {
            // authorised so return component
            return <Component {...props} />;
        }

        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }} />
);
