/* This page is used to update the spaces */

import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from "../../contexts/siteContext";
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';
import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HttpService } from '../../../apiAuthorization/httpService';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import AlertDialog from '../../common/alertDialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    table: {
        minWidth: 750
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export default function Spaces() {
    const classes = useStyles();
    const { defaultSite } = useContext(Context);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = useState(true);

    const alertClose = () => {
        setAlertOpen(false);
        setLoading(false);
    };

    const [spaces, setSpaces] = useState({
        columns: [
            { title: 'Id', hidden: true, field: 'Id' },
            {
                title: 'Location Name', field: 'LocationId', width: '50%',
                validate: rowData => {
                    if (!rowData.LocationId) {
                        return { isValid: false, helperText: 'Location Name is required' };
                    } else {
                        return { isValid: true, helperText: '' };
                    }
                }
            },
            {
                title: 'Space Name', field: 'SpaceName', width: '50%',
                validate: rowData => {
                    if (rowData.SpaceName === undefined) {
                        rowData.SpaceName = '';
                        return { isValid: true, helperText: '' };
                    } else if (rowData.SpaceName.trim().length === 0) {
                        return { isValid: false, helperText: 'Space Name is required' };
                    } else {
                        return { isValid: true, helperText: '' };
                    }
                }
            }
        ],
        data: []
    });

    const getSpaces = () => {
        HttpService.post('/Setting/GetSpaceBySite/' + defaultSite.id )
            .then(
                res => {

                    // For drop down list look up data
                    const locations = {};
                    for (let index = 0; index < res.locations.length; index++) {
                        locations[res.locations[index].Id] = res.locations[index].LocationName;
                    }
                    const columns = [...spaces.columns];
                    columns[1].lookup = locations;
                    console.log(locations)
                    setSpaces({ ...spaces, data: res.spaces, columns: columns });
                    setLoading(false);
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('error');
                    setAlertOpen(true);
                }
            );
    };


    const addSpace = model => {
        model.Id = 0;
        model.LocationId = Number(model.LocationId);
        HttpService.post('/Setting/AddSpace', model)
            .then(
                res => {
                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    getSpaces();
                },
                error => {
                        setLoading(false);
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                }
            );
    };

    const updateSpace= model => {
        setLoading(true);
        model.LocationId = Number(model.LocationId);
        HttpService.post('/Setting/UpdateSpace', model)
            .then(
                res => {
                   
                    setLoading(false);
                    if (res.error && res.message) {
                        setErrorMessage(res.message);
                        setSeverity('error');
                        setAlertOpen(true);
                    }
                    getSpaces();
                },
                error => {
                    setErrorMessage(error);
                    setSeverity('info');
                    setAlertOpen(true);
                    setLoading(false);
                }
            );
    };

    const onRowDelete = model => {
        model.LocationId = Number(model.LocationId);
        if (window.confirm("Are you sure you want to delete this item ?")) {
            setLoading(true);
            HttpService.post('/Setting/DeleteSpace', model)
                .then(
                    res => {
                        setLoading(false);
                        if (res.error && res.message) {
                            setErrorMessage(res.message);
                            setSeverity('error');
                            setAlertOpen(true);
                        }
                        getSpaces();
                    },
                    error => {
                        setErrorMessage(error);
                        setSeverity('info');
                        setAlertOpen(true);
                        setLoading(false);
                    }
                );
        }

    };

    useEffect(() => {
        getSpaces();
        // eslint-disable-next-line
    }, [defaultSite.id]);

    return (
        <div className={classes.root}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h4 className={classes.cardTitleWhite}>Spaces</h4>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                title=''
                                isLoading={loading}
                                options={{
                                    filtering: true,
                                    search: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                localization={{
                                    body: { addTooltip: '' }
                                }}
                                icons={{
                                    Add: props => (
                                        <Tooltip title='Add Space'>
                                            <AddIcon className='AddIcon' {...props} />
                                        </Tooltip>
                                    )
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className='ToolBar'>
                                            <MTableToolbar {...props} />
                                        </div>
                                    )
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        disabled: rowData.Disabled,
                                        onClick: () => { onRowDelete(rowData) }
                                    })
                                ]}
                                columns={spaces.columns}
                                data={spaces.data}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                addSpace(newData);
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    updateSpace(newData);
                                                }
                                            }, 600);
                                        })
                                }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <AlertDialog open={alertOpen} close={alertClose} message={errorMessage} severity={severity} />
        </div>
    );
}
